/* @flow */
import '../../css/pay-period-review/SummaryWidget.css';
import * as React from 'react';
import { Button, Empty, Statistic, Tooltip } from 'antd';
import { DownloadOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { LockTypes } from '../../enums/LockTypeEnum';
import { RoleEnum } from '../../enums/RoleEnum';
import { downloadSuccessHandler } from '../../utils/downloader';
import {
  formatDateAsShortDate,
  formatDateAsTime,
  formatNumberAsMoney,
} from '../../utils/formatter';
import { useCallback, useState } from 'react';
import ApprovalProgressBar from '../shared/ApprovalProgressBar';
import LockConfirmationModal from '../shared/LockConfirmationModal';
import useDownloadCommissionReviews from '../../hooks/services/commission/useDownloadCommissionReviews';
import useMyRole from '../../hooks/services/role/useMyRole';
import type { CommissionReviewSummary } from '../../models/reviews/CommissionReviewSummary';
import type { PayPeriod } from '../../models/PayPeriod';

type Props = $ReadOnly<{
  summary: ?CommissionReviewSummary,
  payPeriod: PayPeriod,
  numberOfSelectedRows: number,
}>;

function SummaryWidget({ summary, payPeriod, numberOfSelectedRows }: Props): React.Node {
  // States
  const [lockModalIsVisible, setLockModalIsVisible] = useState(false);
  const [unlockModalIsVisible, setUnlockModalIsVisible] = useState(false);
  const myRole = useMyRole();

  // Callbacks
  const setLockModalIsVisibleCallback = useCallback((value) => setLockModalIsVisible(value), []);
  const setUnlockModalIsVisibleCallback = useCallback(
    (value) => setUnlockModalIsVisible(value),
    [],
  );

  // Mutations
  const downloadReviewsMutation = useDownloadCommissionReviews();

  // Functions
  const onDownloadClick = async (payPeriodId: number) => {
    await downloadReviewsMutation.mutateAsync(payPeriodId, {
      onSuccess: downloadSuccessHandler,
    });
  };

  if (!summary) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>;
  if (summary.numberOfAgents <= 0) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>;

  const isInReview = payPeriod ? payPeriod.isInReview : false;
  const isLocked =
    payPeriod && payPeriod.payPeriodLocks
      ? payPeriod.payPeriodLocks.some((x) => x.lockType === LockTypes.Commissions)
      : true;
  const isAdmin = myRole?.role.name === RoleEnum.Admin;
  const lockedDate =
    payPeriod && payPeriod.payPeriodLocks
      ? formatDateAsShortDate(
          payPeriod.payPeriodLocks.find((x) => x.lockType === LockTypes.Commissions)?.createdDate,
        )
      : 'unknown';
  const lockedTime =
    payPeriod && payPeriod.payPeriodLocks
      ? formatDateAsTime(
          payPeriod.payPeriodLocks.find((x) => x.lockType === LockTypes.Commissions)?.createdDate,
          true,
        )
      : 'unknown';

  return (
    <>
      <div className="commission-reviews-summary-container">
        <Statistic
          className="commission-reviews-summary-statistic"
          title="Total Earned Commission"
          value={formatNumberAsMoney(summary.totalEarnedCommission, true)}
        />
        <Statistic
          className="commission-reviews-summary-statistic"
          title="Number of Agents"
          value={summary.numberOfAgents}
        />
        <div className="commission-reviews-summary-content-approved">
          <ApprovalProgressBar
            countOfApproved={summary.numberOfAgentsApproved}
            countOfSelectedForApproval={numberOfSelectedRows || 0}
            countOfTotal={summary.numberOfAgents}
            showSubProgressBar={false}
          />
        </div>
        {isAdmin && (
          <div className="commission-reviews-summary-button-container">
            {!isLocked && (
              <Tooltip title="Lock this period" placement="bottom">
                <Button
                  className="commission-reviews-summary-button commission-reviews-lock-button"
                  disabled={!isInReview}
                  icon={<LockOutlined />}
                  onClick={() => setLockModalIsVisibleCallback(true)}
                  type="primary"
                ></Button>
              </Tooltip>
            )}
            {isLocked && (
              <Tooltip title="Unlock this period" placement="bottom">
                <Button
                  className="commission-reviews-summary-button commission-reviews-unlock-button"
                  disabled={!isInReview}
                  icon={<UnlockOutlined />}
                  onClick={() => setUnlockModalIsVisibleCallback(true)}
                  type="primary"
                ></Button>
              </Tooltip>
            )}
            <Tooltip title="Download" placement="bottom">
              <Button
                className="commission-reviews-summary-button commission-reviews-download-button"
                disabled={!isLocked}
                icon={<DownloadOutlined />}
                loading={downloadReviewsMutation.isLoading}
                onClick={() => onDownloadClick(payPeriod.id)}
                type="primary"
              ></Button>
            </Tooltip>
          </div>
        )}
        {isLocked && (
          <div className="commission-reviews-summary-lock-timestamp">
            Locked on {lockedDate}
            <br /> at {lockedTime}
          </div>
        )}
      </div>
      <LockConfirmationModal
        isVisible={lockModalIsVisible}
        payPeriod={payPeriod}
        isLocking={true}
        updateModalVisibility={setLockModalIsVisibleCallback}
        lockType={LockTypes.Commissions}
      />
      <LockConfirmationModal
        isVisible={unlockModalIsVisible}
        payPeriod={payPeriod}
        isLocking={false}
        updateModalVisibility={setUnlockModalIsVisibleCallback}
        lockType={LockTypes.Commissions}
      />
    </>
  );
}

export default SummaryWidget;
