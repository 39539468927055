/* @flow */
export const DealTypes: {
  Digital: 1,
  DirectMail: 2,
  Television: 3,
  MBI: 4,
  DigitalAndTelevision: 5,
} = Object.freeze({
  Digital: 1,
  DirectMail: 2,
  Television: 3,
  MBI: 4,
  DigitalAndTelevision: 5,
});

export type DealTypeEnum = $Values<typeof DealTypes>;
