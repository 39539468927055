/* @flow */
import { reduce } from 'lodash';
import type { IncentiveReviewSummary } from '../models/incentives/IncentiveReviewSummary';
import type { IncentivesReview } from '../models/incentives/IncentiveReview';
import type { TeamIncentivesReview } from '../models/incentives/TeamIncentivesReview';

export function recalculateIncentiveReviewSummary(
  incentivesReview: IncentivesReview,
): IncentiveReviewSummary {
  return reduce(
    incentivesReview.teamIncentivesReviews,
    (result: IncentiveReviewSummary, teamIncentiveReview: TeamIncentivesReview) => {
      return {
        ...result,
        numberOfAgents:
          result.numberOfAgents + teamIncentiveReview.teamIncentivesSummary.numberOfAgents,
        numberOfAgentsApproved:
          result.numberOfAgentsApproved +
          teamIncentiveReview.teamIncentivesSummary.numberOfAgentsApproved,
        totalIncentivesAmount:
          result.totalIncentivesAmount +
          teamIncentiveReview.teamIncentivesSummary.totalIncentivesAmount,
      };
    },
    { numberOfAgents: 0, numberOfAgentsApproved: 0, totalIncentivesAmount: 0 },
  );
}
