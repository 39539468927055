/* @flow */
import { updateAdjustment } from '../../../services/AdjustmentService';
import { useMutation, useQueryClient } from 'react-query';
import CommissionsServiceKeys from '../../../constants/CommissionsServiceKeys';
import useQueryCacheKey from '../../useQueryCacheKey';
import type { Adjustment } from '../../../models/Adjustment';
import type { AdjustmentMutation } from '../../../models/AdjustmentMutation';

type AdjustmentUpdateMutationOptions = $ReadOnly<{
  onSuccess?: (data: Adjustment) => void,
  onError?: (error: any) => void,
}>;

type AdjustmentUpdateMutation = $ReadOnly<{
  mutateAsync: (
    data: AdjustmentMutation,
    options?: AdjustmentUpdateMutationOptions,
  ) => Promise<?Adjustment>,
  isLoading: boolean,
}>;

export default function useUpdateAdjustment(): AdjustmentUpdateMutation {
  const queryClient = useQueryClient();
  const cacheKey = useQueryCacheKey(CommissionsServiceKeys.ADJUSTMENTS);

  const { mutateAsync, isLoading } = useMutation(
    async (adjustment: AdjustmentMutation) => updateAdjustment(adjustment),
    {
      onSuccess: async (response: { data: Adjustment, status: number, ... }) => {
        const { data } = response;
        const queryKey = [...cacheKey, { payPeriodId: data.payPeriodId }];

        await queryClient.cancelQueries(queryKey);
        const cachedAdjustments = queryClient.getQueryData(queryKey);

        const index = cachedAdjustments.findIndex((item) => item.id === data.id);

        if (index >= 0) {
          cachedAdjustments.splice(index, 1, data);
          queryClient.setQueryData(queryKey, () => cachedAdjustments);
        }
        return cachedAdjustments[index];
      },
    },
  );

  return {
    mutateAsync: async (
      data: AdjustmentMutation,
      options?: AdjustmentUpdateMutationOptions,
    ): Promise<?Adjustment> => {
      try {
        const result = await mutateAsync(data);
        options?.onSuccess?.(result.data);
        return result.data;
      } catch (e) {
        options?.onError?.(e);
      }
      return null;
    },
    isLoading,
  };
}
