/* @flow */
import '../../css/pay-period-review/ApproveConfirmationModal.css';
import * as React from 'react';
import { Button, Modal, notification } from 'antd';
import { filter, get, map } from 'lodash';
import { formatDateAsShortDate } from '../../utils/formatter';
import ApprovalStatistics from '../shared/ApprovalStatistics';
import useApproveCommissions from '../../hooks/services/commission/useApproveCommissions';
import type { AgentCommissionReview } from '../../models/reviews/AgentCommissionReview';
import type { PayPeriod } from '../../models/PayPeriod';

type Props = $ReadOnly<{
  isVisible: boolean,
  teamName: string,
  onModalFinish: () => void,
  payPeriod: PayPeriod,
  reviews: ?Array<AgentCommissionReview>,
  updateModalVisibility: (e: boolean) => void,
}>;

function ApproveConfirmationModal(props: Props): React.Node {
  const { isVisible, onModalFinish, payPeriod, reviews, teamName, updateModalVisibility } = props;

  // Mutations
  const approveMutation = useApproveCommissions();

  // #region Helper Functions

  const getPayPeriodLabel = (): string => {
    const startDate = formatDateAsShortDate(get(payPeriod, 'startDate'), false, false);
    const endDate = formatDateAsShortDate(get(payPeriod, 'endDate'), false, false);
    return `${startDate} - ${endDate}`;
  };

  // #endregion

  // #region Click Events

  const onCancelClick = () => updateModalVisibility(false);

  const onConfirmClick = async () => {
    const reviewsToApprove = filter(reviews, (review) => review.isSelected);
    const reviewIds = map(reviewsToApprove, (review) => review.agentCommissionId);
    await approveMutation.mutateAsync(
      { agentCommissionIds: reviewIds, payPeriodId: payPeriod.id },
      {
        onSuccess: () => {
          updateModalVisibility(false);
          openSuccessNotification();
          onModalFinish();
        },
        onError: (e) => {
          updateModalVisibility(false);
          if (e?.response?.data?.errors?.IsLocked)
            openFailureNotification(e.response.data.errors.IsLocked);
          else openFailureNotification();
        },
      },
    );
  };

  const openSuccessNotification = () => {
    const key = `approval-success-notification-${Date.now()}`;
    const btn = (
      <Button type="primary" size="small" onClick={() => notification.close(key)}>
        Ok
      </Button>
    );
    notification['success']({
      message: 'Approval Complete',
      description: <div>The selected payroll records have been approved.</div>,
      btn,
      key,
      placement: 'bottom',
    });
  };

  const openFailureNotification = (errorMessage?: string) => {
    const key = `export-failure-notification-${Date.now()}`;
    const btn = (
      <Button type="primary" size="small" onClick={() => notification.close(key)}>
        Ok
      </Button>
    );
    notification['error']({
      message: 'Approval Failed',
      description: (
        <div>
          Approval of the payroll records for pay period <strong>{getPayPeriodLabel()}</strong>{' '}
          failed. <strong>{errorMessage}</strong> If you need further assistance, please notify
          corporate IT
        </div>
      ),
      style: { width: 500 },
      duration: 0,
      btn,
      key,
      placement: 'bottom',
    });
  };

  // #endregion

  return (
    <>
      <Modal
        okText="Confirm"
        okButtonProps={{ loading: approveMutation.isLoading }}
        onCancel={onCancelClick}
        onOk={onConfirmClick}
        title={`Payroll Approval Confirmation for '${teamName}'`}
        visible={isVisible}
        width={700}
        wrapClassName="payroll-approve-modal"
      >
        <div className="payroll-approve-modal-content">
          Confirm you wish to approve the selected payroll records for pay period{' '}
          {getPayPeriodLabel()}. Approval will result in the following statistics as seen below.
        </div>
        <div className="payroll-approve-modal-header">
          Approval statistics for <strong>{teamName}</strong>
        </div>
        <ApprovalStatistics approvals={reviews} teamName={teamName} />
      </Modal>
    </>
  );
}

export default ApproveConfirmationModal;
