/* @flow */
import '../../css/pay-period-review/Statistics.css';
import * as React from 'react';
import { Card } from 'antd';
import { filter, get, size } from 'lodash';
import ApprovalProgressBar from './ApprovalProgressBar';

type Props = $ReadOnly<{
  approvals: any,
  teamName: string,
}>;

function ApprovalStatistics({ approvals, teamName }: Props): React.Node {
  const approvedReviews = getApprovedReviews(approvals);
  const selectedReviews = getSelectedReviews(approvals);
  const totalSelected = size(selectedReviews);
  const totalApproved = size(approvedReviews);
  const totalCount = size(approvals);

  return (
    <div className="payroll-stats-container">
      <div className="payroll-stats">
        <Card bordered={false} className="payroll-stats-collapse-card" key={teamName}>
          <div className="payroll-stats-collapse-card-stats">
            {getProgressBarSection(totalSelected, totalApproved, totalCount)}
          </div>
        </Card>
      </div>
    </div>
  );
}

// #region Helper Functions

function getApprovedReviews(reviews: ?Array<{ isApproved: boolean, isSelected: boolean }>) {
  return getReviewsByField(reviews, 'isApproved');
}

function getSelectedReviews(reviews: ?Array<{ isApproved: boolean, isSelected: boolean }>) {
  return getReviewsByField(reviews, 'isSelected');
}

function getReviewsByField(
  reviews: ?Array<{ isApproved: boolean, isSelected: boolean }>,
  fieldName: string,
) {
  return filter(reviews, (review) => get(review, fieldName));
}

// #endregion

// #region Component Functions

function getProgressBarSection(
  totalSelected: number,
  totalApproved: number,
  totalCount: number,
): React.Node {
  if (totalSelected <= 0) return null;
  return (
    <div className="payroll-stats-collapse-card-stats-container">
      <div className="payroll-stats-collapse-card-stats-totals header-top">
        <strong>{totalSelected} selected</strong> out of {totalCount - totalApproved} unapproved
      </div>
      <div className="payroll-stats-collapse-card-stats-bar">
        {getDoubleStatsBar(totalSelected, totalApproved, totalCount)}
      </div>
      <div className="payroll-stats-collapse-card-stats-totals header-bottom">
        <strong>After approval</strong>: {totalSelected + totalApproved} approved out of{' '}
        {totalCount} total
      </div>
    </div>
  );
}

function getDoubleStatsBar(
  totalSelected: number,
  totalApproved: number,
  totalCount: number,
): React.Node {
  return (
    <ApprovalProgressBar
      countOfApproved={totalApproved}
      countOfSelectedForApproval={totalSelected ?? 0}
      countOfTotal={totalCount}
      showSubProgressBar={false}
    />
  );
}

// #endregion

export default ApprovalStatistics;
