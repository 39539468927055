/* @flow */
import { ImpersonationContext } from '../context/ImpersonationContext';
import { isNil } from 'lodash';
import { useContext } from 'react';
import config from '../config/';

function useQueryCacheKey(
  key: string,
  useImpersonation: boolean = true,
): Array<?(number | string)> {
  const impersonationContext = useContext(ImpersonationContext);

  if (useImpersonation && !isNil(impersonationContext.agent)) {
    return [key, impersonationContext.agent && impersonationContext.agent.azureAdId];
  }

  if (useImpersonation && config.impersonatedEmail) {
    return [key, config.impersonatedEmail];
  }

  return [key];
}

export default useQueryCacheKey;
