/* @flow */
import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { authClient } from './services/AuthService';
import CommissionRoutes from './components/routing/CommissionRoutes';
import config from './config/';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 900000, // 15 mins, in milliseconds
      retry: (failureCount, error) => failureCount < 3 && ![404].includes(error?.response?.status),
    },
  },
});

function App(): React.Node {
  const isDevEnvironment = config.environment === 'dev' || config.environment === 'local';
  return (
    <React.StrictMode>
      <MsalProvider instance={authClient}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <CommissionRoutes />
          </BrowserRouter>
          {isDevEnvironment && <ReactQueryDevtools />}
        </QueryClientProvider>
      </MsalProvider>
    </React.StrictMode>
  );
}

export default App;
