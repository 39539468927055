/* @flow */
import * as React from 'react';

export type SearchModalContextType = {
  updateModal: (visible: boolean, query: string) => void,
};

export const SearchModalContext: React$Context<SearchModalContextType> =
  React.createContext<SearchModalContextType>({
    updateModal: () => {},
  });
