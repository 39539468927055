/* @flow */
import '../../css/incentives/IncentivesSummary.css';
import * as React from 'react';
import { Button, Empty, Statistic, Tooltip } from 'antd';
import { DownloadOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { LockTypes } from '../../enums/LockTypeEnum';
import { RoleEnum } from '../../enums/RoleEnum';
import { downloadSuccessHandler } from '../../utils/downloader';
import {
  formatDateAsShortDate,
  formatDateAsTime,
  formatNumberAsMoney,
} from '../../utils/formatter';
import { useCallback, useState } from 'react';
import ApprovalProgressBar from '../shared/ApprovalProgressBar';
import LockConfirmationModal from '../shared/LockConfirmationModal';
import useDownloadAgentIncentives from '../../hooks/services/incentive/useDownloadAgentIncentives';
import useMyRole from '../../hooks/services/role/useMyRole';
import type { IncentiveReviewSummary } from '../../models/incentives/IncentiveReviewSummary';
import type { PayPeriod } from '../../models/PayPeriod';

type Props = $ReadOnly<{
  payPeriod: PayPeriod,
  numberOfSelectedRows: number,
  summary: ?IncentiveReviewSummary,
}>;

function IncentivesSummaryWidget(props: Props): React.Node {
  const { payPeriod, summary, numberOfSelectedRows } = props;

  // States
  const [lockModalIsVisible, setLockModalIsVisible] = useState(false);
  const [unlockModalIsVisible, setUnlockModalIsVisible] = useState(false);
  const myRole = useMyRole();

  // Callbacks
  const setLockModalIsVisibleCallback = useCallback((value) => setLockModalIsVisible(value), []);
  const setUnlockModalIsVisibleCallback = useCallback(
    (value) => setUnlockModalIsVisible(value),
    [],
  );

  // Mutations
  const downloadAgentIncentives = useDownloadAgentIncentives();

  if (!summary) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>;
  if (summary.numberOfAgents <= 0) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>;

  const isInReview = payPeriod ? payPeriod.isInReview : false;
  const isLocked =
    payPeriod && payPeriod.payPeriodLocks
      ? payPeriod.payPeriodLocks.some((x) => x.lockType === LockTypes.Incentives)
      : true;
  const isAdmin = myRole?.role.name === RoleEnum.Admin;
  const lockedDate =
    payPeriod && payPeriod.payPeriodLocks
      ? formatDateAsShortDate(
          payPeriod.payPeriodLocks.find((x) => x.lockType === LockTypes.Incentives)?.createdDate,
        )
      : 'unknown';
  const lockedTime =
    payPeriod && payPeriod.payPeriodLocks
      ? formatDateAsTime(
          payPeriod.payPeriodLocks.find((x) => x.lockType === LockTypes.Incentives)?.createdDate,
          true,
        )
      : 'unknown';

  // Functions
  const onDownloadClick = async (payPeriodId: number) => {
    await downloadAgentIncentives.mutateAsync(payPeriodId, {
      onSuccess: downloadSuccessHandler,
    });
  };

  return (
    <>
      <div className="incentives-reviews-summary-container">
        <Statistic
          className="incentives-reviews-summary-statistic"
          title="Total Incentives Amount"
          value={formatNumberAsMoney(summary.totalIncentivesAmount, true)}
        />
        <Statistic
          className="incentives-reviews-summary-statistic"
          title="Number of Incentives"
          value={summary.numberOfAgents}
        />
        <div className="incentives-reviews-summary-content-approved">
          <ApprovalProgressBar
            countOfApproved={summary.numberOfAgentsApproved}
            countOfSelectedForApproval={numberOfSelectedRows || 0}
            countOfTotal={summary.numberOfAgents}
            showSubProgressBar={false}
          />
        </div>
        {isAdmin && (
          <div className="incentives-reviews-summary-button-container">
            {!isLocked && (
              <Tooltip title="Lock this period" placement="bottom">
                <Button
                  className="incentives-reviews-summary-button incentives-reviews-lock-button"
                  disabled={!isInReview}
                  icon={<LockOutlined />}
                  onClick={() => setLockModalIsVisibleCallback(true)}
                  type="primary"
                ></Button>
              </Tooltip>
            )}
            {isLocked && (
              <Tooltip title="Unlock this period" placement="bottom">
                <Button
                  className="incentives-reviews-summary-button incentives-reviews-unlock-button"
                  disabled={!isInReview}
                  icon={<UnlockOutlined />}
                  onClick={() => setUnlockModalIsVisibleCallback(true)}
                  type="primary"
                ></Button>
              </Tooltip>
            )}
            <Tooltip title="Download" placement="bottom">
              <Button
                className="incentives-reviews-summary-button incentives-reviews-download-button"
                disabled={!isLocked}
                icon={<DownloadOutlined />}
                loading={false}
                onClick={() => onDownloadClick(payPeriod.id)}
                type="primary"
              ></Button>
            </Tooltip>
          </div>
        )}
        {isLocked && (
          <div className="incentives-reviews-summary-lock-timestamp">
            Locked on {lockedDate}
            <br /> at {lockedTime}
          </div>
        )}
      </div>
      <LockConfirmationModal
        isVisible={lockModalIsVisible}
        payPeriod={payPeriod}
        isLocking={true}
        updateModalVisibility={setLockModalIsVisibleCallback}
        lockType={LockTypes.Incentives}
      />
      <LockConfirmationModal
        isVisible={unlockModalIsVisible}
        payPeriod={payPeriod}
        isLocking={false}
        updateModalVisibility={setUnlockModalIsVisibleCallback}
        lockType={LockTypes.Incentives}
      />
    </>
  );
}

export default IncentivesSummaryWidget;
