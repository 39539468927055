/* @flow */
import { getAgentIncentives } from '../../../services/IncentiveService';
import { useQuery } from 'react-query';
import CommissionsServiceKeys from '../../../constants/CommissionsServiceKeys';
import useQueryCacheKey from '../../useQueryCacheKey';
import type { IncentivesReview } from '../../../models/incentives/IncentiveReview';

type AgentIncentiveQueryData = {
  isLoadingAgentIncentives: boolean,
  incentiveReview: IncentivesReview,
};

function useAgentIncentives(payPeriodId: number): AgentIncentiveQueryData {
  const cacheKey = useQueryCacheKey(CommissionsServiceKeys.AGENT_INCENTIVES);

  const { isFetching, data } = useQuery(
    [...cacheKey, { payPeriodId: payPeriodId }],
    async () => getAgentIncentives(payPeriodId),
    {
      staleTime: 0,
      enabled: payPeriodId > 0,
    },
  );

  return {
    isLoadingAgentIncentives: isFetching,
    incentiveReview: data,
  };
}

export default useAgentIncentives;
