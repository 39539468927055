/* @flow */
import '../../css/dashboard/CommissionTable.css';
import * as React from 'react';
import { RetentionCommissionStatuses } from '../../enums/RetentionCommissionStatusEnum';
import { Table } from 'antd';
import { formatDateAsShortDate, formatNumberAsMoney } from '../../utils/formatter';
import { formatRetentionCommissionStatus } from '../../utils/enumFormatter';
import { isNil } from 'lodash';
import SearchModalButton from '../shared/SearchModalButton.jsx';
import type { RetentionCommissionStatusEnum } from '../../enums/RetentionCommissionStatusEnum';
import type { RetentionDealCommission } from '../../models/deals/RetentionDealCommission';

const RETENTION_COMMISSION_COLUMN_CONFIG = {
  customerNumber: {
    title: 'Customer Number',
    dataIndex: 'moxyCustomerNumber',
    editable: false,
    sorter: (a: RetentionDealCommission, b: RetentionDealCommission): any =>
      (a.moxyCustomerNumber || '').localeCompare(b.moxyCustomerNumber),
    render: (value: string): React.Node => <SearchModalButton query={value} label={value} />,
    fixed: 'left',
    width: 155,
  },
  contractNumber: {
    title: 'Contract Number',
    dataIndex: 'contractNumber',
    editable: false,
    sorter: (a: RetentionDealCommission, b: RetentionDealCommission): any =>
      (a.contractNumber || '').localeCompare(b.contractNumber),
    render: (value: string): React.Node => <SearchModalButton query={value} label={value} />,
    fixed: 'left',
    width: 155,
  },
  status: {
    title: 'Status',
    dataIndex: 'retentionCommissionStatus',
    editable: false,
    render: (value: RetentionCommissionStatusEnum): string =>
      formatRetentionCommissionStatus(value),
    sorter: (a: RetentionDealCommission, b: RetentionDealCommission): any =>
      formatRetentionCommissionStatus(a.retentionCommissionStatus).localeCompare(
        formatRetentionCommissionStatus(b.retentionCommissionStatus),
      ),
    filterSearch: true,
    filters: ([
      RetentionCommissionStatuses.AwaitingPayment,
      RetentionCommissionStatuses.Stolen,
      RetentionCommissionStatuses.PaidOut,
      RetentionCommissionStatuses.Cancelled,
      RetentionCommissionStatuses.AwaitingFunding,
    ].map((retentionStatus: RetentionCommissionStatusEnum) => ({
      text: formatRetentionCommissionStatus(retentionStatus),
      value: retentionStatus,
    })): {
      text: string,
      value: number,
    }[]),
    onFilter: (
      retentionStatus: RetentionCommissionStatusEnum,
      deal: RetentionDealCommission,
    ): boolean => deal.retentionCommissionStatus === retentionStatus,
    fixed: 'left',
    width: 130,
  },
  savedDate: {
    title: 'Saved Date',
    dataIndex: 'savedDate',
    editable: false,
    render: (value: string): string => formatDateAsShortDate(value, true, true),
    sorter: (a: RetentionDealCommission, b: RetentionDealCommission): any =>
      new Date(a.savedDate) - new Date(b.savedDate),
    width: 100,
    align: 'center',
    defaultSortOrder: 'ascend',
  },
  paymentDate: {
    title: 'Payment Date',
    dataIndex: 'paymentDate',
    editable: false,
    render: (value: string): string =>
      !isNil(value) ? formatDateAsShortDate(value, true, true) : '-',
    sorter: (a: RetentionDealCommission, b: RetentionDealCommission): any =>
      new Date(a.paymentDate) - new Date(b.paymentDate),
    width: 100,
    align: 'center',
  },
  cancelledDate: {
    title: 'Canceled Date',
    dataIndex: 'cancelledDate',
    editable: false,
    render: (value: string): string =>
      !isNil(value) ? formatDateAsShortDate(value, true, true) : '-',
    sorter: (a: RetentionDealCommission, b: RetentionDealCommission): any =>
      new Date(a.cancelledDate) - new Date(b.cancelledDate),
    width: 100,
    align: 'center',
  },
  pifDate: {
    title: 'PIF Date',
    dataIndex: 'pifDate',
    editable: false,
    render: (value: string): string =>
      !isNil(value) ? formatDateAsShortDate(value, true, true) : '-',
    sorter: (a: RetentionDealCommission, b: RetentionDealCommission): any =>
      new Date(a.pifDate) - new Date(b.pifDate),
    width: 100,
    align: 'center',
  },
  discountOfferedAmount: {
    title: 'Discount Contr.',
    dataIndex: 'discountOfferedAmount',
    editable: false,
    render: (value: number): string => formatNumberAsMoney(value, true),
    sorter: (a: RetentionDealCommission, b: RetentionDealCommission): any =>
      a.discountOfferedAmount - b.discountOfferedAmount,
    width: 100,
    align: 'right',
  },
  saveRatioBonusAmount: {
    title: 'Save Ratio Contr.',
    dataIndex: 'saveRatioBonusAmount',
    editable: false,
    render: (value: number): string => formatNumberAsMoney(value, true),
    sorter: (a: RetentionDealCommission, b: RetentionDealCommission): any =>
      a.saveRatioBonusAmount - b.saveRatioBonusAmount,
    width: 100,
    align: 'right',
  },
  commissionTotal: {
    title: 'Commission Total',
    dataIndex: 'rawTotal',
    editable: false,
    render: (value: number): string => formatNumberAsMoney(value, true),
    sorter: (a: RetentionDealCommission, b: RetentionDealCommission): any =>
      a.rawTotal - b.rawTotal,
    width: 110,
    align: 'right',
  },
  earnedTotal: {
    title: 'Earned this Period',
    dataIndex: 'earnedTotal',
    editable: false,
    render: (value: number): string => formatNumberAsMoney(value, true),
    sorter: (a: RetentionDealCommission, b: RetentionDealCommission): any =>
      a.earnedTotal - b.earnedTotal,
    width: 110,
    align: 'right',
  },
};

type RetentionCommissionColumnKey = $Keys<typeof RETENTION_COMMISSION_COLUMN_CONFIG>;
type Props = $ReadOnly<{
  columnConfig?: Array<RetentionCommissionColumnKey>,
  commissions: ?Array<RetentionDealCommission>,
  total?: ?number,
  totalLabel?: ?string,
  minScrollHeight?: number,
}>;

RetentionCommissionsTable.defaultProps = {
  columnConfig: [
    'customerNumber',
    'contractNumber',
    'status',
    'savedDate',
    'paymentDate',
    'cancelledDate',
    'pifDate',
    'discountOfferedAmount',
    'saveRatioBonusAmount',
    'commissionTotal',
    'earnedTotal',
  ],
  minScrollHeight: 240,
};

function RetentionCommissionsTable(props: Props): React.Node {
  const { columnConfig, commissions, total, totalLabel, minScrollHeight } = props;
  const scroll = { y: minScrollHeight };

  return (
    <Table
      bordered
      columns={getColumns(columnConfig)}
      dataSource={commissions}
      size="small"
      pagination={false}
      scroll={scroll}
      footer={() => getFooter(total, totalLabel)}
      rowKey={(deal) => deal.id}
    />
  );
}

function getColumns(columnNames: ?Array<RetentionCommissionColumnKey>): any {
  return columnNames ? columnNames.map((name) => RETENTION_COMMISSION_COLUMN_CONFIG[name]) : [];
}

function getFooter(total: ?number, label: ?string): React.Node {
  if (total === null || total === undefined) {
    return null;
  }

  const formattedTotal = formatNumberAsMoney(total, true);
  return (
    <div className="commission-table-footer">
      <div className="commission-table-footer-cell commission-table-footer-cell-label">
        {isNil(label) ? 'Total Earned this Period' : label}:
      </div>
      <div className="commission-table-footer-cell commission-table-footer-cell-value">
        {formattedTotal}
      </div>
    </div>
  );
}

export default RetentionCommissionsTable;
