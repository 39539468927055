/* @flow */
import * as React from 'react';
import { Button, Select } from 'antd';
import { flatMap, groupBy, map, orderBy } from 'lodash';
import { useState } from 'react';
import useAgents from '../../hooks/services/agent/useAgents';
import type { Team } from '../../models/Team';

type Props = $ReadOnly<{
  teamIds?: ?Array<number>,
  onChange?: ?(e: Team[]) => void,
  useImpersonation: boolean,
  payPeriodId: ?number,
}>;

function TeamSelector(props: Props): React.Node {
  const { onChange, useImpersonation, payPeriodId, teamIds } = props;

  const [selectedTeamIds, setSelectedTeamIds] = useState<number[]>(teamIds ?? []);

  const agents = useAgents({
    payPeriodId,
    excludeInactive: true,
    useImpersonation,
    useTeamGrouping: true,
  });

  const teamMemberships = flatMap(agents, (agent) =>
    map(agent.teamMemberships, (membership) => ({
      teamId: membership.team.id,
      teamName: membership.team.teamName,
    })),
  );

  const teams = map(
    groupBy(orderBy(teamMemberships, 'teamName'), 'teamName'),
    (groups, teamName, dict) => {
      return {
        value: dict[teamName][0].teamId,
        label: teamName,
      };
    },
  );

  const getTeam = (selectedTeams: Array<number>) => {
    return teams.reduce((filtered, next) => {
      if (selectedTeams.includes(next.value)) {
        filtered = [...filtered, { id: next.value, teamName: next.label }];
      }
      return filtered;
    }, []);
  };

  const handleChange = (selectedTeams: number[]) => {
    setSelectedTeamIds(selectedTeams);
    selectedTeams = [...selectedTeams, ...selectedTeamIds];
    onChange?.(getTeam(selectedTeams));
  };

  const onClickDM = () => {
    const dmTeamIds = teams.filter((team) => team.label.includes('DM')).map((team) => team.value);
    const newSelectedTeamIds = dmTeamIds.reduce((selectedIds, teamId) => {
      return selectedTeamIds.includes(teamId) ? selectedIds : [...selectedIds, teamId];
    }, selectedTeamIds);
    setSelectedTeamIds(newSelectedTeamIds);
    handleChange(newSelectedTeamIds);
  };

  const onClickNET = () => {
    const dmTeamIds = teams.filter((team) => team.label.includes('NET')).map((team) => team.value);
    const newSelectedTeamIds = dmTeamIds.reduce((selectedIds, teamId) => {
      return selectedTeamIds.includes(teamId) ? selectedIds : [...selectedIds, teamId];
    }, selectedTeamIds);
    setSelectedTeamIds(newSelectedTeamIds);
    handleChange(newSelectedTeamIds);
  };

  return (
    <>
      <Select
        className="agent-selector-root"
        mode="tags"
        placeholder="Please select"
        onChange={handleChange}
        style={{ width: '100%' }}
        options={teams}
        value={selectedTeamIds}
      />
      <Button type="link" onClick={onClickDM}>
        All DM
      </Button>
      <Button type="link" onClick={onClickNET}>
        All NET
      </Button>
    </>
  );
}

export default TeamSelector;
