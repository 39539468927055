/* @flow */

export const DealGroupTypes: {
  SoldCurrent: 1,
  SoldPrior: 2,
  Cancelled: 3,
  TrueUp: 4,
  Unsettled: 5,
} = Object.freeze({
  SoldCurrent: 1,
  SoldPrior: 2,
  Cancelled: 3,
  TrueUp: 4,
  Unsettled: 5,
});

export type DealGroupTypeEnum = $Values<typeof DealGroupTypes>;
