/* @flow */
import '../../css/shared/Section.css';
import * as React from 'react';
import { Divider, Spin } from 'antd';
import { isEmpty } from 'lodash';

type Props = $ReadOnly<{
  headerLabel: ?string,
  contents: Array<React.Node>,
  isLoading: boolean,
}>;

function Section(props: Props): React.Node {
  const { headerLabel, contents, isLoading } = props;

  return (
    <div className="section-root">
      {!isEmpty(headerLabel) && <Divider>{headerLabel}</Divider>}
      <div className="section-main">
        {isLoading && (
          <div className="section-loading">
            <Spin />
          </div>
        )}
        {!isLoading &&
          contents.map((content, index) => (
            <div className="section-content" key={index}>
              {content}
            </div>
          ))}
      </div>
    </div>
  );
}

Section.defaultProps = {
  isLoading: false,
};

export default Section;
