/* @flow */
import '../../css/shared/SearchPopover.css';
import * as React from 'react';
import { Popover } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useRef, useState } from 'react';
import PATHS from '../../constants/paths';
import SearchBar from './SearchBar';

function SearchPopover(): React.Node {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const onSearch = (value) => {
    navigate(`${PATHS.SEARCH}?q=${value}`);
    setVisible(false);
  };

  const searchBarRef = useRef(null);

  return (
    <Popover
      className="search-popover-root"
      content={
        <SearchBar width="medium" enterButton="Search" ref={searchBarRef} onSearch={onSearch} />
      }
      trigger="click"
      placement="bottomRight"
      onVisibleChange={(value) => {
        setVisible(value);
        // Need to trigger the focus with a slight delay due to the popover animations.
        value && window.setTimeout(() => searchBarRef.current?.focus({ cursor: 'all' }), 100);
      }}
      visible={visible}
      arrowPointAtCenter={false}
    >
      <button className="search-popover-button">
        <SearchOutlined />
      </button>
    </Popover>
  );
}

export default SearchPopover;
