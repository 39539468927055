/* @flow */
export default Object.freeze({
  ADJUSTMENTS: 'adjustments',
  AGENT_COMMISSION: 'agentCommission',
  COMMISSION_SEARCH_RESULTS: 'commissionSearchResults',
  COMMISSION_REVIEW: 'commissionReviews',
  MY_ROLE: 'myRole',
  PAY_PERIODS: 'payPeriods',
  PAY_PERIOD_AGENTS: 'payPeriodAgents',
  INCENTIVES: 'incentives',
  AGENT_INCENTIVES: 'agentIncentives',
  CONTESTS: 'contests',
});
