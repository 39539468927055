/* @flow */
import { getIncentives } from '../../../services/IncentiveService';
import { useQuery } from 'react-query';
import CommissionsServiceKeys from '../../../constants/CommissionsServiceKeys';
import useQueryCacheKey from '../../useQueryCacheKey';
import type { Incentive } from '../../../models/incentives/Incentive';

type IncentivesQueryData = {
  isLoadingIncentives: boolean,
  incentives: Array<Incentive>,
};

function useIncentive(excludeUnpublished: boolean = true): IncentivesQueryData {
  const cacheId = `${CommissionsServiceKeys.INCENTIVES}-${String(excludeUnpublished)}`;

  const cacheKey = useQueryCacheKey(cacheId);

  const { isFetching, data } = useQuery(cacheKey, async () => getIncentives(excludeUnpublished), {
    staleTime: 0,
  });

  return {
    isLoadingIncentives: isFetching,
    incentives: data,
  };
}

export default useIncentive;
