/* @flow */
import { getAgentCommission } from '../../../services/AgentService';
import { useQuery } from 'react-query';
import CommissionServiceKeys from '../../../constants/CommissionsServiceKeys';
import useQueryCacheKey from '../../useQueryCacheKey';
import type { AgentCommission } from '../../../models/AgentCommission';
import type { AgentCommissionTotals } from '../../../models/totals/AgentCommissionTotals';

type AgentCommissionQueryData = {
  isLoading: boolean,
  agentCommission: ?AgentCommission,
  agentCommissionTotals: ?AgentCommissionTotals,
};

function useAgentCommission(agentId: ?number, payPeriodId: ?number): AgentCommissionQueryData {
  const newAgentId = agentId ?? 0;
  const newPayPeriodId = payPeriodId ?? 0;
  const cacheKey = useQueryCacheKey(CommissionServiceKeys.AGENT_COMMISSION);

  const { isFetching, data } = useQuery(
    [...cacheKey, { agentId: newAgentId, payPeriodId: newPayPeriodId }],
    async () => getAgentCommission(newAgentId, newPayPeriodId),
    {
      enabled: newAgentId > 0 && newPayPeriodId > 0,
    },
  );

  return {
    isLoading: isFetching,
    agentCommission: data?.agentCommission,
    agentCommissionTotals: data?.agentCommissionTotals,
  };
}

export default useAgentCommission;
