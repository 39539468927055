/* @flow */
import * as React from 'react';
import { IncentiveStatuses } from '../../enums/IncentiveStatusEnum';
import { Select } from 'antd';
import { map } from 'lodash';
import { useEffect, useState } from 'react';
import type { Incentive } from '../../models/incentives/Incentive';

const { Option } = Select;

type Props = {
  inputValue: ?number,
  defaultValue?: number,
  onChange?: ?(value: number) => void,
  incentives: ?Array<Incentive>,
};

function IncentiveTypeSelector(props: Props): React.Node {
  const [incentives] = useState(props.incentives);
  const [value, setValue] = useState(props.inputValue);

  useEffect(() => {
    setValue(props.inputValue);
  });

  const onSelectChange = (newValue: number) => {
    setValue(newValue);
    props.onChange?.(newValue);
  };

  const incentiveTypeOptions = map(
    incentives?.filter((incentive) => incentive.incentiveStatus === IncentiveStatuses.Published) ||
      [],
    (incentive: Incentive) => {
      return (
        <Option key={incentive.id} value={incentive.id}>
          {incentive.name}
        </Option>
      );
    },
  );

  return (
    <Select
      showSearch
      filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
      placeholder="Select an Incentive"
      value={value}
      onChange={onSelectChange}
    >
      {incentiveTypeOptions}
    </Select>
  );
}

export default IncentiveTypeSelector;
