/* @flow */
import { getAgents } from '../../../services/AgentService';
import { useQuery } from 'react-query';
import CommissionServiceKeys from '../../../constants/CommissionsServiceKeys';
import useQueryCacheKey from '../../useQueryCacheKey';
import type { Agent } from '../../../models/Agent';

const CACHE_TIME = 60 * 60 * 1000; // One hour

function useAgents(
  options: {
    payPeriodId: ?number,
    excludeInactive: boolean,
    useImpersonation: boolean,
    useTeamGrouping: boolean,
  } = {
    payPeriodId: 1,
    excludeInactive: true,
    useImpersonation: true,
    useTeamGrouping: false,
  },
): ?Array<Agent> {
  const { payPeriodId, excludeInactive, useImpersonation, useTeamGrouping } = options;

  const payPeriodCacheId =
    payPeriodId === null || payPeriodId === undefined ? 'current' : String(payPeriodId);

  const cacheId = `${payPeriodCacheId}-${CommissionServiceKeys.PAY_PERIOD_AGENTS}-${String(
    excludeInactive,
  )}-${String(useTeamGrouping)}`;
  const cacheKey = useQueryCacheKey(cacheId, useImpersonation);

  const { isFetching, isError, data } = useQuery(
    cacheKey,
    async () => getAgents(payPeriodId, excludeInactive, useImpersonation, useTeamGrouping),
    {
      cacheTime: CACHE_TIME,
      staleTime: CACHE_TIME,
    },
  );

  if (isFetching) {
    return null;
  }

  if (isError) {
    return [];
  }

  return data;
}

export default useAgents;
