/* @flow */
import '../../css/shared/SearchModalButton.css';
import * as React from 'react';
import { Button } from 'antd';
import useSearchModalContext from '../../hooks/useSearchModalContext';

type Props = $ReadOnly<{
  label: string,
  query: string,
}>;

function SearchModalButton({ label, query }: Props): React.Node {
  const { updateModal } = useSearchModalContext();

  return (
    <Button
      className="search-modal-button-root"
      onClick={() => {
        updateModal(true, query);
      }}
      type="link"
    >
      {label}
    </Button>
  );
}

export default SearchModalButton;
