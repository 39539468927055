/* @flow */
import { useState } from 'react';
import WebAPIClient from '../services/api/WebAPIClient';
import type { Agent } from '../models/Agent';

function useImpersonation(): [?Agent, (agent: ?Agent) => void] {
  const [agent, setAgent] = useState(null);

  const setImpersonatedAgent = (agent: ?Agent) => {
    if (agent) {
      WebAPIClient.setImpersonationHeaders({
        'x-impersonation-email-address': agent.emailAddress,
      });
    } else {
      WebAPIClient.setImpersonationHeaders({});
    }

    setAgent(agent);
  };

  return [agent, setImpersonatedAgent];
}

export default useImpersonation;
