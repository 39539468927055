/* @flow */
import * as React from 'react';
import { DatePicker } from 'antd';
import { useEffect, useState } from 'react';
import moment from 'moment';
import type { AgentIncentive } from '../../../models/incentives/AgentIncentive';

type Props = $ReadOnly<{
  fieldId: number,
  form: any,
  agentIncentive: ?AgentIncentive,
}>;

function CalendarMonthIncentiveValue(props: Props): React.Node {
  const { fieldId, form, agentIncentive } = props;
  const getInitialValue = () => {
    return moment(
      agentIncentive?.incentiveValues?.find((x) => x.incentiveFieldId === fieldId)?.value ??
        new Date(),
    );
  };

  const customFormat = (value) => {
    return `${value.format('MMMM')} ${value.format('YYYY')}`;
  };

  const [dateState, setDateState] = useState(getInitialValue());

  const updateField = (date, dateString) => {
    form.setFieldsValue({ [fieldId]: dateString });
    setDateState(date);
  };

  useEffect(() => {
    form.setFieldsValue({ [fieldId]: getInitialValue().format('MMMM YYYY') });
  }, []);

  return (
    <DatePicker value={dateState} onChange={updateField} picker="month" format={customFormat} />
  );
}

export default CalendarMonthIncentiveValue;
