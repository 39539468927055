/* @flow */
import '../../css/incentives/ContestPeriod.css';
import * as React from 'react';
import { DatePicker, TimePicker } from 'antd';
import { RoleEnum } from '../../enums/RoleEnum';
import { useEffect, useState } from 'react';
import moment from 'moment';
import useMyRole from '../../hooks/services/role/useMyRole';
import type { Contest } from '../../models/incentives/Contest';
import type { PayPeriod } from '../../models/PayPeriod';

type Props = $ReadOnly<{
  contest: ?Contest,
  form: any,
  payPeriod: ?PayPeriod,
  isDisabled: boolean,
}>;

function ContestPeriod(props: Props): React.Node {
  const myRole = useMyRole();

  const hourFormat = 'hh:mm A';
  const dateFormat = 'MM/DD/YYYY';

  const { contest, form, payPeriod, isDisabled } = props;

  const [contestDate, setContestDate] = useState(moment(contest?.startDate));
  const [startTime, setStartTime] = useState(moment(contest?.startDate));
  const [endTime, setEndTime] = useState(moment(contest?.endDate));

  useEffect(() => {
    form.setFieldsValue({
      duration: [startTime, endTime],
    });
  }, [contestDate, startTime, endTime]);

  const onDateChange = (value) => {
    setContestDate(value);

    setStartTime(
      moment(value).set({
        hour: startTime.hours(),
        minute: startTime.minutes(),
      }),
    );

    setEndTime(
      moment(value).set({
        hour: endTime.hours(),
        minute: endTime.minutes(),
      }),
    );
  };

  const onStartTimeChange = (value) => {
    setStartTime(
      moment(contestDate).set({
        hour: value.hours(),
        minute: value.minutes(),
      }),
    );
  };

  const onEndTimeChange = (value) => {
    setEndTime(
      moment(contestDate).set({
        hour: value.hours(),
        minute: value.minutes(),
      }),
    );
  };

  const disabledDate = (current) => {
    if (current && myRole?.role.name === RoleEnum.Admin) {
      return (
        current < moment(payPeriod?.startDate).startOf('day') ||
        current > moment(payPeriod?.endDate).startOf('day')
      );
    }
    return (
      (current && current < moment().endOf('day')) ||
      current > moment(payPeriod?.endDate).startOf('day')
    );
  };

  return (
    <>
      <div className="contest-period">
        <span>Contest Date:</span>
        <DatePicker
          value={moment(contestDate)}
          onChange={onDateChange}
          format={dateFormat}
          disabledDate={disabledDate}
          allowClear={false}
          disabled={isDisabled}
        />
      </div>
      <div className="contest-period">
        <span>Start Time:</span>
        <TimePicker
          value={moment(startTime, hourFormat)}
          onChange={onStartTimeChange}
          format={hourFormat}
          allowClear={false}
          disabled={isDisabled}
        />
      </div>
      <div className="contest-period">
        <span>End Time:</span>
        <TimePicker
          value={moment(endTime, hourFormat)}
          onChange={onEndTimeChange}
          format={hourFormat}
          allowClear={false}
          disabled={isDisabled}
        />
      </div>
    </>
  );
}

export default ContestPeriod;
