/* @flow */
import * as React from 'react';
import { ManagerCommissionTypes } from '../../enums/ManagerCommissionTypeEnum';
import { Tabs } from 'antd';
import { filter, isNil } from 'lodash';
import ManagerCommissionsTable from './ManagerCommissionsTable.jsx';
import type { ManagerCommission } from '../../models/managers/ManagerCommission';
import type { ManagerTotals } from '../../models/totals/ManagerTotals';
const { TabPane } = Tabs;

type Props = $ReadOnly<{
  commissions: Array<ManagerCommission>,
  total: ?ManagerTotals,
  payPeriodId: number,
  extraContent?: React.Node,
}>;

function ManagerCommissionTabs(props: Props): React.Node {
  const { commissions, extraContent, total, payPeriodId } = props;

  const salesCommissions = filter(commissions, (commission) =>
    [
      ManagerCommissionTypes.Sales,
      ManagerCommissionTypes.Onboarding,
      ManagerCommissionTypes.SalesAcceleration,
      ManagerCommissionTypes.Takeover,
    ].includes(commission.managerCommissionType),
  );
  const retentionCommissions = filter(
    commissions,
    (commission) => commission.managerCommissionType === ManagerCommissionTypes.Retention,
  );
  const flatForceCommissions = filter(
    commissions,
    (commission) => commission.managerCommissionType === ManagerCommissionTypes.RetentionFlatForce,
  );
  const sdrCommissions = filter(
    commissions,
    (commission) => commission.managerCommissionType === ManagerCommissionTypes.SalesDevelopment,
  );

  const hasSalesReps = !isNil(salesCommissions) && !(salesCommissions?.length === 0);
  const hasRetentionReps = !isNil(retentionCommissions) && !(retentionCommissions?.length === 0);
  const hasRetentionFlatForceReps =
    !isNil(flatForceCommissions) && !(flatForceCommissions?.length === 0);
  const hasSDRReps = !isNil(sdrCommissions) && !(sdrCommissions?.length === 0);

  const salesLabel = `Sales Reps (${salesCommissions.length})`;
  const retentionLabel = `Retention Reps (${retentionCommissions.length})`;
  const flatForceLabel = `Retention Flat Force Reps (${flatForceCommissions.length})`;
  const sdrLabel = `SDR Reps (${sdrCommissions.length})`;

  return (
    <Tabs tabBarExtraContent={extraContent}>
      {hasSalesReps && (
        <TabPane tab={salesLabel} key="1">
          <ManagerCommissionsTable
            commissions={salesCommissions}
            total={total?.salesTotal}
            payPeriodId={payPeriodId}
            includeChannelAverages={true}
            includeSDRStatistics={false}
            includeOverride={true}
            showFooter={false}
          />
        </TabPane>
      )}
      {hasRetentionReps && (
        <TabPane tab={retentionLabel} key="2">
          <ManagerCommissionsTable
            commissions={retentionCommissions}
            total={total?.retentionTotal}
            payPeriodId={payPeriodId}
            includeChannelAverages={false}
            includeSDRStatistics={false}
            includeOverride={true}
            showFooter={false}
          />
        </TabPane>
      )}
      {hasRetentionFlatForceReps && (
        <TabPane tab={flatForceLabel} key="3">
          <ManagerCommissionsTable
            commissions={flatForceCommissions}
            total={total?.retentionFlatForceTotal}
            payPeriodId={payPeriodId}
            includeChannelAverages={false}
            includeSDRStatistics={false}
            includeOverride={true}
            showFooter={false}
          />
        </TabPane>
      )}
      {hasSDRReps && (
        <TabPane tab={sdrLabel} key="4">
          <ManagerCommissionsTable
            commissions={sdrCommissions}
            total={total?.salesDevelopmentTotal}
            payPeriodId={payPeriodId}
            includeChannelAverages={false}
            includeSDRStatistics={true}
            includeOverride={true}
            showFooter={false}
          />
        </TabPane>
      )}
    </Tabs>
  );
}

export default ManagerCommissionTabs;
