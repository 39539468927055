/* @flow */
import * as React from 'react';
import { AdjustmentTypes } from '../../enums/AdjustmentTypeEnum';
import { Select } from 'antd';
import { formatAdjustmentType } from '../../utils/enumFormatter';
import { map } from 'lodash';
import { useState } from 'react';
import type { AdjustmentTypeEnum } from '../../enums/AdjustmentTypeEnum';
const { Option } = Select;

type Props = {
  value?: ?number,
  defaultValue?: number,
  onChange?: ?(value: number) => void,
};

function AdjustmentTypeSelector(props: Props): React.Node {
  const [value, setValue] = useState(props.value);

  const onSelectChange = (newValue: number) => {
    setValue(newValue);
    props.onChange?.(newValue);
  };

  const adjustmentTypeOptions = map(AdjustmentTypes, (adjustmentType: AdjustmentTypeEnum) => {
    return (
      <Option key={adjustmentType} value={adjustmentType}>
        {formatAdjustmentType(adjustmentType)}
      </Option>
    );
  });

  return (
    <Select value={props.defaultValue ?? value} onChange={onSelectChange}>
      {adjustmentTypeOptions}
    </Select>
  );
}

export default AdjustmentTypeSelector;
