/* @flow */

export const IncentiveFieldTypes: {
  CALENDAR_DATE: 1,
  CALENDAR_WEEK: 2,
  CALENDAR_MONTH: 3,
  CALENDAR_RANGE: 4,
  CONTRACT_NUMBERS: 5,
  STRING: 6,
  DESCRIPTION: 7,
  PHONE_NUMBER: 8,
  CONTEST_NAME: 9,
  CONTEST_PERIOD: 10,
  NUMBER_OF_SALES: 11,
} = Object.freeze({
  CALENDAR_DATE: 1,
  CALENDAR_WEEK: 2,
  CALENDAR_MONTH: 3,
  CALENDAR_RANGE: 4,
  CONTRACT_NUMBERS: 5,
  STRING: 6,
  DESCRIPTION: 7,
  PHONE_NUMBER: 8,
  CONTEST_NAME: 9,
  CONTEST_PERIOD: 10,
  NUMBER_OF_SALES: 11,
});

export type IncentiveFieldTypeEnum = $Values<typeof IncentiveFieldTypes>;
