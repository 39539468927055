/* @flow */
import * as React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { RoleEnum } from '../../enums/RoleEnum';
import BonusesAndPenalties from '../pages/BonusesAndPenalties';
import ContestManagement from '../pages/ContestManagement';
import CorrectionsAndAdjustments from '../pages/CorrectionsAndAdjustments';
import Dashboard from '../pages/Dashboard';
import Incentives from '../pages/Incentives';
import IncentivesManagement from '../pages/IncentivesManagement';
import MasterLayout from '../layouts/MasterLayout';
import NoPermissions from '../pages/NoPermissions';
import PATHS from '../../constants/paths';
import PayPeriodReview from '../pages/PayPeriodReview';
import RequiresPermission from '../shared/RequiresPermission';
import Search from '../pages/Search';
import Totals from '../pages/Totals';

function CommissionRoutes(): React.Node {
  const adminLevelRoutes = (
    <Route
      element={
        <RequiresPermission
          level={RoleEnum.Admin}
          visible={<Outlet />}
          fallback={<NoPermissions />}
        />
      }
    >
      <Route path={PATHS.CORRECTIONSANDADJUSTMENTS} element={<CorrectionsAndAdjustments />} />
      <Route path={PATHS.INCENTIVESMANAGEMENT} element={<IncentivesManagement />} />
      <Route path={PATHS.TOTALS} element={<Totals />} />
    </Route>
  );

  const managerLevelRoutes = (
    <Route
      element={
        <RequiresPermission
          level={RoleEnum.Manager}
          visible={<Outlet />}
          fallback={<NoPermissions />}
        />
      }
    >
      <Route path={PATHS.BONUSESANDPENALTIES} element={<BonusesAndPenalties />} />
      <Route path={PATHS.PAYPERIODREVIEW} element={<PayPeriodReview />} />
      <Route path={PATHS.CONTESTMANAGEMENT} element={<ContestManagement />} />
    </Route>
  );

  const incentiveCreatorLevelRoutes = (
    <Route
      element={
        <RequiresPermission
          level={RoleEnum.IncentiveCreator}
          visible={<Outlet />}
          fallback={<NoPermissions />}
        />
      }
    >
      <Route path={PATHS.INCENTIVES} element={<Incentives />} />
    </Route>
  );

  const retentionManagerLevelRoutes = (
    <Route
      element={
        <RequiresPermission
          level={RoleEnum.RetentionManager}
          visible={<Outlet />}
          fallback={<NoPermissions />}
        />
      }
    >
      <Route path={PATHS.CORRECTIONSANDADJUSTMENTS} element={<CorrectionsAndAdjustments />} />
    </Route>
  );

  const repLevelRoutes = (
    <Route
      element={
        <RequiresPermission
          level={RoleEnum.Rep}
          visible={<Outlet />}
          fallback={<NoPermissions />}
        />
      }
    >
      <Route path={PATHS.SEARCH} element={<Search />} />
    </Route>
  );

  return (
    <Routes>
      <Route path={PATHS.DASHBOARD} element={<MasterLayout />}>
        <Route index={true} element={<Dashboard />} />
        {retentionManagerLevelRoutes}
        {adminLevelRoutes}
        {managerLevelRoutes}
        {incentiveCreatorLevelRoutes}
        {repLevelRoutes}
      </Route>
    </Routes>
  );
}

export default CommissionRoutes;
