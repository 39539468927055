/* @flow */
import '../../css/shared/AgentSelector.css';
import * as React from 'react';
import { Cascader, Checkbox, Tooltip } from 'antd';
import { find, flatMap, groupBy, isNil, last, map, orderBy } from 'lodash';
import { useEffect, useState } from 'react';
import useAgents from '../../hooks/services/agent/useAgents';
import type { Agent } from '../../models/Agent';

type Props = $ReadOnly<{
  value?: any,
  agentId?: ?number,
  onChange?: ?(e: ?Agent) => void,
  useImpersonation: boolean,
  tooltipErrorMessage: string,
  showStatus: boolean,
  showErrorMessage: boolean,
  showExcludeInactiveCheckbox: boolean,
  defaultExcludeInactive: boolean,
  payPeriodId: ?number,
  useTeamGrouping: boolean,
}>;

function AgentSelector(props: Props): React.Node {
  const {
    value,
    agentId,
    defaultExcludeInactive,
    showExcludeInactiveCheckbox,
    tooltipErrorMessage,
    showErrorMessage,
    onChange,
    showStatus,
    useImpersonation,
    payPeriodId,
    useTeamGrouping,
  } = props;

  // States
  const [initialized, setInitialized] = useState(false);
  const [isAgentIdInvalid, setIsAgentIdInvalid] = useState(false);
  const [excludeInactive, setExcludeInactive] = useState(defaultExcludeInactive);
  const [isFocus, setIsFocus] = useState(false);
  const agents = useAgents({ payPeriodId, excludeInactive, useImpersonation, useTeamGrouping });

  // Effects
  useEffect(() => {
    if (!agents || initialized) return;

    if (agents.length === 1) {
      onChange?.(agents[0]);
      setInitialized(true);
      return;
    }

    const selectedAgent = getAgent(agentId);
    selectedAgent && onChange?.(selectedAgent);
    setInitialized(true);
  }, [agents, agentId, initialized, onChange]);

  useEffect(() => {
    if (!agents || isNil(agentId)) return;
    setIsAgentIdInvalid(!getAgent(agentId));
  }, [agents, agentId]);

  useEffect(() => {
    if (agents && agents.length === 1) onChange?.(agents[0]);
  }, [agents]);

  // Functions
  const getAgent = (agentId: ?number) => {
    return find(agents, (agent) => agent.id === agentId);
  };

  const getTooltipErrorMessage = (): string => {
    if (showErrorMessage && tooltipErrorMessage) return tooltipErrorMessage;
    if (showStatus && isAgentIdInvalid)
      return 'Invalid/Missing agent query. Please use the selector instead.';
    return '';
  };

  const inactiveCheckbox = (
    <Checkbox
      className="agent-selector-checkbox"
      checked={excludeInactive}
      onChange={(event) => setExcludeInactive(event.target.checked)}
    >
      Exclude Inactive
    </Checkbox>
  );

  if (agents === null || agents === undefined) {
    return (
      <Tooltip
        visible={isFocus && showErrorMessage}
        title={tooltipErrorMessage}
        color="red"
        placement="right"
      >
        <Cascader
          className="agent-selector-root"
          placeholder="Loading Reps..."
          value={null}
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
          loading
        />
        {showExcludeInactiveCheckbox && inactiveCheckbox}
      </Tooltip>
    );
  }

  if (agents.length === 0) return <></>;
  if (agents.length === 1) return <span className="subheader-heading">{agents[0].fullName}</span>;

  const teamMemberships = flatMap(agents, (agent) =>
    map(agent.teamMemberships, (membership) => ({
      agent,
      teamName: membership.team.teamName,
    })),
  );

  const options = map(
    groupBy(orderBy(teamMemberships, 'teamName'), 'teamName'),
    (groups, teamName) => {
      return {
        value: teamName,
        label: teamName,
        children: map(groups, (group) => {
          return { value: group.agent.id, label: group.agent.fullName };
        }),
      };
    },
  );

  const selectedAgent = isAgentIdInvalid ? null : getAgent(agentId);
  const selectedValue = value ? [value.fullName] : null;
  const valueForCascader = selectedAgent?.fullName ?? selectedValue;

  const agentSelector = (
    <Cascader
      className="agent-selector-root"
      placeholder="Select a Rep"
      options={options}
      value={valueForCascader}
      showSearch={{
        filter: (input, path) =>
          path.some((option) => option.label.toUpperCase().includes(input.toUpperCase())),
      }}
      onChange={(value) => {
        const selectedAgentId = last(value);
        onChange?.(getAgent(selectedAgentId));
      }}
      onFocus={() => setIsFocus(true)}
      onBlur={() => setIsFocus(false)}
      status={showStatus && showErrorMessage && 'error'}
    />
  );

  const errorMessage = getTooltipErrorMessage();
  return (
    <>
      {showStatus && errorMessage !== '' ? (
        <Tooltip
          visible={isFocus && showErrorMessage}
          title={tooltipErrorMessage}
          color="red"
          placement="right"
        >
          {agentSelector}
        </Tooltip>
      ) : (
        agentSelector
      )}
      {showExcludeInactiveCheckbox && inactiveCheckbox}
    </>
  );
}

export default AgentSelector;
