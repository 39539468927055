/* @flow */
export default {
  DASHBOARD: '/',
  BONUSESANDPENALTIES: '/bonuses-and-penalties/',
  CORRECTIONSANDADJUSTMENTS: '/corrections-and-adjustments/',
  TOTALS: '/totals/',
  PAYPERIODREVIEW: '/period-review/',
  PAYROLLEXPORTS: '/payroll-exports/',
  INCENTIVES: '/incentives/',
  INCENTIVESMANAGEMENT: '/incentives-management/',
  CONTESTMANAGEMENT: '/contest-management/',
  SEARCH: '/search/',
};
