/* @flow */
import '../../css/search/SearchDetails.css';
import * as React from 'react';
import { Badge, Col, Descriptions, Divider, Row } from 'antd';
import {
  type RetentionCommissionStatusEnum,
  RetentionCommissionStatuses,
} from '../../enums/RetentionCommissionStatusEnum';
import {
  formatBooleanAsString,
  formatDateAsShortDate,
  formatNumberAsMoney,
  formatNumberAsPercentage,
  truncateNumber,
} from '../../utils/formatter';
import { formatRetentionCommissionStatus } from '../../utils/enumFormatter';
import { isNil } from 'lodash';
import type { RetentionDealCommission } from '../../models/deals/RetentionDealCommission';

type Props = $ReadOnly<{
  deal: RetentionDealCommission,
}>;

function SearchRetentionDealDetails({ deal }: Props): React.Node {
  return (
    <div className="search-detail-root">
      <Row gutter={[32, 0]}>
        <Col span={12} className="search-details-basic-info-section">
          {getBasicInfoSection(deal)}
          {getSaveDetailsSection(deal)}
        </Col>
        <Col span={12} className="search-details-calculation-section">
          {getCalculationSection(deal)}
        </Col>
      </Row>
    </div>
  );
}

function getBasicInfoSection(deal: RetentionDealCommission): React.Node {
  return (
    <>
      <Row>
        <Col span={18} className="retention-details-basic-info-first-row">
          <Descriptions title="Basic Info" column={3} size="small" layout="vertical" bordered>
            <Descriptions.Item label={'Saved Date'}>
              {!isNil(deal.savedDate) ? formatDateAsShortDate(deal.savedDate, true, true) : '-'}
            </Descriptions.Item>
            <Descriptions.Item label={'Flat Force?'}>
              {formatBooleanAsString(deal.isFlatForce)}
            </Descriptions.Item>
            <Descriptions.Item label={'Sold Date'}>
              {!isNil(deal.soldDate) ? formatDateAsShortDate(deal.soldDate, true, true) : '-'}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="retention-details-basic-info-second-row">
          <Descriptions column={4} size="small" layout="vertical" bordered>
            <Descriptions.Item label={'Full Pay?'}>
              {formatBooleanAsString(deal.isContractFullPay)}
            </Descriptions.Item>
            <Descriptions.Item label={'Canceled Date'}>
              {!isNil(deal.cancelledDate)
                ? formatDateAsShortDate(deal.cancelledDate, true, true)
                : '-'}
            </Descriptions.Item>
            <Descriptions.Item label={'Payment Collection'}>
              {!isNil(deal.paymentDate) ? formatDateAsShortDate(deal.paymentDate, true, true) : '-'}
            </Descriptions.Item>
            <Descriptions.Item label={'PIF Date'}>
              {!isNil(deal.pifDate) ? formatDateAsShortDate(deal.pifDate, true, true) : '-'}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </>
  );
}

function getSaveDetailsSection(deal: RetentionDealCommission): React.Node {
  return (
    <Row className="search-details-row">
      <Col span={12} className="retention-details-save-details-row">
        <Descriptions title="Discount" column={2} layout="vertical" size="small" bordered>
          <Descriptions.Item label={'Discount Offered'}>
            {formatNumberAsMoney(deal.discountOffered, true)}
          </Descriptions.Item>
          <Descriptions.Item label="Save Rate %">
            {formatNumberAsPercentage(truncateNumber(deal.saveRatioPercentage * 100, 1), false)}
          </Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  );
}

function getCalculationSection(deal: RetentionDealCommission): React.Node {
  return (
    <>
      <Descriptions title="Calculation" column={1} size="large" bordered>
        <Descriptions.Item label={'Discount Offered Contribution'}>
          {formatNumberAsMoney(deal.discountOfferedAmount, true)}
        </Descriptions.Item>
        <Descriptions.Item label={'Save Ratio Contribution'}>
          {formatNumberAsMoney(deal.saveRatioBonusAmount, true)}
        </Descriptions.Item>
      </Descriptions>
      <Divider></Divider>
      <Descriptions column={1} bordered>
        <Descriptions.Item label="Raw Total">
          {formatNumberAsMoney(deal.rawTotal, true)}
        </Descriptions.Item>
      </Descriptions>
      <Divider></Divider>
      <Descriptions column={1} bordered>
        <Descriptions.Item label={'Status'}>
          <Badge
            color={getBadgeColorForStatus(deal.retentionCommissionStatus)}
            status="processing"
            text={formatRetentionCommissionStatus(deal.retentionCommissionStatus)}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Earned this Period">
          {formatNumberAsMoney(deal.earnedTotal, true)}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
}

function getBadgeColorForStatus(status: RetentionCommissionStatusEnum): string {
  switch (status) {
    case RetentionCommissionStatuses.PaidOut:
      return 'green';
    case RetentionCommissionStatuses.Stolen:
    case RetentionCommissionStatuses.Cancelled:
      return 'red';
    case RetentionCommissionStatuses.AwaitingPayment:
    default:
      return 'blue';
  }
}

export default SearchRetentionDealDetails;
