/* @flow */
import { deleteAdjustment } from '../../../services/AdjustmentService';
import { useMutation, useQueryClient } from 'react-query';
import CommissionsServiceKeys from '../../../constants/CommissionsServiceKeys';
import useQueryCacheKey from '../../useQueryCacheKey';
import type { Adjustment } from '../../../models/Adjustment';

type AdjustmentDeleteMutationOptions = $ReadOnly<{
  onSuccess?: () => void,
  onError?: (error: any) => void,
}>;

type AdjustmentDeleteMutation = $ReadOnly<{
  mutateAsync: (data: Adjustment, options?: AdjustmentDeleteMutationOptions) => Promise<void>,
  isLoading: boolean,
}>;

export default function useDeleteAdjustment(): AdjustmentDeleteMutation {
  const queryClient = useQueryClient();
  const cacheKey = useQueryCacheKey(CommissionsServiceKeys.ADJUSTMENTS);

  const { mutateAsync, isLoading } = useMutation(
    async (adjustment: Adjustment) => deleteAdjustment(adjustment.id, adjustment.payPeriodId),
    {
      onSuccess: async (response, deletedAdjustment: Adjustment) => {
        const queryKey = [...cacheKey, { payPeriodId: deletedAdjustment.payPeriodId }];

        await queryClient.cancelQueries(queryKey);
        const cachedAdjustments = queryClient.getQueryData(queryKey);

        const index = cachedAdjustments.findIndex((item) => item.id === deletedAdjustment.id);
        if (index >= 0) {
          cachedAdjustments.splice(index, 1);
        }

        queryClient.setQueryData(queryKey, () => cachedAdjustments);
        return cachedAdjustments;
      },
    },
  );
  return {
    mutateAsync: async (
      data: Adjustment,
      options?: AdjustmentDeleteMutationOptions,
    ): Promise<void> => {
      try {
        await mutateAsync(data);
        options?.onSuccess?.();
      } catch (e) {
        options?.onError?.(e);
      }
    },
    isLoading,
  };
}
