/* @flow */
export const AutomatedIncentive: {
  PerUnitSalesVolunteeredShift: 1,
  PerUnitSalesContest: 2,
  HighestSalesVolumeDaily: 3,
  HighestSalesVolumeWeekly: 4,
  PerfectScheduledAdherenceMonth2: 5,
  PerfectScheduledAdherenceMonth3: 6,
  ClosesDealFirstInboundCallOfTheirShift: 7,
  ClosesTwoDealsInboundCallsFirstHourOfShift: 8,
  ClosesThreeDealsInboundCallsFirstHourOfShift: 9,
  ExtendedHours: 10,
  SDROutbound: 11,
} = Object.freeze({
  PerUnitSalesVolunteeredShift: 1,
  PerUnitSalesContest: 2,
  HighestSalesVolumeDaily: 3,
  HighestSalesVolumeWeekly: 4,
  PerfectScheduledAdherenceMonth2: 5,
  PerfectScheduledAdherenceMonth3: 6,
  ClosesDealFirstInboundCallOfTheirShift: 7,
  ClosesTwoDealsInboundCallsFirstHourOfShift: 8,
  ClosesThreeDealsInboundCallsFirstHourOfShift: 9,
  ExtendedHours: 10,
  SDROutbound: 11,
});

export type AutomatedIncentiveEnum = $Values<typeof AutomatedIncentive>;
