/* @flow */
import '../../css/dashboard/CommissionTable.css';
import * as React from 'react';
import { DealTypes } from '../../enums/DealTypeEnum';
import { SalesCommissionStatus } from '../../enums/SalesCommissionStatusEnum';
import { Table } from 'antd';
import {
  formatBooleanAsString,
  formatDateAsShortDate,
  formatNumberAsMoney,
} from '../../utils/formatter';
import { formatDealType, formatSalesCommissionStatus } from '../../utils/enumFormatter';
import { isNil, uniqBy } from 'lodash';
import SearchModalButton from '../shared/SearchModalButton';
import type { DealTypeEnum } from '../../enums/DealTypeEnum';
import type { SalesCommission } from '../../models/deals/SalesCommission';
import type { SalesCommissionStatusEnum } from '../../enums/SalesCommissionStatusEnum';

const SALES_COMMISSION_COLUMN_CONFIG = {
  customerNumber: {
    title: 'Customer Number',
    dataIndex: 'moxyCustomerNumber',
    editable: false,
    sorter: (a: SalesCommission, b: SalesCommission): any =>
      a.moxyCustomerNumber.localeCompare(b.moxyCustomerNumber),
    render: (value: string): React.Node => <SearchModalButton query={value} label={value} />,
    fixed: 'left',
    width: 155,
  },
  contractNumber: {
    title: 'Contract Number',
    dataIndex: 'contractNumber',
    editable: false,
    sorter: (a: SalesCommission, b: SalesCommission): any =>
      a.contractNumber.localeCompare(b.contractNumber),
    render: (value: string): React.Node => <SearchModalButton query={value} label={value} />,
    fixed: 'left',
    width: 155,
  },
  earnedTotal: {
    title: 'Earned this Period',
    dataIndex: 'earnedTotal',
    editable: false,
    render: (value: number): string => formatNumberAsMoney(value, true),
    sorter: (a: SalesCommission, b: SalesCommission): any => a.earnedTotal - b.earnedTotal,
    fixed: 'left',
    width: 110,
    align: 'right',
  },
  outstandingTotal: {
    title: 'Outstanding',
    dataIndex: 'outstandingTotal',
    render: (value: number): string => formatNumberAsMoney(value, true),
    sorter: (a: SalesCommission, b: SalesCommission): any =>
      a.outstandingTotal - b.outstandingTotal,
    width: 110,
    fixed: 'left',
    align: 'right',
  },
  dealType: {
    title: 'Channel',
    dataIndex: 'dealType',
    editable: false,
    render: (value: DealTypeEnum): string => formatDealType(value),
    sorter: (a: SalesCommission, b: SalesCommission): any =>
      formatDealType(a.dealType).localeCompare(formatDealType(b.dealType)),
    filterSearch: true,
    filters: ([DealTypes.Digital, DealTypes.DirectMail, DealTypes.Television].map(
      (dealType: DealTypeEnum) => ({
        text: formatDealType(dealType),
        value: dealType,
      }),
    ): {
      text: string,
      value: number,
    }[]),
    onFilter: (dealType: DealTypeEnum, deal: SalesCommission): boolean =>
      deal.dealType === dealType,
    fixed: 'left',
    width: 130,
  },
  commissionStatus: {
    title: 'Status',
    dataIndex: 'salesCommissionStatus',
    editable: false,
    render: (value: SalesCommissionStatusEnum, commission: SalesCommission): string =>
      formatSalesCommissionStatus(value, commission.soldDetails.isFullPay),
    sorter: (a: SalesCommission, b: SalesCommission): any =>
      formatSalesCommissionStatus(a.salesCommissionStatus, a.soldDetails.isFullPay).localeCompare(
        formatSalesCommissionStatus(b.salesCommissionStatus, b.soldDetails.isFullPay),
      ),
    filterSearch: true,
    filters: (uniqBy(
      [
        SalesCommissionStatus.DownPaymentTooLow,
        SalesCommissionStatus.AwaitingDownPayment,
        SalesCommissionStatus.InitialPayout,
        SalesCommissionStatus.AwaitingFunding,
        SalesCommissionStatus.PaidOut,
        SalesCommissionStatus.Cancelled,
        SalesCommissionStatus.Reassigned,
        SalesCommissionStatus.AwaitingTrialPeriodEnd,
        SalesCommissionStatus.CancellationPeriod,
      ].flatMap((status) => [
        {
          text: formatSalesCommissionStatus(status, false),
          value: formatSalesCommissionStatus(status, false),
        },
        {
          text: formatSalesCommissionStatus(status, true),
          value: formatSalesCommissionStatus(status, true),
        },
      ]),
      (item) => item.text,
    ): {
      text: string,
      value: string,
    }[]),
    onFilter: (status: string, deal: SalesCommission): boolean =>
      formatSalesCommissionStatus(
        deal.salesCommissionStatus,
        deal.soldDetails.isFullPay,
      ).localeCompare(status) === 0,
    fixed: 'left',
    width: 130,
  },
  soldDate: {
    title: 'Sold Date',
    dataIndex: ['soldDetails', 'soldDate'],
    editable: false,
    render: (value: string): string => formatDateAsShortDate(value, true, true),
    sorter: (a: SalesCommission, b: SalesCommission): any =>
      new Date(a.soldDetails.soldDate) - new Date(b.soldDetails.soldDate),
    width: 100,
    align: 'center',
    defaultSortOrder: 'ascend',
  },
  cancelledDate: {
    title: 'Canceled Date',
    dataIndex: ['cancelledDetails', 'cancelledDate'],
    editable: false,
    render: (value: string): string => formatDateAsShortDate(value, true, true),
    sorter: (a: SalesCommission, b: SalesCommission): any =>
      new Date(a.cancelledDetails?.cancelledDate ?? 0) -
      new Date(b.cancelledDetails?.cancelledDate ?? 0),
    width: 100,
    align: 'center',
  },
  fundedDate: {
    title: 'Funded Date',
    dataIndex: ['fundedDetails', 'fundedDate'],
    editable: false,
    render: (value: string): string => formatDateAsShortDate(value, true, true),
    sorter: (a: SalesCommission, b: SalesCommission): any =>
      new Date(a.fundedDetails?.fundedDate ?? 0) - new Date(b.fundedDetails?.fundedDate ?? 0),
    width: 100,
    align: 'center',
  },
  paymentType: {
    title: 'Payment Method',
    dataIndex: ['soldDetails', 'paymentType'],
    editable: false,
    width: 120,
  },
  fullPay: {
    title: 'Full Pay?',
    dataIndex: ['soldDetails', 'isFullPay'],
    editable: false,
    render: (value: boolean): string => formatBooleanAsString(value),
    sorter: (a: SalesCommission, b: SalesCommission): any =>
      formatBooleanAsString(a.soldDetails.isFullPay).localeCompare(
        formatBooleanAsString(b.soldDetails.isFullPay),
      ),
    width: 80,
  },
  splitDeal: {
    title: 'Split Deal?',
    dataIndex: ['isSplitDeal'],
    editable: false,
    render: (value: boolean): string => formatBooleanAsString(value),
    sorter: (a: SalesCommission, b: SalesCommission): any =>
      formatBooleanAsString(a.isSplitDeal).localeCompare(formatBooleanAsString(b.isSplitDeal)),
    width: 80,
  },
  collectedPaymentTotal: {
    title: 'Payment Collected',
    dataIndex: 'collectedPaymentTotal',
    editable: false,
    render: (value: number): string => formatNumberAsMoney(value, true),
    sorter: (a: SalesCommission, b: SalesCommission): any =>
      a.collectedPaymentTotal - b.collectedPaymentTotal,
    width: 100,
    align: 'right',
  },
  activationFee: {
    title: 'Activation Fee',
    dataIndex: 'eliteClubFee',
    editable: false,
    render: (value: number): string => formatNumberAsMoney(value, true),
    sorter: (a: SalesCommission, b: SalesCommission): any => a.eliteClubFee - b.eliteClubFee,
    width: 100,
    align: 'right',
  },
  rawTotal: {
    title: 'Commission Total',
    dataIndex: 'rawTotal',
    editable: false,
    render: (value: number): string => formatNumberAsMoney(value, true),
    sorter: (a: SalesCommission, b: SalesCommission): any => a.rawTotal - b.rawTotal,
    width: 110,
    align: 'right',
  },
  holdbackTotal: {
    title: 'Holdback',
    dataIndex: 'holdbackTotal',
    editable: false,
    render: (value: number): string => formatNumberAsMoney(value, true),
    sorter: (a: SalesCommission, b: SalesCommission): any => a.holdbackTotal - b.holdbackTotal,
    width: 100,
    align: 'right',
  },
  financeTermAmount: {
    title: 'Fin Term Contr.',
    dataIndex: 'financeTermAmount',
    editable: false,
    render: (value: number): string => formatNumberAsMoney(value, true),
    sorter: (a: SalesCommission, b: SalesCommission): any =>
      a.financeTermAmount - b.financeTermAmount,
    width: 100,
    align: 'right',
  },
  retailDeviationAmount: {
    title: 'Retail Dev Contr.',
    dataIndex: 'retailDeviationAmount',
    editable: false,
    render: (value: number): string => formatNumberAsMoney(value, true),
    sorter: (a: SalesCommission, b: SalesCommission): any =>
      a.retailDeviationAmount - b.retailDeviationAmount,
    width: 100,
    align: 'right',
  },
  policyTermAmount: {
    title: 'Term Contr.',
    dataIndex: 'policyTermAmount',
    editable: false,
    render: (value: number): string => formatNumberAsMoney(value, true),
    sorter: (a: SalesCommission, b: SalesCommission): any =>
      a.policyTermAmount - b.policyTermAmount,
    width: 100,
    align: 'right',
  },
  eliteActivationAmount: {
    title: 'Elite Contr.',
    dataIndex: 'eliteActivationAmount',
    editable: false,
    render: (value: number): string => formatNumberAsMoney(value, true),
    sorter: (a: SalesCommission, b: SalesCommission): any =>
      a.eliteActivationAmount - b.eliteActivationAmount,
    width: 100,
    align: 'right',
  },
  closingRatioBonusAmount: {
    title: 'IB CR Contr.',
    dataIndex: 'closingRatioBonusAmount',
    editable: false,
    render: (value: number): string => formatNumberAsMoney(value, true),
    sorter: (a: SalesCommission, b: SalesCommission): any =>
      a.closingRatioBonusAmount - b.closingRatioBonusAmount,
    width: 100,
    align: 'right',
  },
  outboundClosingRatioBonusAmount: {
    title: 'OB CR Contr.',
    dataIndex: 'outboundClosingRatioBonusAmount',
    editable: false,
    render: (value: number): string => formatNumberAsMoney(value, true),
    sorter: (a: SalesCommission, b: SalesCommission): any =>
      a.outboundClosingRatioBonusAmount - b.outboundClosingRatioBonusAmount,
    width: 100,
    align: 'right',
  },
  tvClosingRatioPercentageBonusAmount: {
    title: 'TV CR Contr.',
    dataIndex: 'tvClosingRatioPercentageBonusAmount',
    editable: false,
    render: (value: number): string => formatNumberAsMoney(value, true),
    width: 100,
    align: 'right',
  },
  digitalClosingRatioBonusAmount: {
    title: 'Digital CR Contr.',
    dataIndex: 'digitalClosingRatioBonusAmount',
    editable: false,
    render: (value: number): string => formatNumberAsMoney(value, true),
    width: 100,
    align: 'right',
  },
  directMailClosingRatioBonusAmount: {
    title: 'DM CR Contr.',
    dataIndex: 'directMailClosingRatioBonusAmount',
    editable: false,
    render: (value: number): string => formatNumberAsMoney(value, true),
    width: 100,
    align: 'right',
  },
  dialsPerSaleBonusAmount: {
    title: 'Dials/Sale Contr.',
    dataIndex: 'dialsPerSaleBonusAmount',
    editable: false,
    render: (value: number): string => formatNumberAsMoney(value, true),
    width: 100,
    align: 'right',
  },
  outboundDialerSaleAmount: {
    title: 'OB Dialer',
    dataIndex: 'outboundDialerSaleAmount',
    editable: false,
    width: 100,
    render: (value: number): string => formatNumberAsMoney(value, true),
    align: 'right',
  },
};

type SalesCommissionColumnKey = $Keys<typeof SALES_COMMISSION_COLUMN_CONFIG>;
type Props = $ReadOnly<{
  columnConfig?: Array<SalesCommissionColumnKey>,
  deals: ?Array<SalesCommission>,
  total?: ?number,
  totalLabel?: ?string,
  minScrollHeight?: number,
  minScrollWidth?: number,
}>;

CommissionSalesTable.defaultProps = {
  columnConfig: [
    'customerNumber',
    'contractNumber',
    'dealType',
    'commissionStatus',
    'earnedTotal',
    'soldDate',
    'cancelledDate',
    'fundedDate',
    'paymentType',
    'fullPay',
    'collectedPaymentTotal',
    'activationFee',
    'holdbackTotal',
    'financeTermAmount',
    'policyTermAmount',
    'retailDeviationAmount',
    'eliteActivationAmount',
    'closingRatioBonusAmount',
    'outboundClosingRatioBonusAmount',
    'tvClosingRatioPercentageBonusAmount',
    'digitalClosingRatioBonusAmount',
    'directMailClosingRatioBonusAmount',
    'dialsPerSaleBonusAmount',
    'splitDeal',
    'rawTotal',
    'outboundDialerSaleAmount',
  ],
  minScrollHeight: 600,
  minScrollWidth: 1000,
};

function CommissionSalesTable(props: Props): React.Node {
  const { columnConfig, deals, total, totalLabel, minScrollHeight, minScrollWidth } = props;
  const scroll = { y: minScrollHeight, x: minScrollWidth };

  return (
    <Table
      bordered
      columns={getColumns(columnConfig)}
      dataSource={deals}
      size="small"
      pagination={false}
      scroll={scroll}
      footer={() => getFooter(total, totalLabel)}
      rowKey={(commission) => commission.id}
      sticky
    />
  );
}

function getColumns(columnNames: ?Array<SalesCommissionColumnKey>): any {
  return columnNames ? columnNames.map((name) => SALES_COMMISSION_COLUMN_CONFIG[name]) : [];
}

function getFooter(total: ?number, label: ?string): React.Node {
  if (total === null || total === undefined) {
    return null;
  }

  const formattedTotal = formatNumberAsMoney(total, true);
  return (
    <div className="commission-table-footer">
      <div className="commission-table-footer-cell commission-table-footer-cell-label">
        {isNil(label) ? 'Total Earned this Period' : label}:
      </div>
      <div className="commission-table-footer-cell">{formattedTotal}</div>
    </div>
  );
}

export default CommissionSalesTable;
