/* @flow */
import '../../css/pay-period-review/ManagerSummaryWidget.css';
import * as React from 'react';
import { Button, Collapse, Empty, Statistic, Tooltip } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { ImpersonationContext } from '../../context/ImpersonationContext';
import { LockTypes } from '../../enums/LockTypeEnum';
import { assign, find, map, size } from 'lodash';
import { formatNumberAsMoney } from '../../utils/formatter';
import { useCallback, useContext, useState } from 'react';
import ApprovalProgressBar from '../shared/ApprovalProgressBar';
import ApproveConfirmationModal from './ApproveConfirmationModal';
import TeamSummaryTable from './TeamSummaryTable';
import useMyRole from '../../hooks/services/role/useMyRole';
import type { AgentCommissionReview } from '../../models/reviews/AgentCommissionReview';
import type { LeaderReviewSummary } from '../../models/reviews/LeaderReviewSummary';
import type { PayPeriod } from '../../models/PayPeriod';

const { Panel } = Collapse;

type Props = $ReadOnly<{
  title: string,
  sectionId: string,
  summary: ?LeaderReviewSummary,
  payPeriod: PayPeriod,
  selectedRows: ?Array<AgentCommissionReview>,
  setSelectedRowsCallback: ({ teamId: string, records: Array<any> }) => void,
}>;

function ManagerSummaryWidget(props: Props): React.Node {
  const impersonation = useContext(ImpersonationContext);
  const isImpersonated = impersonation.agent !== null;

  const { title, sectionId, payPeriod, summary, selectedRows, setSelectedRowsCallback } = props;
  // States
  const [approveSelectedModalIsVisible, setApproveSelectedModalIsVisible] = useState(false);
  const [approveModalTeamName, setApproveModalTeamName] = useState('');
  const myRole = useMyRole();

  // Callbacks
  const setApproveSelectedModalIsVisibleCallback = useCallback(
    (value) => setApproveSelectedModalIsVisible(value),
    [],
  );
  const setApproveModalTeamNameCallback = useCallback(
    (value) => setApproveModalTeamName(value),
    [],
  );

  // Functions
  const userHasWriteAccess = () => !myRole?.hasReadOnlyAccess;

  const getSelectedApprovalRecordsForModal = (rows: ?Array<AgentCommissionReview>) => {
    return map(rows, (row: AgentCommissionReview) => {
      const isSelected = find(
        selectedRows,
        (selectedRow: AgentCommissionReview) =>
          selectedRow.agentCommissionId === row.agentCommissionId,
      );
      return assign({ isSelected }, row);
    });
  };

  const getManagersTableColumnConfig = (): Array<string> => {
    return ['rep', 'howardSimonId', 'managedTeams', 'adjustments', 'earnedTotal'];
  };

  const getApproveButtonTooltipText = (
    countOfSelected: number,
    isLocked: boolean,
    isImpersonated: boolean,
  ): string => {
    if (isLocked) return 'Cannot approve when a period is locked';
    if (isImpersonated) return 'Cannot approve while viewing as another user';
    if (countOfSelected === 0) return 'Select records first before approving';
    return 'Approve selected records';
  };

  const getWidgetHeaderContent = (props: Props): React.Node => {
    const { summary, payPeriod, selectedRows } = props;
    const isLocked =
      payPeriod && payPeriod.payPeriodLocks
        ? payPeriod.payPeriodLocks.some((x) => x.lockType === LockTypes.Commissions)
        : true;
    const approveTooltipText = getApproveButtonTooltipText(
      size(selectedRows),
      isLocked,
      isImpersonated,
    );

    return (
      <>
        <div className="manager-reviews-table-summary-main-content">
          <Statistic
            className="manager-reviews-table-summary-team-statistic team-name-statistic"
            title="Team"
            value={title}
          />
          <Statistic
            className="manager-reviews-table-summary-statistic total-commission-statistic"
            title="Total Commission"
            value={formatNumberAsMoney(summary?.summary.totalEarnedCommission, true)}
          />
          <Statistic
            className="manager-reviews-table-summary-statistic agent-count-statistic"
            title="Agents"
            value={summary?.summary.numberOfAgents}
          />
          <div className="manager-reviews-table-summary-content-approved">
            <ApprovalProgressBar
              countOfApproved={summary?.summary.numberOfAgentsApproved ?? 0}
              countOfSelectedForApproval={size(selectedRows)}
              countOfTotal={summary?.summary.numberOfAgents ?? 0}
              showTitle={false}
              showSubProgressBar={false}
              title={`${summary?.summary.numberOfAgentsApproved ?? 0} approved`}
              type="dashboard"
              width={80}
            />
          </div>
          <div className="manager-reviews-table-summary-button-container">
            <Tooltip title={approveTooltipText}>
              <Button
                className="manager-reviews-table-summary-button"
                disabled={!userHasWriteAccess() || size(selectedRows) === 0 || isLocked}
                icon={<CheckCircleOutlined />}
                onClick={(event) => {
                  event.stopPropagation();
                  setApproveSelectedModalIsVisibleCallback(true);
                  setApproveModalTeamNameCallback(title);
                }}
                type="primary"
              ></Button>
            </Tooltip>
          </div>
        </div>
      </>
    );
  };

  const getTableContent = (summary: ?LeaderReviewSummary, payPeriod: PayPeriod): React.Node => {
    if (!summary || !summary.summary || summary.summary.numberOfAgents === 0) {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>;
    }

    return (
      <div className="manager-reviews-table-summary-expanded-section">
        <TeamSummaryTable
          reviews={summary.reviews}
          payPeriod={payPeriod}
          selectedRows={selectedRows}
          setSelectedRowsCallback={setSelectedRowsCallback}
          columnConfig={getManagersTableColumnConfig()}
          teamId={sectionId}
        />
      </div>
    );
  };

  if (!summary) return null;

  return (
    <>
      <div className="manager-reviews-table-summary-container">
        <Collapse expandIconPosition="start">
          <Panel key="1" extra={getWidgetHeaderContent(props)}>
            {getTableContent(summary, payPeriod)}
          </Panel>
        </Collapse>
      </div>
      <ApproveConfirmationModal
        isVisible={approveSelectedModalIsVisible}
        payPeriod={payPeriod}
        onModalFinish={() => setSelectedRowsCallback({ teamId: sectionId, records: [] })}
        reviews={getSelectedApprovalRecordsForModal(summary.reviews)}
        updateModalVisibility={setApproveSelectedModalIsVisibleCallback}
        teamName={approveModalTeamName ?? ''}
      />
    </>
  );
}

export default ManagerSummaryWidget;
