/* @flow */
export const IncentiveStatuses: {
  Draft: 1,
  Published: 2,
  Unpublished: 3,
} = Object.freeze({
  Draft: 1,
  Published: 2,
  Unpublished: 3,
});

export type IncentiveStatusEnum = $Values<typeof IncentiveStatuses>;
