/* @flow */

import * as React from 'react';
import { map, orderBy } from 'lodash';
import type { IncentiveField } from '../../models/incentives/IncentiveField';

type Props = $ReadOnly<{
  incentiveFields: ?Array<IncentiveField>,
}>;

function IncentiveValues(props: Props): React.Node {
  const { incentiveFields } = props;

  const buildReadOnlyUiComponents = (incentiveFields: ?Array<IncentiveField>) => {
    if (!incentiveFields || incentiveFields.length <= 0) return null;

    return map(
      orderBy(incentiveFields, (incentiveField) => incentiveField.order),
      (incentiveField) => {
        return `${incentiveField.name}`;
      },
    ).join(', ');
  };

  return buildReadOnlyUiComponents(incentiveFields);
}

export default IncentiveValues;
