/* @flow */
import { acquireTokenAsync } from '../AuthService';
import axios from 'axios';
import config from '../../config/';

class WebAPI {
  #url: string;
  #version: string;
  #impersonationHeaders: ?{ [key: string]: string };

  constructor(url: string, version: string) {
    this.#url = url;
    this.#version = version;

    if (config.impersonatedEmail) {
      this.#impersonationHeaders = {
        'x-impersonation-email-address': config.impersonatedEmail,
      };
    }
  }

  setImpersonationHeaders(headers: ?{ [key: string]: string }): void {
    if (headers && headers['x-impersonation-email-address']) {
      this.#impersonationHeaders = headers;
      return;
    }

    if (config.impersonatedEmail) {
      this.#impersonationHeaders = {
        'x-impersonation-email-address': config.impersonatedEmail,
      };
    }
  }

  async get(
    path: string,
    queryData: ?{ [key: string]: any } = null,
    useImpersonation: boolean = true,
  ): Promise<any> {
    const completeUrl = this.#buildRequestUrl(this.#url, this.#version, path, queryData);
    const headers = await this.#createHeaders(useImpersonation);
    return await axios.get(completeUrl, headers);
  }

  async post(path: string, data: Object, useImpersonation: boolean = true): Promise<any> {
    const completeUrl = this.#buildRequestUrl(this.#url, this.#version, path);
    const headers = await this.#createHeaders(useImpersonation);
    return await axios.post(completeUrl, data, headers);
  }

  async put(path: string, data: Object, useImpersonation: boolean = true): Promise<any> {
    const completeUrl = this.#buildRequestUrl(this.#url, this.#version, path);
    const headers = await this.#createHeaders(useImpersonation);
    return await axios.put(completeUrl, data, headers);
  }

  async patch(path: string, data: Object, useImpersonation: boolean = true): Promise<any> {
    const completeUrl = this.#buildRequestUrl(this.#url, this.#version, path);
    const headers = await this.#createHeaders(useImpersonation);
    return await axios.patch(completeUrl, data, headers);
  }

  async delete(path: string, useImpersonation: boolean = true): Promise<any> {
    const completeUrl = this.#buildRequestUrl(this.#url, this.#version, path);
    const headers = await this.#createHeaders(useImpersonation);
    return await axios.delete(completeUrl, headers);
  }

  async #getAuthToken(): Promise<string> {
    const token = await acquireTokenAsync();
    return token?.accessToken ?? '';
  }

  async #createHeaders(useImpersonation: boolean): Promise<Object> {
    const token = await this.#getAuthToken();

    return {
      headers: {
        ...(useImpersonation && this.#impersonationHeaders),
        Authorization: `Bearer ${token}`,
      },
    };
  }

  #buildRequestUrl(
    baseUrl: string,
    version: string,
    resourceUri: string,
    queryData: ?{ [key: string]: any },
  ): string {
    let requestUrl = baseUrl;
    if (version) {
      requestUrl += `/${version}`;
    }

    if (resourceUri) {
      requestUrl += `/${resourceUri}`;
    }

    if (queryData) {
      const queryString = Object.keys(queryData)
        .map((key) => key + '=' + (queryData[key]: string))
        .join('&');

      if (queryString.length > 0) {
        requestUrl += `?${queryString}`;
      }
    }

    return requestUrl;
  }
}

export default WebAPI;
