/* @flow */
import * as React from 'react';
import { IncentiveFieldTypes } from '../../enums/IncentiveFieldTypeEnum';
import { Select } from 'antd';
import { formatFieldTypes } from '../../utils/enumFormatter';
import { map } from 'lodash';
import { useState } from 'react';
import type { IncentiveFieldTypeEnum } from '../../enums/IncentiveFieldTypeEnum';
const { Option } = Select;

type Props = {
  value?: ?string,
  defaultValue?: string,
  onChange?: ?(value: string) => void,
};

function FieldTypeSelector(props: Props): React.Node {
  const [value, setValue] = useState(props.value);

  const onSelectChange = (newValue: string) => {
    setValue(newValue);
    props.onChange?.(newValue);
  };

  const fieldTypeOptions = map(IncentiveFieldTypes, (fieldType: IncentiveFieldTypeEnum) => {
    return (
      <Option key={fieldType} value={fieldType}>
        {formatFieldTypes(fieldType)}
      </Option>
    );
  });

  return (
    <Select style={{ width: 210 }} value={props.defaultValue ?? value} onChange={onSelectChange}>
      {fieldTypeOptions}
    </Select>
  );
}

export default FieldTypeSelector;
