/* @flow */
import * as React from 'react';
import { formatNumberAsMoney } from '../../utils/formatter';
import { isNil } from 'lodash';
import type { AgentIncentive } from '../../models/incentives/AgentIncentive';
import type { Incentive } from '../../models/incentives/Incentive';

type Props = $ReadOnly<{
  incentives: ?Array<Incentive>,
  agentIncentive: ?AgentIncentive,
  selectedIncentiveId: ?number,
  amount: ?number,
  isInModal: boolean,
}>;

function OriginalAmountLabel(props: Props): React.Node {
  const { incentives, agentIncentive, selectedIncentiveId, amount, isInModal } = props;

  const incentiveId = agentIncentive?.incentiveId ?? selectedIncentiveId;

  const perUnitText = () => {
    return agentIncentive?.pricePerUnit !==
      incentives?.find((incentive) => incentive.id === incentiveId)?.fixedAmount ? (
      <span className="original-amount-label">
        {' '}
        ({formatNumberAsMoney(agentIncentive?.pricePerUnit, true)} per unit, Original per unit:{' '}
        {formatNumberAsMoney(
          incentives?.find((incentive) => incentive.id === incentiveId)?.fixedAmount ?? 0,
          true,
        )}
        )
      </span>
    ) : (
      <span className="original-amount-label">
        ({formatNumberAsMoney(agentIncentive?.pricePerUnit, true)} per Unit)
      </span>
    );
  };

  const getText = () => {
    if (agentIncentive?.incentiveValues?.find((x) => x.isMultiplier)) return <i>{perUnitText()}</i>;

    return agentIncentive?.amount !==
      incentives?.find((incentive) => incentive.id === incentiveId)?.fixedAmount ? (
      <span className="original-amount-label">
        <i>
          {' '}
          (Original:{' '}
          {formatNumberAsMoney(
            incentives?.find((incentive) => incentive.id === incentiveId)?.fixedAmount ?? 0,
            true,
          )}
          )
        </i>
      </span>
    ) : null;
  };

  return isInModal ? (
    !isNil(incentiveId) &&
    amount !== incentives?.find((incentive) => incentive.id === incentiveId)?.fixedAmount ? (
      <span className="original-amount-label">
        <i>
          {' '}
          (Original:{' '}
          {formatNumberAsMoney(
            incentives?.find((incentive) => incentive.id === incentiveId)?.fixedAmount ?? 0,
            true,
          )}
          )
        </i>
      </span>
    ) : null
  ) : (
    <div>{getText()}</div>
  );
}

export default OriginalAmountLabel;
