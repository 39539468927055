/* @flow */
import { convertHeaderStringToObject } from '../utils/downloader';
import { get, orderBy } from 'lodash';
import WebAPIClient from './api/WebAPIClient';
import type { AgentIncentive } from '../models/incentives/AgentIncentive';
import type { AgentIncentiveMutation } from '../models/incentives/AgentIncentiveMutation';
import type { Contest } from '../models/incentives/Contest';
import type { ContestMutation } from '../models/incentives/ContestMutation';
import type { File } from '../models/File';
import type { Incentive } from '../models/incentives/Incentive';
import type { IncentiveMutation } from '../models/incentives/IncentiveMutation';
import type { IncentivesReview } from '../models/incentives/IncentiveReview';

export const getIncentives = async (excludeUnpublished: boolean): Promise<Array<Incentive>> => {
  const response = await WebAPIClient.get('incentives', { excludeUnpublished });
  return orderBy(get(response, 'data', null), (incentive) => incentive.name, 'asc');
};

export const createIncentive = async (incentiveId: IncentiveMutation): Promise<Incentive> =>
  await WebAPIClient.post('incentives', incentiveId);

export const updateIncentiveStatus = async (incentive: IncentiveMutation): Promise<any> =>
  await WebAPIClient.put(`incentives/${incentive.id}/status/${incentive.incentiveStatus}`);

export const updateIncentive = async (incentive: IncentiveMutation): Promise<Incentive> =>
  await WebAPIClient.put(`incentives/${incentive.id}`, incentive);

export const deleteIncentive = async (incentiveId: number): Promise<any> =>
  await WebAPIClient.delete(`incentives/${incentiveId}`);

export const getAgentIncentives = async (payPeriodId: number): Promise<Array<IncentivesReview>> => {
  const response = await WebAPIClient.get('incentives/agentIncentives', {
    payPeriodId: payPeriodId,
  });
  return get(response, 'data', []);
};

export const updateAgentIncentive = async (
  agentIncentive: AgentIncentiveMutation,
): Promise<AgentIncentive> =>
  await WebAPIClient.put(`incentives/agentincentives/${agentIncentive.id}`, agentIncentive);

export const deleteAgentIncentive = async (
  agentIncentiveId: number,
  payPeriodId: number,
): Promise<any> =>
  await WebAPIClient.delete(
    `incentives/agentincentives/${agentIncentiveId}?payPeriodId=${payPeriodId}`,
  );

export const createAgentIncentive = async (
  agentIncentiveId: AgentIncentiveMutation,
): Promise<AgentIncentive> =>
  await WebAPIClient.post('incentives/agentincentives', agentIncentiveId);

export const approveAgentIncentive = async (
  agentIncentiveIds: Array<number>,
  payPeriodId: number,
): Promise<AgentIncentive> => {
  const response = await WebAPIClient.put(
    `incentives/agentincentives/approve?payPeriodId=${payPeriodId}`,
    agentIncentiveIds,
  );
  return get(response, 'data', []);
};

export const downloadAgentIncentives = async (payPeriodId: number): Promise<File> => {
  const response = await WebAPIClient.get('incentives/agentincentives/download', {
    payPeriodId: payPeriodId,
  });

  const header = get(response, 'headers.content-disposition', []);
  const headerObject = convertHeaderStringToObject(header);
  const fileName = headerObject['filename'];

  return {
    fileContents: get(response, 'data', []),
    contentType: get(response, 'headers.content-type', []),
    fileName: fileName,
  };
};

export const getContests = async (payPeriodId: number): Promise<Array<Contest>> => {
  const response = await WebAPIClient.get('incentives/contests', {
    payPeriodId: payPeriodId,
  });
  return get(response, 'data', []);
};

export const createContest = async (contest: ContestMutation): Promise<Contest> =>
  await WebAPIClient.post('incentives/contests', contest);

export const updateContest = async (contest: ContestMutation): Promise<Contest> =>
  await WebAPIClient.put(`incentives/contests/${contest.id}`, contest);

export const deleteContest = async (contestId: number): Promise<any> =>
  await WebAPIClient.delete(`incentives/contests/${contestId}`);
