/* @flow */
export type RoleEnumType =
  | 'Admin'
  | 'RetentionManager'
  | 'Manager'
  | 'IncentiveCreator'
  | 'Rep'
  | 'None'
  | 'Unknown';
export const RoleEnum = Object.freeze({
  Admin: 'Admin',
  RetentionManager: 'RetentionManager',
  Manager: 'Manager',
  IncentiveCreator: 'IncentiveCreator',
  Rep: 'Rep',
  None: 'None',
  Unknown: 'Unknown',
});
