/* @flow */
export const ManagerCommissionTypes: {
  Sales: 1,
  Retention: 2,
  RetentionFlatForce: 3,
  Onboarding: 4,
  SalesAcceleration: 5,
  SalesDevelopment: 6,
  Takeover: 7,
} = Object.freeze({
  Sales: 1,
  Retention: 2,
  RetentionFlatForce: 3,
  Onboarding: 4,
  SalesAcceleration: 5,
  SalesDevelopment: 6,
  Takeover: 7,
});

export type ManagerCommissionTypeEnum = $Values<typeof ManagerCommissionTypes>;
