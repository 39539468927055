/* @flow */
import { approveCommissions } from '../../../services/CommissionService';
import { filter, findIndex, forEach, head, reduce, size } from 'lodash';
import { useMutation, useQueryClient } from 'react-query';
import CommissionReviewsServiceKeys from '../../../constants/CommissionsServiceKeys';
import useQueryCacheKey from '../../useQueryCacheKey';
import type { AgentCommission } from '../../../models/AgentCommission';

type ApproveRequest = $ReadOnly<{
  agentCommissionIds: Array<number>,
  payPeriodId: number,
}>;

type ApproveCommissionsMutationOptions = $ReadOnly<{
  onSuccess?: () => void,
  onError?: (error: any) => void,
}>;

type ApproveCommissionsMutation = $ReadOnly<{
  mutateAsync: (data: ApproveRequest, options?: ApproveCommissionsMutationOptions) => Promise<void>,
  isLoading: boolean,
}>;

export default function useApproveCommissions(): ApproveCommissionsMutation {
  const queryClient = useQueryClient();
  const cacheKey = useQueryCacheKey(CommissionReviewsServiceKeys.COMMISSION_REVIEW);

  const { mutateAsync, isLoading } = useMutation(
    (request: ApproveRequest) =>
      approveCommissions(request.agentCommissionIds, request.payPeriodId),
    {
      onSuccess: async (result: Array<AgentCommission>) => {
        const queryKey = [...cacheKey, { payPeriodId: head(result).payPeriod.id }];

        await queryClient.cancelQueries(queryKey);
        const cachedReviews = queryClient.getQueryData(queryKey);
        const approvedReviews = filter(result, (review) => review.isApproved);

        forEach(approvedReviews, (value) => {
          forEach(cachedReviews.teamReviews, (teamReview) => {
            const index = findIndex(
              teamReview.commissionReviews,
              (review) => review.agentCommissionId === value.id,
            );

            if (index >= 0) {
              teamReview.commissionReviews[index].isApproved = true;
            }
          });

          forEach(cachedReviews.teamReviews, (teamReview) => {
            teamReview.teamSummary.numberOfAgentsApproved = size(
              filter(teamReview.commissionReviews, (review) => review.isApproved === true),
            );
          });

          if (cachedReviews.managerReview) {
            TeamReview(cachedReviews.managerReview);
          }

          if (cachedReviews.teamLeadReview) {
            TeamReview(cachedReviews.teamLeadReview);
          }

          function TeamReview(teamReview) {
            const index = findIndex(
              teamReview.reviews,
              (review) => review.agentCommissionId === value.id,
            );

            if (index >= 0) {
              teamReview.reviews[index].isApproved = true;
            }

            teamReview.summary.numberOfAgentsApproved = size(
              filter(teamReview.reviews, (review) => review.isApproved === true),
            );
          }
        });

        cachedReviews.summary.numberOfAgentsApproved = reduce(
          cachedReviews.teamReviews,
          (total, teamReview) => {
            return total + teamReview.teamSummary.numberOfAgentsApproved;
          },
          0,
        );

        if (cachedReviews.managerReview) {
          TeamReviews(cachedReviews.managerReview);
        }

        if (cachedReviews.teamLeadReview) {
          TeamReviews(cachedReviews.teamLeadReview);
        }

        function TeamReviews(team) {
          cachedReviews.summary.numberOfAgentsApproved += team.summary.numberOfAgentsApproved;
        }

        return cachedReviews;
      },
    },
  );

  return {
    mutateAsync: async (
      data: ApproveRequest,
      options?: ApproveCommissionsMutationOptions,
    ): Promise<void> => {
      try {
        await mutateAsync(data);
        options?.onSuccess?.();
      } catch (e) {
        options?.onError?.(e);
      }
    },
    isLoading,
  };
}
