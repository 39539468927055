/* @flow */
import * as React from 'react';
import { Select, Tooltip } from 'antd';
import { find, head, isNil, map } from 'lodash';
import { formatDateAsShortDate } from '../../utils/formatter';
import { useEffect, useState } from 'react';
import usePayPeriods from '../../hooks/services/pay-period/usePayPeriods';

type Props = $ReadOnly<{
  payPeriodId: ?number,
  onChange: (e: number, setQueryParam: boolean) => void,
  showStatus: boolean,
}>;

function PayPeriodSelector(props: Props): React.Node {
  const { payPeriodId, onChange, showStatus } = props;

  // States
  const payPeriods = usePayPeriods();
  const [initialized, setInitialized] = useState(false);
  const [isPayPeriodIdInvalid, setIsPayPeriodIdInvalid] = useState(payPeriodId === 0);

  // Effects
  useEffect(() => {
    if (isNil(payPeriods) || initialized) {
      return;
    }

    const defaultPayPeriodId = payPeriodId ?? head(payPeriods)?.id;
    onChange(defaultPayPeriodId, false);
    setInitialized(true);
  }, [payPeriods, payPeriodId, initialized, onChange]);

  useEffect(() => {
    if (isNil(payPeriods) || isNil(payPeriodId)) {
      return;
    }

    setIsPayPeriodIdInvalid(!find(payPeriods, (payPeriod) => payPeriod.id === payPeriodId));
  }, [payPeriodId, payPeriods, showStatus]);

  // Render
  if (!payPeriods) {
    return <Select placeholder="Loading Pay Periods..." value={null} loading />;
  }

  const options = map(payPeriods, (pp) => {
    const startDate = formatDateAsShortDate(pp.startDate);
    const endDate = formatDateAsShortDate(pp.endDate);
    const label = `${startDate} - ${endDate}`;
    return (
      <Select.Option key={pp.id} value={pp.id} label={label}>
        {label}
      </Select.Option>
    );
  });

  const payPeriodSelector = (
    <Select
      placeholder="Select a Pay Period"
      optionFilterProp="label"
      value={isPayPeriodIdInvalid ? null : payPeriodId}
      status={showStatus && isPayPeriodIdInvalid ? 'error' : null}
      onChange={(value) => onChange(value, true)}
    >
      {options}
    </Select>
  );

  return showStatus && isPayPeriodIdInvalid ? (
    <Tooltip
      title="Invalid/Missing payPeriod query. Please use the selector instead."
      color="red"
      placement="right"
    >
      {payPeriodSelector}
    </Tooltip>
  ) : (
    payPeriodSelector
  );
}

export default PayPeriodSelector;
