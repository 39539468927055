/* @flow */
import '../../css/pages/PayPeriodReview.css';
import * as React from 'react';
import { Affix, BackTop, Empty } from 'antd';
import {
  clone,
  concat,
  filter,
  find,
  forEach,
  isNil,
  last,
  map,
  orderBy,
  reduce,
  size,
  split,
} from 'lodash';
import { useCallback, useState } from 'react';
import ManagerSummaryWidget from '../pay-period-review/ManagerSummaryWidget';
import PayPeriodSelector from '../shared/PayPeriodSelector';
import Section from '../shared/Section';
import SubHeader from '../shared/SubHeader';
import SummaryWidget from '../pay-period-review/SummaryWidget';
import TeamSummaryWidget from '../pay-period-review/TeamSummaryWidget';
import usePayPeriodQueryState from '../../hooks/usePayPeriodQueryState';
import usePayPeriods from '../../hooks/services/pay-period/usePayPeriods';
import useReviews from '../../hooks/services/review/useReviews';
import type { LeaderReviewSummary } from '../../models/reviews/LeaderReviewSummary';
import type { PayPeriod } from '../../models/PayPeriod';
import type { TeamReviewSummary } from '../../models/reviews/TeamReviewSummary';

function PayPeriodReview(): React.Node {
  // States
  const { payPeriodId, onPayPeriodChange } = usePayPeriodQueryState();
  const [selectedRowsDictionary, setSelectedRowsDictionary] = useState({});

  // Queries
  const { isLoading, reviews } = useReviews(payPeriodId);
  const payPeriods = usePayPeriods();

  // Callbacks
  const setSelectedRowsDictionaryCallback = useCallback(
    (action: { teamId: string, records: Array<any> }) => {
      const clonedDictionary = clone(selectedRowsDictionary);
      const key = `${action.teamId}-${payPeriodId ?? ''}`;

      if (action.records === null || size(action.records) === 0) {
        clonedDictionary[key] = [];
        setSelectedRowsDictionary(clonedDictionary);
        return;
      }

      forEach(action.records, (record) => {
        const isInDictionary =
          find(clonedDictionary[key], (row) => row.key === record.key) !== undefined;

        clonedDictionary[key] = isInDictionary
          ? filter(clonedDictionary[key], (row) => row.key !== record.key)
          : filter(concat(clonedDictionary[key], record), (row) => row !== undefined);
      });

      setSelectedRowsDictionary(clonedDictionary);
    },
    [selectedRowsDictionary, payPeriodId],
  );

  const selectedPayPeriod = find(payPeriods, (payPeriod) => payPeriod.id === payPeriodId);
  const summary = !isNil(reviews) ? reviews.summary : null;
  const teamReviews = !isNil(reviews) ? reviews.teamReviews : null;
  const managerReview = !isNil(reviews) ? reviews.managerReview : null;
  const teamLeadReview = !isNil(reviews) ? reviews.teamLeadReview : null;

  const getTeamBreakdownSections = (
    managerReview: ?LeaderReviewSummary,
    teamReviews: ?Array<TeamReviewSummary>,
    payPeriod: PayPeriod,
  ): React.Node => {
    if (!teamReviews) return null;

    const teamReviewsOrdered = orderBy(teamReviews, (review: TeamReviewSummary) => review.teamName);
    var sections = map(teamReviewsOrdered, (value: TeamReviewSummary) => {
      const key = `${value.teamId}-${payPeriodId || 0}`;
      return (
        <TeamSummaryWidget
          key={value.teamId}
          payPeriod={payPeriod}
          summary={value}
          selectedRows={selectedRowsDictionary[key]}
          setSelectedRowsCallback={setSelectedRowsDictionaryCallback}
        />
      );
    });

    if (teamLeadReview) {
      var teamLeadKey = `teamlead-${payPeriodId ?? ''}`;
      var teamLeadSection = (
        <ManagerSummaryWidget
          title="Team Leads"
          sectionId="teamlead"
          key={teamLeadKey}
          payPeriod={payPeriod}
          summary={teamLeadReview}
          selectedRows={selectedRowsDictionary[teamLeadKey]}
          setSelectedRowsCallback={setSelectedRowsDictionaryCallback}
        />
      );

      sections.unshift(teamLeadSection);
    }

    if (managerReview) {
      var managerKey = `managers-${payPeriodId ?? ''}`;
      var managerSection = (
        <ManagerSummaryWidget
          title="Managers"
          sectionId="managers"
          key={managerKey}
          payPeriod={payPeriod}
          summary={managerReview}
          selectedRows={selectedRowsDictionary[managerKey]}
          setSelectedRowsCallback={setSelectedRowsDictionaryCallback}
        />
      );

      sections.unshift(managerSection);
    }

    return sections;
  };

  const getCountOfSelectedRowsForPeriod = (payPeriodId: number): number => {
    const selectedRowsForThisPeriod = filter(selectedRowsDictionary, (value, key) => {
      const keySplit = split(key, '-');
      const keyPayPeriod = last(keySplit);
      return keyPayPeriod === payPeriodId.toString();
    });

    return reduce(
      selectedRowsForThisPeriod,
      (sum, value) => {
        return sum + size(value);
      },
      0,
    );
  };

  return (
    <>
      <SubHeader
        headerLabel={'Period Review'}
        primarySelector={null}
        secondarySelectorSeparator={'for'}
        secondarySelectorLabel={'Commissions Period'}
        secondarySelector={
          <PayPeriodSelector
            payPeriodId={payPeriodId}
            showStatus={true}
            onChange={onPayPeriodChange}
          />
        }
      />
      <Section
        headerLabel={''}
        contents={[
          <>
            {(!summary || summary.numberOfAgents <= 0) && (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
            )}
            {summary && summary.numberOfAgents > 0 && (
              <div className="commission-review-root">
                <div className="commission-review-main-content">
                  {getTeamBreakdownSections(managerReview, teamReviews, selectedPayPeriod)}
                </div>
                <Affix offsetTop={65}>
                  <SummaryWidget
                    key="reviewsSummarySection"
                    summary={summary}
                    payPeriod={selectedPayPeriod}
                    numberOfSelectedRows={getCountOfSelectedRowsForPeriod(payPeriodId || 0)}
                  />
                </Affix>
              </div>
            )}
          </>,
        ]}
        isLoading={isLoading}
      />
      <BackTop visibilityHeight={800} />
    </>
  );
}

export default PayPeriodReview;
