/* @flow */

import { updateContest } from '../../../services/IncentiveService';
import { useMutation, useQueryClient } from 'react-query';
import CommissionsServiceKeys from '../../../constants/CommissionsServiceKeys';
import useQueryCacheKey from '../../useQueryCacheKey';
import type { Contest } from '../../../models/incentives/Contest';
import type { ContestMutation } from '../../../models/incentives/ContestMutation';

type ContestUpdateMutationOptions = $ReadOnly<{
  onSuccess?: (data: Contest) => void,
  onError?: (error: any) => void,
}>;

type ContestUpdateMutation = $ReadOnly<{
  mutateAsync: (data: ContestMutation, options?: ContestUpdateMutationOptions) => Promise<?Contest>,
  isLoading: boolean,
}>;
export default function useUpdateContest(): ContestUpdateMutation {
  const queryClient = useQueryClient();
  const cacheKey = useQueryCacheKey(CommissionsServiceKeys.CONTESTS);

  const { mutateAsync, isLoading } = useMutation(
    async (contest: ContestMutation) => updateContest(contest),
    {
      onSuccess: async (response: { data: Contest, status: number, ... }) => {
        const { data } = response;
        const queryKey = [...cacheKey, { payPeriodId: data.payPeriodId }];

        await queryClient.cancelQueries(queryKey);
        const cachedContests = queryClient.getQueryData(queryKey);

        const index = cachedContests.findIndex((item) => item.id === data.id);

        if (index >= 0) {
          cachedContests.splice(index, 1, data);
          queryClient.setQueryData(queryKey, () => cachedContests);
        }
        return cachedContests[index];
      },
    },
  );

  return {
    mutateAsync: async (
      data: ContestMutation,
      options?: ContestUpdateMutationOptions,
    ): Promise<?Contest> => {
      try {
        const result = await mutateAsync(data);
        options?.onSuccess?.(result.data);
        return result.data;
      } catch (e) {
        options?.onError?.(e);
      }
      return null;
    },
    isLoading,
  };
}
