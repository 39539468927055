/* @flow */
import '../../css/shared/SubHeader.css';
import * as React from 'react';

type Props = $ReadOnly<{
  headerLabel: string,
  primarySelectorSeparator?: string,
  primarySelectorLabel?: ?string,
  primarySelector: React.Node,
  secondarySelectorSeparator: string,
  secondarySelectorLabel: string,
  secondarySelector: React.Node,
}>;

function SubHeader(props: Props): React.Node {
  const {
    headerLabel,
    primarySelectorSeparator,
    primarySelectorLabel,
    primarySelector,
    secondarySelectorSeparator,
    secondarySelectorLabel,
    secondarySelector,
  } = props;

  return (
    <div className="subheader-root">
      <span className="subheader-heading subheader-spacing">{headerLabel}</span>
      {primarySelectorSeparator !== null && primarySelectorSeparator !== undefined ? (
        <span className="subheader-separator">{primarySelectorSeparator}</span>
      ) : null}
      {primarySelectorLabel !== null && primarySelectorLabel !== undefined ? (
        <span className="subheader-label">{primarySelectorLabel}</span>
      ) : null}
      {primarySelector !== null && primarySelector !== undefined ? (
        <span className="subheader-selector">{primarySelector}</span>
      ) : null}
      <span className="subheader-separator">{secondarySelectorSeparator}</span>
      <span className="subheader-label">{secondarySelectorLabel}</span>
      {secondarySelector}
    </div>
  );
}

export default SubHeader;
