/* @flow */
export const CallDirections: {
  Inbound: 1,
  Outbound: 2,
  Both: 3,
} = Object.freeze({
  Inbound: 1,
  Outbound: 2,
  Both: 3,
});

export type CallDirectionEnum = $Values<typeof CallDirections>;
