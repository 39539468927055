/* @flow */
import '../../css/incentives/IncentiveForm.css';
import * as React from 'react';
import { Button, Checkbox, Form, Input, InputNumber, Modal, Space, notification } from 'antd';
import { DndContext, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IncentiveFieldTypes } from '../../enums/IncentiveFieldTypeEnum';
import { IncentiveStatuses } from '../../enums/IncentiveStatusEnum';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { SortableItem } from './SortableItem';
import { faCheckCircle, faPlus } from '@fortawesome/free-solid-svg-icons';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import FieldTypeSelector from './FieldTypeSelector';
import useCreateIncentive from '../../hooks/services/incentive-management/useCreateIncentive';
import useUpdateIncentive from '../../hooks/services/incentive-management/useUpdateIncentive';
import type { Incentive } from '../../models/incentives/Incentive';

type Props = $ReadOnly<{
  incentive: ?Incentive,
  isOpen: boolean,
  excludeUnpublished: boolean,
  setModal: (isOpen: boolean, incentive: ?Incentive) => void,
}>;

function IncentiveForm(props: Props): React.Node {
  const { incentive, isOpen, setModal, excludeUnpublished } = props;

  // Forms
  const [form] = Form.useForm();
  const [fieldsForm] = Form.useForm();

  // Watch
  const incentiveFieldTypeWatch = Form.useWatch('incentiveFieldType', fieldsForm);

  // States
  const [items, setItems] = useState(incentive?.incentiveFields ?? []);
  const [isMultiplierDisabled, setIsMultiplierDisabled] = useState(false);

  // Hooks
  useEffect(() => {
    if (incentive) {
      form.setFieldsValue({
        name: incentive.name,
        fixedAmount: incentive.fixedAmount,
      });
    }
  }, []);

  useEffect(() => {
    const ids = [IncentiveFieldTypes.STRING, IncentiveFieldTypes.CONTRACT_NUMBERS];
    if (items?.some((x) => x.isMultiplier) || !ids.includes(incentiveFieldTypeWatch)) {
      setIsMultiplierDisabled(true);
    } else {
      setIsMultiplierDisabled(false);
    }
  });

  // UI modifications
  const modalTitle = (incentive) => {
    if (
      incentive &&
      (incentive.incentiveStatus === IncentiveStatuses.Published ||
        incentive.incentiveStatus === IncentiveStatuses.Unpublished)
    ) {
      return 'View Incentive';
    } else if (incentive && incentive.incentiveStatus === IncentiveStatuses.Draft) {
      return 'Edit Incentive';
    } else {
      return 'New Incentive';
    }
  };

  // Mutations
  const createMutation = useCreateIncentive(excludeUnpublished);
  const createIncentive = async (incentive: Incentive) => {
    await createMutation.mutateAsync(incentive, {
      onSuccess: () => resetFields(),
      onError: (error) => {
        notification.error({
          message: 'Create Failed!',
          description:
            error.response?.data.errors?.IsMultiplier ??
            error.response?.data.message ??
            error.message,
        });
      },
    });
  };

  const updateMutation = useUpdateIncentive(excludeUnpublished);
  const updateIncentive = async (incentive: Incentive) => {
    await updateMutation.mutateAsync(incentive, {
      onSuccess: () => resetFields(),
      onError: (error) => {
        notification.error({
          message: 'Update Failed!',
          description:
            error.response?.data.errors?.IsMultiplier ??
            error.response?.data.message ??
            error.message,
        });
      },
    });
  };

  const isLoading = updateMutation.isLoading || createMutation.isLoading;

  // Handlers
  const handleOk = async () => {
    const formValues = await form.validateFields();

    if (items.length <= 0) {
      const fields = await fieldsForm.validateFields();
      if (fields) await handleAdd();
    } else {
      const fields = await fieldsForm.getFieldsValue(true);
      if (!isNil(fields) && Object.keys(fields).length !== 0) await handleAdd();
    }

    const newIncentive = {
      ...incentive,
      ...formValues,
      incentiveStatus: IncentiveStatuses.Draft,
      incentiveFields: items.map((item) => {
        return { ...item, id: 0, order: items.indexOf(item) + 1 };
      }),
    };

    if (newIncentive && newIncentive.id > 0) {
      await updateIncentive(newIncentive);
      return;
    }
    await createIncentive(newIncentive);
  };

  const handleCancel = () => {
    resetFields();
  };

  const resetFields = () => {
    form.resetFields();
    fieldsForm.resetFields();
    setModal(false, null);
  };

  // Sensors
  const sensors = useSensors(useSensor(PointerSensor));

  function handleDragEnd(event) {
    if (incentive) {
      if (incentive.incentiveStatus !== IncentiveStatuses.Draft) return;
    }
    const { active, over } = event;
    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((x) => x.id === active.id);
        const newIndex = items.findIndex((x) => x.id === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }

  const handleAdd = async () => {
    const fieldFormValues = await fieldsForm.validateFields();

    if (!fieldFormValues) return;

    setItems([
      ...items,
      {
        id: (items.length + 1) * -1,
        incentiveId: (items.length + 1) * -1,
        name: fieldFormValues.name,
        order: items.length + 1,
        isMultiplier: fieldFormValues.isMultiplier,
        incentiveFieldType: fieldFormValues.incentiveFieldType,
      },
    ]);
    fieldsForm.resetFields();
  };

  const remove = (id: number) => {
    setItems(items.filter((x) => x.id !== id));
  };

  const isSystemGenerated = (incentive) =>
    incentive && incentive.automatedIncentiveType > 0 ? (
      <div className="automated-incentive">
        <span>Automated Incentive </span>
        <FontAwesomeIcon icon={faCheckCircle} />
      </div>
    ) : null;

  return (
    <Modal
      width={600}
      title={modalTitle(incentive)}
      visible={isOpen}
      onCancel={handleCancel}
      footer={
        (!incentive || incentive.incentiveStatus === IncentiveStatuses.Draft) && [
          <Button key="cancel" type="primary" disabled={isLoading} onClick={() => handleCancel()}>
            Cancel
          </Button>,
          <Button key="ok" type="primary" loading={isLoading} onClick={() => handleOk()}>
            OK
          </Button>,
        ]
      }
    >
      <Form requiredMark={false} layout="vertical" form={form}>
        {isSystemGenerated(incentive)}
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Please provide Incentive Name' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="fixedAmount"
          label="Amount"
          rules={[{ required: true, message: 'Please provide an Amount' }]}
        >
          <InputNumber
            autoSize={{ minRows: 1, maxRows: 1 }}
            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          />
        </Form.Item>
      </Form>

      <Form requiredMark={false} layout="vertical" form={fieldsForm}>
        <Space className="incentive-form-space" align="baseline">
          <Form.Item
            name="name"
            label="Field Name"
            rules={[{ required: true, message: 'Please provide Field Name' }]}
          >
            <Input className="incentive-field-input" />
          </Form.Item>
          <Form.Item
            name="incentiveFieldType"
            label="Field Type"
            rules={[{ required: true, message: 'Please provide Field Type' }]}
          >
            <FieldTypeSelector />
          </Form.Item>
          <Form.Item name="isMultiplier" label="Multiplier" valuePropName="checked">
            <Checkbox disabled={isMultiplierDisabled} className="is-multiplier-checkbox" />
          </Form.Item>
          <Form.Item label={' '}>
            <Button type="dashed" onClick={() => handleAdd()} block>
              {(!incentive || incentive.incentiveStatus === IncentiveStatuses.Draft) && (
                <FontAwesomeIcon icon={faPlus} />
              )}
            </Button>
          </Form.Item>
        </Space>
      </Form>
      <form className="added-incentive-fields">
        {incentive && incentive.automatedIncentiveType > 0 ? (
          ''
        ) : (
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext items={items.map((x) => x.id)} strategy={verticalListSortingStrategy}>
              {items.map((item) => (
                <SortableItem
                  key={item.id}
                  field={item}
                  remove={remove}
                  incentiveStatus={incentive?.incentiveStatus ?? null}
                />
              ))}
            </SortableContext>
          </DndContext>
        )}
      </form>
    </Modal>
  );
}

export default IncentiveForm;
