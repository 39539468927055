/* @flow */

export const RetentionGroupTypes: {
  SavedCurrent: 1,
  TrueUp: 2,
  Claimed: 3,
  Cancelled: 4,
  Unsettled: 5,
} = Object.freeze({
  SavedCurrent: 1,
  TrueUp: 2,
  Claimed: 3,
  Cancelled: 4,
  Unsettled: 5,
});

export type RetentionGroupTypeEnum = $Values<typeof RetentionGroupTypes>;
