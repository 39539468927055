/* @flow */
import * as React from 'react';
import { Button } from 'antd';
import { createAndDownloadCsv } from '../../utils/downloader';
import {
  formatDateForFileName,
  formatFullName,
  formatNumberAsMoney,
  formatNumberAsPercentage,
} from '../../utils/formatter';
import type { Agent } from '../../models/Agent';
import type { ManagerCommission } from '../../models/managers/ManagerCommission';
import type { PayPeriod } from '../../models/PayPeriod';

type Props = $ReadOnly<{
  agent: ?Agent,
  deals: ?Array<ManagerCommission>,
  buttonText?: string,
  payPeriod: PayPeriod,
}>;

ManagerCommissionDownloadCsvButton.defaultProps = {
  buttonText: 'Download',
};

function ManagerCommissionDownloadCsvButton(props: Props): React.Node {
  const { buttonText } = props;
  return (
    <Button onClick={() => onDownloadClick(props)} type="link">
      {buttonText}
    </Button>
  );
}

function getFields(): any {
  return [
    {
      label: 'Team',
      value: 'teamName',
    },
    {
      label: 'Rep',
      value: (row: any): string => formatFullName(row.repFirstName, row.repLastName),
    },
    {
      label: 'Earned Total',
      value: (row: any): string => formatNumberAsMoney(row.earnedTotal, true),
    },
    {
      label: 'Total Commission',
      value: (row: any): string => formatNumberAsMoney(row.commissionTotal, true),
    },
    {
      label: 'Override %',
      value: (row: any): string => formatNumberAsPercentage(row.overridePercentage, true),
    },
    {
      label: 'Override Contr.',
      value: (row: any): string => formatNumberAsMoney(row.overrideAmount, true),
    },
    {
      label: 'DM Per Unit Avg.',
      value: (row: any): string =>
        formatNumberAsMoney(row.directMailPerUnitCommissionAverage, true),
    },
    {
      label: 'Digital Per Unit Avg.',
      value: (row: any): string => formatNumberAsMoney(row.digitalPerUnitCommissionAverage, true),
    },
    {
      label: 'Bonus Contr.',
      value: (row: any): string => formatNumberAsMoney(row.bonusAmount, true),
    },
  ];
}

function getFileName(agent: ?Agent, payPeriod: PayPeriod): string {
  const startDate = formatDateForFileName(payPeriod.startDate);
  const endDate = formatDateForFileName(payPeriod.endDate);
  return `Agent ${
    agent?.fullName ?? ''
  } Manager Commissions for Period ${startDate} to ${endDate}.csv`;
}

function onDownloadClick(props: Props) {
  let { deals, agent, payPeriod } = props;
  const fileName = getFileName(agent, payPeriod);
  const fieldData = getFields();
  createAndDownloadCsv(deals, fieldData, fileName ?? 'Download.csv');
}

export default ManagerCommissionDownloadCsvButton;
