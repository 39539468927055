/* @flow */
import '../../css/dashboard/AdjustmentsTable.css';
import * as React from 'react';
import { Empty, Table } from 'antd';
import { formatAdjustmentType } from '../../utils/enumFormatter';
import { formatNumberAsMoney } from '../../utils/formatter';
import { isNil } from 'lodash';
import type { Adjustment } from '../../models/Adjustment';

type Props = $ReadOnly<{
  adjustments: ?Array<Adjustment>,
  adjustmentTotal: ?number,
  minScrollHeight: number,
}>;

AdjustmentsTable.defaultProps = {
  adjustmentTotal: 0,
  minScrollHeight: 240,
};

function AdjustmentsTable(props: Props): React.Node {
  const { adjustments, minScrollHeight } = props;
  const scroll = { y: minScrollHeight };

  if (isNil(adjustments)) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return (
    <Table
      bordered
      columns={getColumns()}
      dataSource={adjustments}
      size="small"
      pagination={false}
      scroll={scroll}
      footer={(adjustments) => getFooter(adjustments, props)}
      rowKey="id"
    />
  );
}

function getColumns(): any {
  return [
    {
      title: 'Type',
      dataIndex: 'adjustmentType',
      editable: false,
      render: (value) => formatAdjustmentType(value),
      sorter: (a, b) =>
        formatAdjustmentType(a.adjustmentType).localeCompare(
          formatAdjustmentType(b.adjustmentType),
        ),
      width: 215,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      editable: false,
      render: (value) => formatNumberAsMoney(value, true),
      sorter: (a, b) => a.amount - b.amount,
      align: 'right',
      width: 110,
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      editable: false,
    },
  ];
}

function getFooter(adjustments: Array<Adjustment>, props: Props): React.Node {
  const { adjustmentTotal } = props;
  const formattedTotal = formatNumberAsMoney(adjustmentTotal, true);

  return (
    <div className="dashboard-adjustments-footer">
      <span>Total:</span>
      {formattedTotal}
    </div>
  );
}

export default AdjustmentsTable;
