/* @flow */
import { convertHeaderStringToObject } from '../utils/downloader';
import { get } from 'lodash';
import WebAPIClient from './api/WebAPIClient';
import type { AgentCommission } from '../models/AgentCommission';
import type { CommissionReview } from '../models/reviews/CommissionReview';
import type { File } from '../models/File';
import type { SearchResult } from '../models/SearchResult';

export const getCommissionSearchResults = async (
  searchTerm: string,
): Promise<Array<SearchResult>> => {
  const response = await WebAPIClient.get('commissions/search', { searchTerm });
  return get(response, 'data', []);
};

export const getCommissionReviews = async (
  payPeriodId: number,
): Promise<Array<CommissionReview>> => {
  const response = await WebAPIClient.get('commissions/reviews', {
    payPeriodId: payPeriodId,
  });
  return get(response, 'data', []);
};

export const downloadCommissionReviews = async (payPeriodId: number): Promise<File> => {
  const response = await WebAPIClient.get('commissions/reviews/download', {
    payPeriodId: payPeriodId,
  });

  const header = get(response, 'headers.content-disposition', []);
  const headerObject = convertHeaderStringToObject(header);
  const fileName = headerObject['filename'];

  return {
    fileContents: get(response, 'data', []),
    contentType: get(response, 'headers.content-type', []),
    fileName: fileName,
  };
};

export const approveCommissions = async (
  agentCommissionIds: Array<number>,
  payPeriodId: number,
): Promise<AgentCommission> => {
  const response = await WebAPIClient.put(
    `commissions/approve?payPeriodId=${payPeriodId}`,
    agentCommissionIds,
  );
  return get(response, 'data', []);
};

export const unapproveCommissions = async (
  agentCommissionId: number,
  payPeriodId: number,
): Promise<AgentCommission> => {
  const response = await WebAPIClient.put(
    `commissions/unapprove?agentCommissionId=${agentCommissionId}&payPeriodId=${payPeriodId}`,
    {},
  );
  return get(response, 'data', []);
};
