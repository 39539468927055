/* @flow */
import '../../css/incentives/AgentIncentivesTable.css';
import * as React from 'react';
import { Button, Checkbox, Popconfirm, Table } from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LockTypes } from '../../enums/LockTypeEnum';
import { RoleEnum } from '../../enums/RoleEnum';
import {
  assign,
  filter,
  find,
  isNil,
  join,
  map,
  orderBy,
  reverse,
  sortBy,
  trim,
  uniq,
  words,
} from 'lodash';
import { faRobot } from '@fortawesome/free-solid-svg-icons';
import { formatDateAsShortDate, formatNumberAsMoney } from '../../utils/formatter';
import { useState } from 'react';
import AgentIncentiveValues from './AgentIncentiveValues';
import DashboardLink from '../shared/DashboardLink';
import OriginalAmountLabel from '../shared/OriginalAmountLabel';
import useDeleteAgentIncentive from '../../hooks/services/incentive/useDeleteAgentIncentive';
import useMyRole from '../../hooks/services/role/useMyRole';
import type { AgentIncentive } from '../../models/incentives/AgentIncentive';
import type { Incentive } from '../../models/incentives/Incentive';
import type { PayPeriod } from '../../models/PayPeriod';
import type { RoleAccess } from '../../models/RoleAccess';

type Props = $ReadOnly<{
  incentives: ?Array<Incentive>,
  agentIncentives: ?Array<AgentIncentive>,
  payPeriod: PayPeriod,
  setModal: (isOpen: boolean, agentIncentive: ?AgentIncentive) => void,
  selectedRows: ?Array<AgentIncentive>,
  setSelectedRowsCallback: ({ teamId: string, records: Array<any> }) => void,
  teamId: string,
  roles: ?RoleAccess,
}>;

function IncentiveTable(props: Props): React.Node {
  const {
    incentives,
    agentIncentives,
    payPeriod,
    setModal,
    selectedRows,
    setSelectedRowsCallback,
    teamId,
    roles,
  } = props;

  // Hooks
  const myRole = useMyRole();

  // States
  const [mutationErrorMessage, setMutationErrorMessage] = useState(null);

  // Mutations
  const deleteMutation = useDeleteAgentIncentive();

  const isLocked =
    payPeriod && payPeriod.payPeriodLocks
      ? payPeriod.payPeriodLocks.some((payPeriod) => payPeriod.lockType === LockTypes.Incentives)
      : true;

  const hasWriteAccess = () =>
    myRole?.role.name === RoleEnum.Admin ||
    myRole?.role.name === RoleEnum.Manager ||
    myRole?.role.name === RoleEnum.RetentionManager ||
    myRole?.role.name === RoleEnum.IncentiveCreator;

  const userHasApprovalAccess = () =>
    myRole?.role.name === RoleEnum.Admin || myRole?.role.name === RoleEnum.Manager;

  const getColumns = (): any => {
    return [
      {
        title: 'Rep',
        dataIndex: 'agentFullName',
        editable: true,
        sorter: (a, b) => a.agentFullName.localeCompare(b.agentFullName),
        render: (value, row: AgentIncentive) => (
          <DashboardLink agentId={row.masterAgentId ?? 0} payPeriodId={payPeriod.id}>
            {value}
          </DashboardLink>
        ),
        filterSearch: true,
        filters: map(
          orderBy(uniq(map(agentIncentives, (agentIncentive) => agentIncentive.agentFullName))),
          (item) => {
            return { text: item, value: item };
          },
        ),
        onFilter: (value, record) => record.agentFullName?.includes(value),
        width: 200,
        defaultSortOrder: 'ascend',
      },
      {
        title: 'HS Id',
        dataIndex: 'howardSimonId',
        editable: false,
        render: (_, row: AgentIncentive) => {
          return row.masterAgent.howardSimonId;
        },
        width: 90,
      },
      {
        title: 'Incentive',
        dataIndex: 'incentiveName',
        editable: true,
        sorter: (a, b) => a.incentiveName.localeCompare(b.incentiveName),
        render: (_, row: AgentIncentive) => {
          return row.isSystemGenerated === true ? (
            <div>
              <FontAwesomeIcon icon={faRobot} style={{ marginRight: 5 }} /> {row.incentiveName}
            </div>
          ) : (
            <div>{row.incentiveName}</div>
          );
        },
        width: 300,
      },
      {
        title: 'Amount',
        dataIndex: 'value',
        editable: true,
        sorter: (a, b) => a.amount - b.amount,
        render: (_, row: AgentIncentive) => (
          <>
            <span>{formatNumberAsMoney(row.amount, true)}</span>
            <OriginalAmountLabel
              incentives={incentives}
              selectedIncentiveId={row.incentiveId}
              agentIncentive={row}
              amount={row.amount}
              isInModal={false}
            />
          </>
        ),
        width: 170,
      },
      {
        title: 'Detail',
        dataIndex: 'detail',
        editable: true,
        render: (_, row: AgentIncentive) => {
          return (
            <AgentIncentiveValues
              key={row.incentiveId}
              selectedIncentiveId={row.incentiveId}
              isReadOnly={true}
              incentives={incentives}
              agentIncentive={row}
              form={null}
            ></AgentIncentiveValues>
          );
        },
        width: 550,
      },
      {
        title: 'Created By',
        dataIndex: 'createdBy',
        editable: false,
        sorter: (a, b) => a.createdBy.localeCompare(b.createdBy),
        width: 300,
      },
      {
        title: 'Created Date',
        dataIndex: 'createdDate',
        editable: false,
        render: (value) => formatDateAsShortDate(value, true, true),
        sorter: (a, b) => new Date(a.createdDate) - new Date(b.createdDate),
        width: 130,
      },
      {
        title: 'Updated By',
        dataIndex: 'updatedBy',
        editable: false,
        sorter: (a, b) => a.updatedBy.localeCompare(b.updatedBy),
        width: 300,
      },
      {
        title: 'Updated Date',
        dataIndex: 'updatedDate',
        editable: false,
        render: (value) => formatDateAsShortDate(value, true, true),
        sorter: (a, b) => new Date(a.updatedDate) - new Date(b.updatedDate),
        width: 130,
      },
      !isLocked
        ? {
            title: 'Actions',
            dataIndex: '',
            key: 'actions',
            render: (_, row: AgentIncentive) => getEditableActionCell(row),
            fixed: 'right',
            width: 175,
          }
        : {},
    ];
  };

  const getRows = (rows: ?Array<AgentIncentive>): Array<AgentIncentive> => {
    if (!rows) {
      return [];
    }
    const data = sortBy(rows, (row) => getFullNameReversed(row.agentFullName));
    return map(data, (record) => {
      return assign({ key: record.id.toString() }, record);
    });
  };

  const getEditableActionCell = (row: AgentIncentive): React.Node => {
    return (
      <div className="incentives-action-cell">
        <div className="incentives-action-cell-buttons">
          {hasWriteAccess() && !isLocked && !row.isApproved && (
            <>
              <Button type="primary" onClick={() => onEditClick(row)}>
                {row.isSystemGenerated ? 'View' : 'Edit'}
              </Button>
              <Popconfirm
                title="Are you sure you want to delete this record?"
                onConfirm={() => onDeleteClick(row)}
              >
                <Button type="primary">Delete</Button>
              </Popconfirm>
            </>
          )}
        </div>
        {getErrorMessage()}
      </div>
    );
  };

  const getErrorMessage = (): React.Node =>
    !isNil(mutationErrorMessage) && (
      <div className="incentives-action-cell-error-message">{mutationErrorMessage}</div>
    );

  // #endregion

  // #region "OnClick Events"

  const onEditClick = (row: AgentIncentive) => {
    setModal(true, row);
  };

  const onDeleteClick = async (row: AgentIncentive) => {
    await deleteMutation.mutateAsync(row, {
      onSuccess: () => {
        setMutationErrorMessage(null);
      },
      onError: (error) => {
        setMutationErrorMessage(
          'This record failed to be deleted. ' + (error.response?.data.message ?? error.message),
        );
      },
    });
  };

  const isInSelectedRows = (key: string): AgentIncentive => {
    return find(selectedRows, (row) => row.key === key);
  };

  const getRowSelection = () => {
    return {
      columnWidth: 50,
      selectedRowKeys: map(selectedRows, (row) => row.key),
      preserveSelectedRowKeys: false,
      hideSelectAll:
        !userHasApprovalAccess() ||
        payPeriod.payPeriodLocks?.some((x) => x.lockType === LockTypes.Incentives),
      onChange: (_, selectedRows) => {
        setSelectedRowsCallback({
          teamId,
          records: filter(selectedRows, (row) => !isInSelectedRows(row.key)),
        });
      },
      getCheckboxProps: (row) => ({
        disabled: row.isApproved || (roles?.role.name !== RoleEnum.Admin && !row.isSystemGenerated),
      }),
      renderCell: (checked, record) => {
        if (record.isApproved) {
          return <CheckCircleTwoTone twoToneColor="#52c41a" />;
        }

        if (
          roles?.role.name === RoleEnum.Admin ||
          (roles?.role.name === RoleEnum.Manager && record.isSystemGenerated)
        ) {
          return (
            <Checkbox
              checked={isInSelectedRows(record.key)}
              disabled={payPeriod.payPeriodLocks?.some((x) => x.lockType === LockTypes.Incentives)}
              onChange={() => onRowCheckboxClick(record)}
            />
          );
        }
      },
    };
  };

  const onRowCheckboxClick = (record) => {
    setSelectedRowsCallback({ teamId, records: [record] });
  };

  // #endregion

  return (
    <Table
      bordered
      className="incentives-table"
      columns={getColumns()}
      dataSource={getRows(agentIncentives)}
      pagination={false}
      scroll={{ y: 600 }}
      rowKey={(record) => record.id.toString()}
      rowSelection={getRowSelection()}
      size="small"
    />
  );
}

function getFullNameReversed(fullName: string) {
  if (isNil(fullName)) return '';
  const fullNameArray = words(fullName);
  const fullNameArrayReversed = reverse(fullNameArray);
  const fullNameReversed = join(fullNameArrayReversed, ' ');
  return trim(fullNameReversed);
}

export default IncentiveTable;
