/* @flow */
import '../../css/pages/Incentives.css';
import * as React from 'react';
import { Button, Checkbox } from 'antd';
import { useState } from 'react';
import IncentiveForm from '../incentives-management/IncentiveForm';
import IncentivesTable from '../incentives-management/IncentivesTable';
import Section from '../shared/Section';
import useIncentive from '../../hooks/services/incentive/useIncentive';
import type { Incentive } from '../../models/incentives/Incentive';

function Incentives(): React.Node {
  // States
  const [currentIncentive, setCurrentIncentive] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [excludeUnpublished, setExcludeUnpublished] = useState(false);

  // Queries
  const { isLoadingIncentives, incentives } = useIncentive(excludeUnpublished);

  // Handlers
  const setModal = (isOpen: boolean, agentIncentive: ?Incentive) => {
    setCurrentIncentive(agentIncentive);
    setIsModalOpen(isOpen);
  };

  return (
    <>
      {isModalOpen && (
        <IncentiveForm
          incentive={currentIncentive}
          setModal={setModal}
          isOpen={isModalOpen}
          excludeUnpublished={excludeUnpublished}
        ></IncentiveForm>
      )}
      <p className="incentives-management-title">Incentives Management</p>
      <Section
        headerLabel={''}
        contents={[
          <>
            <Button className="add-new-agent-incentive-button" type="primary" onClick={setModal}>
              New Incentive
            </Button>
            <Checkbox
              className="exclude-unpublished"
              checked={excludeUnpublished}
              onChange={(event) => setExcludeUnpublished(event.target.checked)}
            >
              Exclude Unpublished
            </Checkbox>
            <IncentivesTable
              key={'incentivesTableSection'}
              incentives={incentives}
              setModal={setModal}
              excludeUnpublished={excludeUnpublished}
            />
          </>,
        ]}
        isLoading={isLoadingIncentives}
      />
    </>
  );
}

export default Incentives;
