/*
 * Render visible component if the user has the right permissions level.
 * If not, render fallback component or null.
 *
 * @flow
 */
import * as React from 'react';
import { RoleEnum } from '../../enums/RoleEnum';
import useMyRole from '../../hooks/services/role/useMyRole';
import type { RoleEnumType } from '../../enums/RoleEnum';

type Props = $ReadOnly<{
  level: RoleEnumType,
  visible: React.Node,
  fallback?: React.Node,
}>;

function RequiresPermission(props: Props): React.Node {
  const { level, visible, fallback } = props;

  // No permissions needed if level is 'None' or 'Unknown'
  if (level === RoleEnum.None || level === RoleEnum.Unknown) {
    return visible;
  }

  const fallbackContent = fallback ?? null;
  const role = useMyRole();

  switch (role?.role.name) {
    case RoleEnum.Admin:
      return visible;
    case RoleEnum.RetentionManager:
      return level !== RoleEnum.Admin ? visible : fallbackContent;
    case RoleEnum.Manager:
      return level !== RoleEnum.Admin && RoleEnum.RetentionManager && RoleEnum.IncentiveCreator
        ? visible
        : fallbackContent;
    case RoleEnum.IncentiveCreator:
      return level !== RoleEnum.Admin &&
        level !== RoleEnum.Manager &&
        level !== RoleEnum.RetentionManager
        ? visible
        : fallbackContent;
    case RoleEnum.Rep:
      return level !== RoleEnum.Admin &&
        level !== RoleEnum.Manager &&
        level !== RoleEnum.RetentionManager &&
        level !== RoleEnum.IncentiveCreator
        ? visible
        : fallbackContent;
    default:
      return level === role ? visible : fallbackContent;
  }
}

export default RequiresPermission;
