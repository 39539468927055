/* @flow */
import * as React from 'react';
import { Button } from 'antd';
import { LockTypes } from '../../enums/LockTypeEnum';
import { find } from 'lodash';
import { useState } from 'react';
import ContestForm from '../contest-management/ContestForm';
import ContestTable from '../contest-management/ContestTable';
import PayPeriodSelector from '../shared/PayPeriodSelector';
import Section from '../shared/Section';
import SubHeader from '../shared/SubHeader';
import useContest from '../../hooks/services/contest-management/useContest';
import useIncentive from '../../hooks/services/incentive/useIncentive';
import usePayPeriodQueryState from '../../hooks/usePayPeriodQueryState';
import usePayPeriods from '../../hooks/services/pay-period/usePayPeriods';
import type { Contest } from '../../models/incentives/Contest';

function ContestManagement(): React.Node {
  // States
  const { payPeriodId, onPayPeriodChange } = usePayPeriodQueryState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentContest, setCurrentContest] = useState(null);

  //Hooks
  const payPeriods = usePayPeriods();

  //Queries
  const { isLoadingContests, contests } = useContest(payPeriodId ?? 0);
  const { isLoadingIncentives, incentives } = useIncentive();

  // Handlers
  const setModal = (isOpen: boolean, contest: ?Contest) => {
    setCurrentContest(contest);
    setIsModalOpen(isOpen);
  };

  const selectedPayPeriod = find(payPeriods, (payPeriod) => payPeriod.id === payPeriodId);
  const isLocked =
    selectedPayPeriod && selectedPayPeriod.payPeriodLocks
      ? selectedPayPeriod.payPeriodLocks.some(
          (payPeriod) => payPeriod.lockType === LockTypes.Incentives,
        )
      : true;

  return (
    <>
      {isModalOpen && (
        <ContestForm
          contest={currentContest}
          payPeriod={selectedPayPeriod}
          incentives={incentives}
          setModal={setModal}
          isOpen={isModalOpen}
        ></ContestForm>
      )}
      <SubHeader
        headerLabel={'Contest Management'}
        primarySelector={null}
        secondarySelectorSeparator={'for'}
        secondarySelectorLabel={'Period'}
        secondarySelector={
          <PayPeriodSelector
            payPeriodId={payPeriodId}
            onChange={onPayPeriodChange}
            showStatus={true}
          />
        }
      />
      {selectedPayPeriod && (
        <Section
          headerLabel={''}
          contents={[
            <Button
              key="newContest"
              className="add-new-agent-incentive-button"
              type="primary"
              onClick={setModal}
              disabled={isLocked}
            >
              New Contest
            </Button>,

            <ContestTable
              key={'contestTableSection'}
              contests={contests ?? []}
              setModal={setModal}
              payPeriod={selectedPayPeriod}
              isLocked={isLocked}
            />,
          ]}
          isLoading={isLoadingContests || isLoadingIncentives}
        />
      )}
    </>
  );
}

export default ContestManagement;
