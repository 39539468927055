/* @flow */
import { downloadCommissionReviews } from '../../../services/CommissionService';
import { useMutation } from 'react-query';
import type { File } from '../../../models/File';

type DownloadCommissionReviewsOptions = $ReadOnly<{
  onSuccess?: (isSuccessful: boolean, data: ?File) => void,
  onError?: (error: any) => void,
}>;

type DownloadCommissionReviews = $ReadOnly<{
  mutateAsync: (payPeriodId: number, options?: DownloadCommissionReviewsOptions) => Promise<void>,
  isLoading: boolean,
}>;

function useDownloadCommissionReviews(): DownloadCommissionReviews {
  const { mutateAsync, isLoading } = useMutation(async (payPeriodId: number) =>
    downloadCommissionReviews(payPeriodId),
  );

  return {
    mutateAsync: async (
      payPeriodId: number,
      options?: DownloadCommissionReviewsOptions,
    ): Promise<void> => {
      try {
        const data = await mutateAsync(payPeriodId);
        options?.onSuccess?.(true, data);
      } catch (e) {
        options?.onError?.(e);
      }
    },
    isLoading,
  };
}

export default useDownloadCommissionReviews;
