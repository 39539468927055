/* @flow */
import { get, orderBy } from 'lodash';
import WebAPIClient from './api/WebAPIClient';
import config from '../config';
import type { LockTypeEnum } from '../enums/LockTypeEnum';
import type { PayPeriod } from '../models/PayPeriod';

export const getPayPeriods = async (): Promise<Array<PayPeriod>> => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 1); // Show the next pay period when appropriate

  const response = await WebAPIClient.get('payperiods', {
    startDate: config.commissionsStartDate,
    endDate:
      currentDate.getMonth() + 1 + '-' + currentDate.getDate() + '-' + currentDate.getFullYear(),
  });

  return orderBy(get(response, 'data', []), (pp) => pp.startDate, 'desc');
};

export const lockPayPeriod = async (
  payPeriodId: number,
  lockType: LockTypeEnum,
): Promise<PayPeriod> => {
  const response = await WebAPIClient.put(`payperiods/${payPeriodId}/lock/${lockType}`);
  return get(response, 'data', null);
};

export const unlockPayPeriod = async (
  payPeriodId: number,
  lockType: LockTypeEnum,
): Promise<PayPeriod> => {
  const response = await WebAPIClient.put(`payperiods/${payPeriodId}/unlock/${lockType}`);
  return get(response, 'data', null);
};
