/* @flow */
import type { RetentionCommissionStatusEnum } from '../enums/RetentionCommissionStatusEnum';

import { type AdjustmentTypeEnum, AdjustmentTypes } from '../enums/AdjustmentTypeEnum';
import { type AgentSaleRoleEnum, AgentSaleRoles } from '../enums/AgentSaleRoleEnum';
import { BonusType, type BonusTypeEnum } from '../enums/BonusTypeEnum';
import { type CallDirectionEnum, CallDirections } from '../enums/CallDirectionEnum';
import { type DealCommissionTypeEnum, DealCommissionTypes } from '../enums/DealCommissionTypeEnum';
import { type DealGroupTypeEnum, DealGroupTypes } from '../enums/DealGroupTypeEnum';
import { type DealTypeEnum, DealTypes } from '../enums/DealTypeEnum';
import { IncentiveFieldTypes } from '../enums/IncentiveFieldTypeEnum';
import { IncentiveStatuses } from '../enums/IncentiveStatusEnum';
import { RetentionCommissionStatuses } from '../enums/RetentionCommissionStatusEnum';
import {
  SalesCommissionStatus,
  type SalesCommissionStatusEnum,
} from '../enums/SalesCommissionStatusEnum';
import {
  SalesDevelopmentCommissionStatus,
  type SalesDevelopmentCommissionStatusEnum,
} from '../enums/SalesDevelopmentCommissionStatusEnum';
import type { IncentiveFieldTypeEnum } from '../enums/IncentiveFieldTypeEnum';
import type { IncentiveStatusEnum } from '../enums/IncentiveStatusEnum';

export function formatCommissionType(dealCommissionType: DealCommissionTypeEnum): string {
  switch (dealCommissionType) {
    case DealCommissionTypes.Sales:
      return 'Sales';
    case DealCommissionTypes.SalesDevelopment:
      return 'Sales Development';
    case DealCommissionTypes.Retention:
      return 'Retention';
    default:
      return 'Unknown';
  }
}

export function formatAdjustmentType(adjustmentType: AdjustmentTypeEnum): string {
  switch (adjustmentType) {
    case AdjustmentTypes.KpiBonus:
      return 'Kpi Bonus';
    case AdjustmentTypes.ReviewBonus:
      return 'Review Bonus';
    case AdjustmentTypes.UpsellBonus:
      return 'Upsell Bonus';
    case AdjustmentTypes.OtherBonus:
      return 'Other Bonus';
    case AdjustmentTypes.EgregiousErrorPenalty:
      return 'Egregious Error Penalty';
    case AdjustmentTypes.OtherPenalty:
      return 'Other Penalty';
    case AdjustmentTypes.Correction:
      return 'Correction';
    case AdjustmentTypes.Other:
      return 'Other';
    default:
      return 'Unknown';
  }
}

export function formatBonusType(bonusType: BonusTypeEnum): string {
  switch (bonusType) {
    case BonusType.ClosingRatio:
      return 'Closing Rate %';
    case BonusType.SaveRatio:
      return 'Save Rate %';
    case BonusType.ManagerSalesPerUnitTarget:
      return 'Per Unit Target';
    case BonusType.ManagerRepCommissionTarget:
      return 'SDR Commission Target';
    case BonusType.SDRTeamPerformance:
      return 'SDR Team Performance';
    case BonusType.ManagerSalesClosingRatio:
      return 'Closing Rate';
    case BonusType.ManagerSalesDialsPerSale:
      return 'Dials Per Sale';
    case BonusType.DialsPerSale:
      return 'Dials Per Sale';
    default:
      return 'Unknown';
  }
}

export function formatAgentSaleRoleType(agentSaleRoleType: AgentSaleRoleEnum): string {
  switch (agentSaleRoleType) {
    case AgentSaleRoles.Closer:
      return 'Closer';
    case AgentSaleRoles.Takeover:
      return 'Takeover';
    case AgentSaleRoles.Renewal:
      return 'Renewal';
    default:
      return 'Unknown';
  }
}

export function formatDealType(dealType: DealTypeEnum): string {
  switch (dealType) {
    case DealTypes.Digital:
      return 'Digital';
    case DealTypes.DirectMail:
      return 'Direct Mail';
    case DealTypes.Television:
      return 'Television';
    case DealTypes.MBI:
      return 'MBI';
    case DealTypes.DigitalAndTelevision:
      return 'Digital And Television';
    default:
      return 'Unknown';
  }
}

export function formatDealGroupType(dealGroup: DealGroupTypeEnum): string {
  switch (dealGroup) {
    case DealGroupTypes.Cancelled:
      return 'Canceled';
    case DealGroupTypes.TrueUp:
      return 'True Up';
    case DealGroupTypes.SoldCurrent:
      return 'Sold Current';
    case DealGroupTypes.SoldPrior:
      return 'Sold Prior';
    case DealGroupTypes.Unsettled:
      return 'Unsettled';
    default:
      return 'Unknown';
  }
}

export function formatSalesCommissionStatus(
  salesCommissionStatus: SalesCommissionStatusEnum,
  isFullPay: boolean,
): string {
  switch (salesCommissionStatus) {
    case SalesCommissionStatus.AwaitingDownPayment:
      return isFullPay ? 'Not Full Payment' : 'Not Full DP';
    case SalesCommissionStatus.AwaitingFunding:
      return isFullPay ? 'Full Pay Lockout' : 'Not Funded';
    case SalesCommissionStatus.Cancelled:
      return 'Canceled';
    case SalesCommissionStatus.DownPaymentTooLow:
      return 'DP Too Low';
    case SalesCommissionStatus.InitialPayout:
      return 'Initial Payout';
    case SalesCommissionStatus.PaidOut:
      return 'Paid Out';
    case SalesCommissionStatus.Reassigned:
      return 'Reassigned';
    case SalesCommissionStatus.AwaitingTrialPeriodEnd:
      return 'Trial Lockout';
    case SalesCommissionStatus.CancellationPeriod:
      return 'Funded';
    default:
      return 'Unknown';
  }
}

export function formatSalesDevelopmentCommissionStatus(
  salesDevelopmentCommissionStatus: SalesDevelopmentCommissionStatusEnum,
): string {
  switch (salesDevelopmentCommissionStatus) {
    case SalesDevelopmentCommissionStatus.Sold:
      return 'Sold';
    case SalesDevelopmentCommissionStatus.Cancelled:
      return 'Canceled';
    default:
      return 'Unknown';
  }
}

export function formatRetentionCommissionStatus(
  retentionStatus: RetentionCommissionStatusEnum,
): string {
  switch (retentionStatus) {
    case RetentionCommissionStatuses.AwaitingPayment:
      return 'Awaiting Payment';
    case RetentionCommissionStatuses.Stolen:
      return 'Claimed';
    case RetentionCommissionStatuses.PaidOut:
      return 'Paid Out';
    case RetentionCommissionStatuses.Cancelled:
      return 'Canceled';
    case RetentionCommissionStatuses.AwaitingFunding:
      return 'Awaiting Funding';
    default:
      return 'Unknown';
  }
}

export function formatFieldTypes(fieldType: IncentiveFieldTypeEnum): string {
  switch (fieldType) {
    case IncentiveFieldTypes.CALENDAR_DATE:
      return 'Calendar - Date';
    case IncentiveFieldTypes.CALENDAR_MONTH:
      return 'Calendar - Month';
    case IncentiveFieldTypes.CALENDAR_RANGE:
      return 'Calendar - Range';
    case IncentiveFieldTypes.CALENDAR_WEEK:
      return 'Calendar - Week';
    case IncentiveFieldTypes.CONTRACT_NUMBERS:
      return 'Contract Number(s)';
    case IncentiveFieldTypes.DESCRIPTION:
      return 'Description';
    case IncentiveFieldTypes.PHONE_NUMBER:
      return 'Phone Number';
    case IncentiveFieldTypes.STRING:
      return 'Input';
    default:
      return 'Unknown';
  }
}

export function formatIncentiveStatus(incentiveStatus: IncentiveStatusEnum): string {
  switch (incentiveStatus) {
    case IncentiveStatuses.Draft:
      return 'Draft';
    case IncentiveStatuses.Published:
      return 'Published';
    case IncentiveStatuses.Unpublished:
      return 'Unpublished';
    default:
      return 'Unknown';
  }
}
export function formatCallDirection(callDirection: CallDirectionEnum): string {
  switch (callDirection) {
    case CallDirections.Inbound:
      return 'Inbound';
    case CallDirections.Outbound:
      return 'Outbound';
    case CallDirections.Both:
      return 'Inbound and Outbound';
    default:
      return 'Unknown';
  }
}
