/* @flow */
import '../../css/incentives/ContestTable.css';
import * as React from 'react';
import { Button, Form, Popconfirm, Table } from 'antd';
import { CallDirections } from '../../enums/CallDirectionEnum';
import { formatCallDirection } from '../../utils/enumFormatter';
import {
  formatDateAsShortDate,
  formatDateWithTime,
  formatNumberAsMoney,
} from '../../utils/formatter';
import { isNil, map, orderBy, uniq, uniqBy } from 'lodash';
import { useState } from 'react';
import ShowMore from '../incentives/ShowMore';
import useDeleteContest from '../../hooks/services/contest-management/useDeleteContest';
import type { Contest } from '../../models/incentives/Contest';
import type { PayPeriod } from '../../models/PayPeriod';

type Props = $ReadOnly<{
  contests: ?Array<Contest>,
  payPeriod: ?PayPeriod,
  setModal: (isOpen: boolean, contest: ?Contest) => void,
  isLocked: boolean,
}>;

function ContestTable(props: Props): React.Node {
  const [form] = Form.useForm();
  const { contests, setModal, payPeriod, isLocked } = props;

  //States
  const [mutationErrorMessage, setMutationErrorMessage] = useState(null);

  // Mutations
  const deleteMutation = useDeleteContest(payPeriod?.id ?? 0);

  const getColumns = (): any => {
    return [
      {
        title: 'Contest Name',
        dataIndex: 'name',
        editable: false,
        sorter: (a, b) => a.name.localeCompare(b.name),
        defaultSortOrder: 'ascend',
        filterSearch: true,
        filters: map(orderBy(uniq(map(contests, (contest) => contest.name))), (item) => {
          return { text: item, value: item };
        }),
        onFilter: (value, record) => record.name?.includes(value),
        width: 250,
      },
      {
        title: 'Amount',
        dataIndex: 'overrideAmount',
        editable: false,
        sorter: (a, b) => a.overrideAmount - b.overrideAmount,
        render: (_, row: Contest) => formatNumberAsMoney(row.overrideAmount, true),
        align: 'right',
        width: 100,
      },
      {
        title: 'Contest Type',
        dataIndex: 'incentive',
        editable: true,
        sorter: (a, b) => a.incentive.name.localeCompare(b.incentive.name),
        render: (_, row: Contest) => {
          return <div>{row.incentive.name}</div>;
        },
        width: 225,
      },
      {
        title: 'Call Direction',
        dataIndex: 'inboundOutboundEnum',

        sorter: (a, b) => a.inboundOutboundEnum - b.inboundOutboundEnum,
        filterSearch: true,
        filters: (uniqBy(
          [CallDirections.Inbound, CallDirections.Outbound, CallDirections.Both].flatMap(
            (callDirection) => [
              {
                text: formatCallDirection(callDirection),
                value: formatCallDirection(callDirection),
              },
            ],
          ),
          (item) => item.text,
        ): {
          text: string,
          value: string,
        }[]),
        onFilter: (value, record) => formatCallDirection(record.inboundOutboundEnum) === value,
        render: (_, row: Contest) => {
          return (
            <span>
              <strong>{formatCallDirection(row.inboundOutboundEnum)}</strong>
            </span>
          );
        },
        width: 175,
      },
      {
        title: 'Agents',
        dataIndex: 'agents',
        editable: false,
        render: (_, row: Contest) => {
          return <ShowMore inputText={row.agents.map((agent) => agent.fullName).join(', ')} />;
        },
        filterSearch: true,
        filters:
          contests &&
          contests
            .reduce((agents, contest) => {
              const contestAgents = contest.agents.map((agent) => ({
                text: agent.fullName,
                value: agent.id,
              }));
              return agents.concat(contestAgents);
            }, [])
            .filter((agent, index, self) => {
              return index === self.findIndex((a) => a.value === agent.value);
            }),
        onFilter: (value, record) => {
          const agentIds = record.agents.map((agent) => agent.id);
          return agentIds.includes(value);
        },
        width: 300,
      },
      {
        title: 'Teams',
        dataIndex: 'teams',
        editable: false,
        render: (_, row: Contest) => {
          return <ShowMore inputText={row.teams.map((team) => team.teamName).join(', ')} />;
        },
        filterSearch: true,
        filters:
          contests &&
          contests
            .reduce((teams, contest) => {
              const contestTeams = contest.teams.map((team) => ({
                text: team.teamName,
                value: team.id,
              }));
              return teams.concat(contestTeams);
            }, [])
            .filter((team, index, self) => {
              return index === self.findIndex((a) => a.value === team.value);
            }),
        onFilter: (value, record) => {
          const teamIds = record.teams.map((team) => team.id);
          return teamIds.includes(value);
        },
        width: 300,
      },
      {
        title: 'Start Date',
        dataIndex: 'startDate',
        editable: false,
        render: (value) => formatDateWithTime(value),
        sorter: (a, b) => new Date(a.startDate) - new Date(b.startDate),
        width: 160,
      },
      {
        title: 'End Date',
        dataIndex: 'endDate',
        editable: false,
        render: (value) => formatDateWithTime(value),
        sorter: (a, b) => new Date(a.endDate) - new Date(b.endDate),
        width: 160,
      },
      {
        title: 'Created By',
        dataIndex: 'createdBy',
        editable: false,
        sorter: (a, b) => a.createdBy.localeCompare(b.createdBy),
        width: 300,
      },
      {
        title: 'Created Date',
        dataIndex: 'createdDate',
        editable: false,
        render: (value) => formatDateAsShortDate(value, true, true),
        sorter: (a, b) => new Date(a.createdDate) - new Date(b.createdDate),
        width: 130,
      },
      {
        title: 'Updated By',
        dataIndex: 'updatedBy',
        editable: false,
        sorter: (a, b) => a.updatedBy.localeCompare(b.updatedBy),
        width: 300,
      },
      {
        title: 'Updated Date',
        dataIndex: 'updatedDate',
        editable: false,
        render: (value) => formatDateAsShortDate(value, true, true),
        sorter: (a, b) => new Date(a.updatedDate) - new Date(b.updatedDate),
        width: 130,
      },
      !isLocked
        ? {
            title: 'Actions',
            dataIndex: '',
            align: 'center',
            key: 'actions',
            render: (_, row: Contest) => getEditableActionCell(row),
            fixed: 'right',
            width: 175,
          }
        : {},
    ];
  };

  const getRows = (): Array<Contest> => {
    if (!contests) {
      return [];
    }
    return [...contests];
  };

  const onEditClick = (row: Contest) => {
    setModal(true, row);
  };

  const getEditableActionCell = (row: Contest): React.Node => {
    return (
      <div className="contests-action-cell">
        <div className="contests-action-cell-buttons">
          {
            <>
              <Button type="primary" onClick={() => onEditClick(row)}>
                Edit
              </Button>
              <Popconfirm
                title="Are you sure you want to delete this record?"
                onConfirm={() => onDeleteClick(row)}
              >
                <Button type="primary">Delete</Button>
              </Popconfirm>
            </>
          }
        </div>
        {getErrorMessage()}
      </div>
    );
  };

  const getErrorMessage = (): React.Node =>
    !isNil(mutationErrorMessage) && (
      <div className="contests-action-cell-error-message">{mutationErrorMessage}</div>
    );

  const onDeleteClick = async (row: Contest) => {
    await deleteMutation.mutateAsync(row, {
      onSuccess: () => {
        setMutationErrorMessage(null);
      },
      onError: (error) => {
        setMutationErrorMessage(
          'This record failed to be deleted. ' + (error.response?.data.message ?? error.message),
        );
      },
    });
  };

  return (
    <Form form={form} component={false}>
      <Table
        className="contests-table"
        bordered
        columns={getColumns()}
        dataSource={getRows()}
        size="small"
        pagination={false}
        scroll={{ y: 600 }}
        rowKey="id"
      />
    </Form>
  );
}

export default ContestTable;
