/* @flow */
import * as React from 'react';
import { InputNumber, Tooltip } from 'antd';
import { formatNumberAsMoney } from '../../utils/formatter';
import { isNil } from 'lodash';
import { useState } from 'react';

type Props = $ReadOnly<{
  value?: ?number,
  onChange?: (e: number) => void,
  tooltipErrorMessage: string,
  showErrorMessage: boolean,
}>;

function TooltipInputNumber(props: Props): React.Node {
  const { tooltipErrorMessage, showErrorMessage, value, onChange } = props;
  const [isFocus, setIsFocus] = useState(false);

  return (
    <Tooltip
      visible={isFocus && showErrorMessage}
      title={tooltipErrorMessage}
      color="red"
      placement="right"
    >
      <InputNumber
        formatter={(value) => (!isNil(value) ? value : formatNumberAsMoney(value, false))}
        parser={(value) => value?.replace(',', '')}
        value={value}
        onChange={onChange}
        onFocus={(e) => {
          e.target.select();
          setIsFocus(true);
        }}
        onBlur={() => setIsFocus(false)}
        status={showErrorMessage && 'error'}
      />
    </Tooltip>
  );
}

export default TooltipInputNumber;
