/* @flow */
import '../../css/incentives/AgentIncentiveValues.css';
import * as React from 'react';
import { Form, Input } from 'antd';
import { IncentiveFieldTypes } from '../../enums/IncentiveFieldTypeEnum';
import { formatContestPeriod, formatNthWeekOfMonth } from '../../utils/formatter';
import { map, orderBy } from 'lodash';
import { useEffect } from 'react';
import CalendarIncentiveValue from './dynamic-form-components/CalendarIncentiveValue';
import CalendarMonthIncentiveValue from './dynamic-form-components/CalendarMonthIncentiveValue';
import CalendarRangeIncentiveValue from './dynamic-form-components/CalendarRangeIncentiveValue';
import CalendarWeekIncentiveValue from './dynamic-form-components/CalendarWeekIncentiveValue';
import ContestPeriod from '../contest-management/ContestPeriod';
import ContractNumbersIncentiveValue from './dynamic-form-components/ContractNumbersIncentiveValue';
import ShowMoreContractNumbers from './ShowMoreContractNumbers';
import moment from 'moment';
import type { AgentIncentive } from '../../models/incentives/AgentIncentive';
import type { Incentive } from '../../models/incentives/Incentive';
import type { IncentiveField } from '../../models/incentives/IncentiveField';
import type { IncentiveValue } from '../../models/incentives/IncentiveValue';

const { TextArea } = Input;

type Props = $ReadOnly<{
  isReadOnly: boolean,
  selectedIncentiveId: number,
  incentives: ?Array<Incentive>,
  agentIncentive: ?AgentIncentive,
  form: any,
}>;

function AgentIncentiveValues(props: Props): React.Node {
  const { isReadOnly, selectedIncentiveId, incentives, agentIncentive, form } = props;

  useEffect(() => {
    if (form && agentIncentive && incentives) {
      map(agentIncentive.incentiveValues, (item: IncentiveValue) => {
        const incentive = incentives.find((x) => x.id === agentIncentive.incentiveId);

        if (!incentive) return;

        const incentiveFieldId =
          incentive.incentiveFields.find((x) => x.id === item.incentiveFieldId)?.id ?? -1;

        if (incentiveFieldId < 0) return;

        form.setFieldsValue({ [incentiveFieldId]: item.value });
      });
    }
  });

  const buildReadOnlyUiComponents = (incentiveValues: ?Array<IncentiveValue>) => {
    if (!incentiveValues || incentiveValues.length <= 0) return null;

    return map(
      orderBy(incentiveValues, (incentives) => incentives.order),
      (incentiveValue) => {
        switch (incentiveValue.incentiveFieldType) {
          case IncentiveFieldTypes.CONTRACT_NUMBERS:
            return (
              <ShowMoreContractNumbers
                incentiveId={incentiveValue.id}
                incentiveName={incentiveValue.name}
                incentiveValue={incentiveValue.value}
                incentiveFieldType={incentiveValue.incentiveFieldType}
              />
            );
          case IncentiveFieldTypes.CALENDAR_RANGE:
            return (
              <div key={`${incentiveValue.name}-${incentiveValue.id}`}>
                <span className="incentive-value-name">{incentiveValue.name}: </span>
                <span>{JSON.parse(incentiveValue.value).join(' - ')} </span>
              </div>
            );
          case IncentiveFieldTypes.CALENDAR_WEEK:
            return (
              <div key={`${incentiveValue.name}-${incentiveValue.id}`}>
                <span className="incentive-value-name">{incentiveValue.name}: </span>
                <span>{formatNthWeekOfMonth(moment(incentiveValue.value, 'YYYY ww'))} </span>
              </div>
            );
          case IncentiveFieldTypes.DESCRIPTION:
            return (
              <ShowMoreContractNumbers
                incentiveId={incentiveValue.id}
                incentiveName={incentiveValue.name}
                incentiveValue={incentiveValue.value}
                incentiveFieldType={incentiveValue.incentiveFieldType}
              />
            );
          case IncentiveFieldTypes.CONTEST_PERIOD:
            return (
              <div key={`${incentiveValue.name}-${incentiveValue.id}`}>
                <span className="incentive-value-name">{incentiveValue.name}: </span>
                <span>{formatContestPeriod(incentiveValue.value)} </span>
              </div>
            );
          default:
            return (
              <div key={`${incentiveValue.name}-${incentiveValue.id}`}>
                <span className="incentive-value-name">{incentiveValue.name}: </span>
                <span>{incentiveValue.value} </span>
              </div>
            );
        }
      },
    );
  };

  const buildUiComponents = (selectedIncentiveId: number, incentives: Array<Incentive>) => {
    if (!incentives) return null;

    const incentive = incentives.find(
      (incentive: Incentive) => incentive.id === selectedIncentiveId,
    );

    if (!incentive) return null;

    return map(
      orderBy(incentive.incentiveFields, (field: IncentiveField) => field.order),
      (field) => {
        switch (field.incentiveFieldType) {
          case IncentiveFieldTypes.STRING:
            return (
              <Form.Item
                key={field.id}
                name={field.id}
                label={field.name}
                rules={[{ required: true, message: 'Value is required!' }]}
              >
                <Input />
              </Form.Item>
            );
          case IncentiveFieldTypes.CALENDAR_DATE:
            return (
              <Form.Item
                key={field.id}
                name={field.id}
                label={field.name}
                rules={[{ required: true, message: 'Value is required!' }]}
              >
                <CalendarIncentiveValue
                  agentIncentive={agentIncentive}
                  fieldId={field.id}
                  form={form}
                  calendarOptions="date"
                />
              </Form.Item>
            );
          case IncentiveFieldTypes.CALENDAR_WEEK:
            return (
              <Form.Item
                key={field.id}
                name={field.id}
                label={field.name}
                rules={[{ required: true, message: 'Value is required!' }]}
              >
                <CalendarWeekIncentiveValue
                  agentIncentive={agentIncentive}
                  fieldId={field.id}
                  form={form}
                />
              </Form.Item>
            );
          case IncentiveFieldTypes.CALENDAR_MONTH:
            return (
              <Form.Item
                key={field.id}
                name={field.id}
                label={field.name}
                rules={[{ required: true, message: 'Value is required!' }]}
              >
                <CalendarMonthIncentiveValue
                  agentIncentive={agentIncentive}
                  fieldId={field.id}
                  form={form}
                />
              </Form.Item>
            );
          case IncentiveFieldTypes.CALENDAR_RANGE:
            return (
              <Form.Item
                key={field.id}
                name={field.id}
                label={field.name}
                rules={[{ required: true, message: 'Value is required!' }]}
              >
                <CalendarRangeIncentiveValue
                  agentIncentive={agentIncentive}
                  fieldId={field.id}
                  form={form}
                />
              </Form.Item>
            );
          case IncentiveFieldTypes.CONTRACT_NUMBERS:
            return (
              <Form.Item
                key={field.id}
                name={field.id}
                label={field.name}
                rules={[{ required: true, message: 'Value is required!' }]}
              >
                <ContractNumbersIncentiveValue
                  agentIncentive={agentIncentive}
                  form={form}
                  fieldId={field.id}
                ></ContractNumbersIncentiveValue>
              </Form.Item>
            );
          case IncentiveFieldTypes.DESCRIPTION:
            return (
              <Form.Item
                key={field.id}
                name={field.id}
                label={field.name}
                rules={[{ required: true, message: 'Value is required!' }]}
              >
                <TextArea
                  placeholder="Provide a Description"
                  autoSize={{ minRows: 3, maxRows: 5 }}
                />
              </Form.Item>
            );
          case IncentiveFieldTypes.PHONE_NUMBER:
            return (
              <Form.Item
                key={field.id}
                name={field.id}
                label={field.name}
                rules={[{ required: true, message: 'Value is required!' }]}
              >
                <input type="tel" placeholder="+1 (xxx) xxx-xx-xx" />
              </Form.Item>
            );
          case IncentiveFieldTypes.CONTEST_NAME:
            return (
              <Form.Item key={field.id} name={field.id} label={field.name}>
                <input style={{ width: '100%' }} disabled={true} />
              </Form.Item>
            );
          case IncentiveFieldTypes.CONTEST_PERIOD:
            return (
              <Form.Item
                key={field.id}
                name={field.id}
                label={field.name}
                rules={[{ required: true, message: 'Please select a contest period' }]}
              >
                <ContestPeriod
                  contest={agentIncentive?.contest ?? null}
                  form={form}
                  payPeriod={agentIncentive?.contest?.payPeriod ?? null}
                  isDisabled={true}
                ></ContestPeriod>
              </Form.Item>
            );
          case IncentiveFieldTypes.NUMBER_OF_SALES:
            return (
              <Form.Item
                key={field.id}
                name={field.id}
                label={field.name}
                rules={[{ required: true, message: 'Value is required!' }]}
              >
                <input style={{ width: 35 }} disabled={true} />
              </Form.Item>
            );
          default:
            return null;
        }
      },
    );
  };

  const buildUIComponents = (selectedIncentiveId: number, incentives: ?Array<Incentive>) => {
    if (!incentives) return null;
    return buildUiComponents(selectedIncentiveId, incentives);
  };

  return isReadOnly ? (
    buildReadOnlyUiComponents(agentIncentive?.incentiveValues)
  ) : (
    <Form requiredMark={false} layout="vertical" form={form}>
      <>{buildUIComponents(selectedIncentiveId, incentives)}</>
    </Form>
  );
}

export default AgentIncentiveValues;
