/* @flow */
import { updateIncentive } from '../../../services/IncentiveService';
import { useMutation, useQueryClient } from 'react-query';
import CommissionsServiceKeys from '../../../constants/CommissionsServiceKeys';
import type { Incentive } from '../../../models/incentives/Incentive';
import type { IncentiveMutation } from '../../../models/incentives/IncentiveMutation';

type IncentiveUpdateMutationOptions = $ReadOnly<{
  onSuccess?: (data: Incentive) => void,
  onError?: (error: any) => void,
}>;

type IncentiveUpdateMutation = $ReadOnly<{
  mutateAsync: (
    data: IncentiveMutation,
    options?: IncentiveUpdateMutationOptions,
  ) => Promise<?Incentive>,
  isLoading: boolean,
}>;

export default function useUpdateIncentive(
  excludeUnpublished: boolean = true,
): IncentiveUpdateMutation {
  const queryClient = useQueryClient();
  const cacheKey = `${CommissionsServiceKeys.INCENTIVES}-${String(excludeUnpublished)}`;

  const { mutateAsync, isLoading } = useMutation(
    async (incentive: IncentiveMutation) => updateIncentive(incentive),
    {
      onSuccess: async (response: { data: Incentive, status: number, ... }) => {
        const { data } = response;

        await queryClient.cancelQueries(cacheKey);
        const cachedIncentives = queryClient.getQueryData(cacheKey);

        const index = cachedIncentives.findIndex((item) => item.id === data.id);

        if (index >= 0) {
          cachedIncentives.splice(index, 1, data);
          queryClient.setQueryData(cacheKey, () => cachedIncentives);
        }
        return cachedIncentives[index];
      },
    },
  );

  return {
    mutateAsync: async (
      data: IncentiveMutation,
      options?: IncentiveUpdateMutationOptions,
    ): Promise<?Incentive> => {
      try {
        const result = await mutateAsync(data);
        options?.onSuccess?.(result.data);
        return result.data;
      } catch (e) {
        options?.onError?.(e);
      }
      return null;
    },
    isLoading,
  };
}
