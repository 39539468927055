/* @flow */

export const SalesDevelopmentCommissionStatus: {
  Sold: 1,
  Cancelled: 2,
} = Object.freeze({
  Sold: 1,
  Cancelled: 2,
});

export type SalesDevelopmentCommissionStatusEnum = $Values<typeof SalesDevelopmentCommissionStatus>;
