/* @flow */

export const SalesCommissionStatus: {
  AwaitingDownPayment: 1,
  InitialPayout: 2,
  AwaitingFunding: 3,
  Cancelled: 4,
  PaidOut: 5,
  DownPaymentTooLow: 6,
  Reassigned: 7,
  AwaitingTrialPeriodEnd: 8,
  CancellationPeriod: 9,
} = Object.freeze({
  AwaitingDownPayment: 1,
  InitialPayout: 2,
  AwaitingFunding: 3,
  Cancelled: 4,
  PaidOut: 5,
  DownPaymentTooLow: 6,
  Reassigned: 7,
  AwaitingTrialPeriodEnd: 8,
  CancellationPeriod: 9,
});

export type SalesCommissionStatusEnum = $Values<typeof SalesCommissionStatus>;
