/* @flow */
import '../../css/shared/SearchModal.css';
import * as React from 'react';
import { Modal, Spin } from 'antd';
import SearchResultsTable from '../search/SearchResultsTable';
import useCommissionSearchResults from '../../hooks/services/commission/useCommissionSearchResults';
import useSearchModalContext from '../../hooks/useSearchModalContext';

type Props = $ReadOnly<{
  query: string,
  visible: boolean,
  agentId: ?number,
  payPeriodId: ?number,
}>;

function SearchModal({ query, visible, agentId, payPeriodId }: Props): React.Node {
  const { updateModal } = useSearchModalContext();
  const searchResults = useCommissionSearchResults(query);
  const searchResultCount = searchResults?.length ?? 0;
  return (
    <Modal
      className="search-modal-root"
      title="Commission Details"
      centered
      visible={visible}
      footer={null}
      onCancel={() => updateModal(false, query)}
      width={'95%'}
    >
      {!searchResults ? (
        <div className="search-modal-loading">
          <Spin />
        </div>
      ) : (
        <div>
          <p>
            {`${searchResultCount} search result${searchResultCount > 1 ? 's' : ''} for `}
            <b>{`'${query}'`}</b>
          </p>
          <SearchResultsTable
            agentId={agentId}
            payPeriodId={payPeriodId}
            searchResults={searchResults}
            expandRelevantRow={true}
          />
        </div>
      )}
    </Modal>
  );
}

export default SearchModal;
