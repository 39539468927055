/* @flow */
import '../../css/shared/Nav.css';
import * as React from 'react';
import { ImpersonationContext } from '../../context/ImpersonationContext';
import { Link } from 'react-router-dom';
import { Menu, Spin } from 'antd';
import { RoleEnum } from '../../enums/RoleEnum';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import AgentSelector from './AgentSelector.jsx';
import PATHS from '../../constants/paths';
import SearchPopover from './SearchPopover';
import useMyRole from '../../hooks/services/role/useMyRole';
import type { RoleEnumType } from '../../enums/RoleEnum';

type Props = $ReadOnly<{
  enableImpersonation: boolean,
}>;

function Nav(props: Props): React.Node {
  const { enableImpersonation } = props;

  const { pathname } = useLocation();
  const role = useMyRole();
  const impersonation = useContext(ImpersonationContext);

  return (
    <nav className="nav-root">
      <Menu className="nav-menu" mode="horizontal" selectedKeys={[pathname]}>
        <Menu.Item
          key="logo"
          icon={<img src="/images/end-icon.png" height="18px" width="23px" />}
          className="nav-menu-left nav-menu-not-clickable"
        >
          Commissions Portal
        </Menu.Item>
        {enableImpersonation && (
          <Menu.Item key="impersonation" className="nav-menu-left nav-menu-no-hover">
            <span>Viewing as </span>
            <AgentSelector
              value={impersonation.agent}
              onChange={(agent) => {
                impersonation.setAgent(agent);
              }}
              useImpersonation={false}
              tooltipErrorMessage={''}
              showErrorMessage={false}
              showStatus={false}
              showExcludeInactiveCheckbox={false}
              defaultExcludeInactive={false}
              payPeriodId={null}
              useTeamGrouping={false}
            />
          </Menu.Item>
        )}
        {!role ? (
          <Menu.Item key="spinner" className="nav-menu-left nav-menu-not-clickable">
            <Spin />
          </Menu.Item>
        ) : (
          getNavItemsByUserType(role.role.name)
        )}
      </Menu>
    </nav>
  );
}

/* Helper Functions */
function createMenuItem(path: string, content: React.Node): React.Node {
  return (
    <Menu.Item key={path}>
      <Link to={path}>{content}</Link>
    </Menu.Item>
  );
}

function createIncentiveSubMenu(path: string, content: React.Node, userType: string): React.Node {
  return (
    <Menu.SubMenu key="subMenu" title={content}>
      <Menu.Item key="menuItem-incentives">
        <Link to={PATHS.INCENTIVES}></Link>
        Incentives Review
      </Menu.Item>
      {userType === RoleEnum.Admin && (
        <Menu.Item key="menuItem-incentives-management">
          <Link to={PATHS.INCENTIVESMANAGEMENT}></Link>
          Incentives Management
        </Menu.Item>
      )}
      <Menu.Item key="menuItem-contest-management">
        <Link to={PATHS.CONTESTMANAGEMENT}></Link>
        Contest Management
      </Menu.Item>
    </Menu.SubMenu>
  );
}

function createSearchMenuItem(): React.Node {
  return (
    <Menu.Item key={PATHS.SEARCH}>
      <SearchPopover />
    </Menu.Item>
  );
}

function getNavItemsByUserType(userType: RoleEnumType): Array<React.Node> {
  switch (userType) {
    case RoleEnum.Admin:
      return [
        createMenuItem(PATHS.DASHBOARD, 'Dashboard'),
        createMenuItem(PATHS.CORRECTIONSANDADJUSTMENTS, 'Corrections & Adjustments'),
        createMenuItem(PATHS.PAYPERIODREVIEW, 'Period Review'),
        createIncentiveSubMenu(PATHS.INCENTIVES, 'Incentives', userType),
        createSearchMenuItem(),
      ];
    case RoleEnum.RetentionManager:
      return [
        createMenuItem(PATHS.DASHBOARD, 'Dashboard'),
        createMenuItem(PATHS.CORRECTIONSANDADJUSTMENTS, 'Corrections & Adjustments'),
        createMenuItem(PATHS.PAYPERIODREVIEW, 'Period Review'),
        createIncentiveSubMenu(PATHS.INCENTIVES, 'Incentives', userType),
        createSearchMenuItem(),
      ];
    case RoleEnum.Manager:
      return [
        createMenuItem(PATHS.DASHBOARD, 'Dashboard'),
        createMenuItem(PATHS.PAYPERIODREVIEW, 'Period Review'),
        createIncentiveSubMenu(PATHS.INCENTIVES, 'Incentives', userType),
        createSearchMenuItem(),
      ];
    case RoleEnum.IncentiveCreator:
      return [
        createMenuItem(PATHS.DASHBOARD, 'Dashboard'),
        createMenuItem(PATHS.INCENTIVES, 'Incentives'),
        createSearchMenuItem(),
      ];
    case RoleEnum.Rep:
      return [createMenuItem(PATHS.DASHBOARD, 'Dashboard'), createSearchMenuItem()];
    default:
      return [];
  }
}

export default Nav;
