/* @flow */
import { get, isNil, orderBy } from 'lodash';
import WebAPIClient from './api/WebAPIClient';
import type { Agent } from '../models/Agent';
import type { AgentCommissionDashboard } from '../models/AgentCommissionDashboard';

export const getAgents = async (
  payPeriodId: ?number,
  excludeInactive: boolean,
  useImpersonation: boolean,
  useTeamGrouping: boolean,
): Promise<Array<Agent>> => {
  const queryData = !isNil(payPeriodId) ? { excludeInactive, payPeriodId } : { excludeInactive };
  const response = await WebAPIClient.get(
    'agents',
    { useTeamGrouping, ...queryData },
    useImpersonation,
    useTeamGrouping,
  );
  return orderBy(get(response, 'data', []), (a) => a.fullName, 'asc');
};

export const getAgentCommission = async (
  id: number,
  payPeriodId: number,
): Promise<?AgentCommissionDashboard> => {
  const response = await WebAPIClient.get(`agents/${id}/commissions`, { payPeriodId });
  return get(response, 'data', null);
};
