/* @flow */
export const DealCommissionTypes: {
  Sales: 1,
  SalesDevelopment: 2,
  Retention: 3,
} = Object.freeze({
  Sales: 1,
  SalesDevelopment: 2,
  Retention: 3,
});

export type DealCommissionTypeEnum = $Values<typeof DealCommissionTypes>;
