/* @flow */
import { createIncentive } from '../../../services/IncentiveService';
import { useMutation, useQueryClient } from 'react-query';
import CommissionsServiceKeys from '../../../constants/CommissionsServiceKeys';
import type { Incentive } from '../../../models/incentives/Incentive';
import type { IncentiveMutation } from '../../../models/incentives/IncentiveMutation';

type IncentiveCreateMutationOptions = $ReadOnly<{
  onSuccess?: (data: Incentive) => void,
  onError?: (error: any) => void,
}>;

type IncentiveCreateMutation = $ReadOnly<{
  mutateAsync: (
    data: IncentiveMutation,
    options?: IncentiveCreateMutationOptions,
  ) => Promise<?Incentive>,
  isLoading: boolean,
}>;

export default function useCreateIncentive(
  excludeUnpublished: boolean = true,
): IncentiveCreateMutation {
  const queryClient = useQueryClient();
  const cacheKey = `${CommissionsServiceKeys.INCENTIVES}-${String(excludeUnpublished)}`;

  const { mutateAsync, isLoading } = useMutation(
    (incentive: IncentiveMutation) => createIncentive(incentive),
    {
      onSuccess: async (response: { data: Incentive, status: number, ... }) => {
        const { data } = response;
        await queryClient.cancelQueries(cacheKey);
        const cachedIncentive = queryClient.getQueryData(cacheKey);

        const array = [...cachedIncentive, data];
        queryClient.setQueryData(cacheKey, () => array);

        return data;
      },
    },
  );

  return {
    mutateAsync: async (
      data: IncentiveMutation,
      options?: IncentiveCreateMutationOptions,
    ): Promise<?Incentive> => {
      try {
        const result = await mutateAsync(data);
        options?.onSuccess?.(result.data);
        return result.data;
      } catch (e) {
        options?.onError?.(e);
      }
      return null;
    },
    isLoading,
  };
}
