/* @flow */
export const TeamCommissionTypes: {
  Sales: 1,
  SalesDevelopment: 2,
  Retention: 3,
  RetentionFlatForce: 4,
  SalesAcceleration: 5,
  Onboarding: 6,
  Takeover: 7,
} = Object.freeze({
  Sales: 1,
  SalesDevelopment: 2,
  Retention: 3,
  RetentionFlatForce: 4,
  SalesAcceleration: 5,
  Onboarding: 6,
  Takeover: 7,
});

export type TeamCommissionTypeEnum = $Values<typeof TeamCommissionTypes>;
