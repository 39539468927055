/* @flow */
import * as React from 'react';
import { DatePicker } from 'antd';
import { useEffect, useState } from 'react';
import moment from 'moment/moment';
import type { AgentIncentive } from '../../../models/incentives/AgentIncentive';

type Props = $ReadOnly<{
  fieldId: number,
  form: any,
  agentIncentive: ?AgentIncentive,
}>;

function CalendarIncentiveValue(props: Props): React.Node {
  const { fieldId, form, agentIncentive } = props;
  const dateFormat = 'MM/DD/YYYY';

  const getInitialValue = () => {
    return moment(
      agentIncentive?.incentiveValues?.find((x) => x.incentiveFieldId === fieldId)?.value ??
        new Date(),
    );
  };

  const [dateState, setDateState] = useState(getInitialValue);

  const updateField = (date, dateString) => {
    form.setFieldsValue({ [fieldId]: dateString });
    setDateState(date);
  };

  useEffect(() => {
    form.setFieldsValue({ [fieldId]: getInitialValue().format(dateFormat) });
  }, []);

  return <DatePicker value={dateState} onChange={updateField} format={dateFormat} />;
}

export default CalendarIncentiveValue;
