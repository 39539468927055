/* @flow */
import '../../css/pay-period-review/ApproveConfirmationModal.css';
import * as React from 'react';
import { Button, Modal, notification } from 'antd';
import { filter, get, map } from 'lodash';
import { formatDateAsShortDate } from '../../utils/formatter';
import ApprovalStatistics from '../shared/ApprovalStatistics';
import useApproveAgentIncentives from '../../hooks/services/incentive/useApproveAgentIncentive';
import type { AgentIncentive } from '../../models/incentives/AgentIncentive';
import type { PayPeriod } from '../../models/PayPeriod';

type Props = $ReadOnly<{
  isVisible: boolean,
  teamName: string,
  onModalFinish: () => void,
  payPeriod: PayPeriod,
  agentIncentives: ?Array<AgentIncentive>,
  updateModalVisibility: (e: boolean) => void,
}>;

function ApproveConfirmationModal(props: Props): React.Node {
  const { isVisible, onModalFinish, payPeriod, agentIncentives, teamName, updateModalVisibility } =
    props;

  // Mutations
  const approveMutation = useApproveAgentIncentives();

  // #region Helper Functions

  const getPayPeriodLabel = (): string => {
    const startDate = formatDateAsShortDate(get(payPeriod, 'startDate'), false, false);
    const endDate = formatDateAsShortDate(get(payPeriod, 'endDate'), false, false);
    return `${startDate} - ${endDate}`;
  };

  // #endregion

  // #region Click Events

  const onCancelClick = () => updateModalVisibility(false);

  const onConfirmClick = async () => {
    const agentIncentivesToApprove = filter(
      agentIncentives,
      (agentIncentive) => agentIncentive.isSelected,
    );
    const approveIds = map(
      agentIncentivesToApprove,
      (approvedAgentIncentive) => approvedAgentIncentive.id,
    );

    await approveMutation.mutateAsync(
      { agentIncentiveIds: approveIds, payPeriodId: payPeriod.id },
      {
        onSuccess: () => {
          updateModalVisibility(false);
          openSuccessNotification();
          onModalFinish();
        },
        onError: (e) => {
          updateModalVisibility(false);
          if (e?.response?.data?.errors?.IsLocked)
            openFailureNotification(e.response.data.errors.IsLocked);
          else openFailureNotification();
        },
      },
    );
  };

  const openSuccessNotification = () => {
    const key = `approval-success-notification-${Date.now()}`;
    const btn = (
      <Button type="primary" size="small" onClick={() => notification.close(key)}>
        Ok
      </Button>
    );
    notification['success']({
      message: 'Approval Complete',
      description: <div>The selected agent incentives records have been approved.</div>,
      btn,
      key,
      placement: 'bottom',
    });
  };

  const openFailureNotification = (errorMessage?: string) => {
    const key = `export-failure-notification-${Date.now()}`;
    const btn = (
      <Button type="primary" size="small" onClick={() => notification.close(key)}>
        Ok
      </Button>
    );
    notification['error']({
      message: 'Approval Failed',
      description: (
        <div>
          Approval of the agent incentives records for pay period{' '}
          <strong>{getPayPeriodLabel()}</strong> failed. <strong>{errorMessage}</strong> If you need
          further assistance, please notify corporate IT
        </div>
      ),
      style: { width: 500 },
      duration: 0,
      btn,
      key,
      placement: 'bottom',
    });
  };

  // #endregion

  return (
    <>
      <Modal
        okText="Confirm"
        okButtonProps={{ loading: approveMutation.isLoading }}
        onCancel={onCancelClick}
        onOk={onConfirmClick}
        title={`Incentives Approval Confirmation for '${teamName}'`}
        visible={isVisible}
        width={700}
        wrapClassName="payroll-approve-modal"
      >
        <div className="payroll-approve-modal-content">
          Confirm you wish to approve the selected agent incentives records for pay period{' '}
          {getPayPeriodLabel()}. Approval will result in the following statistics as seen below.
        </div>
        <div className="payroll-approve-modal-header">
          Approval statistics for <strong>{teamName}</strong>
        </div>
        <ApprovalStatistics approvals={agentIncentives} teamName={teamName} />
      </Modal>
    </>
  );
}

export default ApproveConfirmationModal;
