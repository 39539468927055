/* @flow */
import '../../css/dashboard/PaymentTotals.css';
import * as React from 'react';
import { Card, Collapse, Empty } from 'antd';
import { DEFAULT_AGENT_COMMISSION_TOTALS } from '../../models/totals/AgentCommissionTotals';
import { formatNumberAsMoney, formatNumberAsPercentage } from '../../utils/formatter';
import type { AdjustmentTotals } from '../../models/totals/AdjustmentTotals';
import type { AgentCommissionTotals } from '../../models/totals/AgentCommissionTotals';
import type { BonusTotals } from '../../models/totals/BonusTotals';
import type { DealTotals } from '../../models/totals/DealTotals';
import type { ManagerTotals } from '../../models/totals/ManagerTotals';
import type { OnboardingTotals } from '../../models/totals/OnboardingTotals';
import type { RetentionTotals } from '../../models/totals/RetentionTotals';

type Props = $ReadOnly<{
  agentCommissionTotals: ?AgentCommissionTotals,
  showSalesTotals: boolean,
  showSalesDevelopmentTotals: boolean,
  showRetentionTotals: boolean,
  showManagerTotals: boolean,
  showOnboardingTotals: boolean,
}>;

function PaymentTotals(props: Props): React.Node {
  const {
    agentCommissionTotals,
    showSalesTotals,
    showSalesDevelopmentTotals,
    showRetentionTotals,
    showManagerTotals,
    showOnboardingTotals,
  } = props;
  const {
    commissionManagerTotals,
    commissionDealTotals,
    commissionRetentionTotals,
    commissionAdjustmentTotals,
    commissionOnboardingTotals,
    commissionTotal,
    commissionBonusTotals,
  } = agentCommissionTotals ?? DEFAULT_AGENT_COMMISSION_TOTALS;

  if (
    !showSalesTotals &&
    !showSalesDevelopmentTotals &&
    !showRetentionTotals &&
    !showManagerTotals &&
    !showOnboardingTotals
  ) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  const totalsComponents = [];

  showManagerTotals && totalsComponents.push(createManagerBlocks(commissionManagerTotals));

  (showSalesTotals || showSalesDevelopmentTotals) &&
    totalsComponents.push(createDealBlocks(commissionDealTotals, commissionBonusTotals));

  showRetentionTotals && totalsComponents.push(createRetentionBlocks(commissionRetentionTotals));

  totalsComponents.push(createAdjustmentBlock(commissionAdjustmentTotals));

  showOnboardingTotals && totalsComponents.push(createOnboardingBlocks(commissionOnboardingTotals));

  const totalsWithDividerComponents = totalsComponents.reduce((previous, current, index) => {
    if (index === 0) {
      return [current];
    }

    return [
      ...previous,
      <div key={index} className="payment-totals-separater">
        +
      </div>,
      current,
    ];
  }, []);

  return (
    <div className="payment-totals-root">
      {totalsWithDividerComponents}
      <div className="payment-totals-separater">=</div>
      <div className="payment-totals-final-sum">{createCommissionTotalBlock(commissionTotal)}</div>
    </div>
  );
}

function createManagerBlocks(managerTotals: ManagerTotals): React.Node {
  const {
    salesCommissionAmount,
    retentionCommissionAmount,
    numberOfRetentionRepsWithBonus,
    retentionBonusAmount,
    retentionFlatForceCommissionAmount,
    numberOfRetentionFlatForceRepsWithBonus,
    retentionFlatForceBonusAmount,
    numberOfSalesDevelopmentRepsWithBonus,
    salesDevelopmentTotal,
    bonusTotal,
    total,
  } = managerTotals;
  const formattedTotal = formatPaymentTotals(total);
  return (
    <Collapse key="manager" className="payment-totals-block">
      <Collapse.Panel
        header={
          <div className="payment-totals-panel-header">
            {`Manager Commission: ${formattedTotal}`}
          </div>
        }
        key="managersPanel"
      >
        {salesCommissionAmount > 0 && (
          <>
            {createPanelLineItem('Sales Commission:', formatPaymentTotals(salesCommissionAmount))}
            {createPanelLineItem('Bonus:', formatPaymentTotals(bonusTotal))}
            <hr />
          </>
        )}
        {retentionCommissionAmount > 0 && (
          <>
            {createPanelLineItem(
              'Retention Commission:',
              formatPaymentTotals(retentionCommissionAmount),
            )}
            {createPanelLineItem('# of Bonus Reps:', numberOfRetentionRepsWithBonus)}
            {createPanelLineItem('Bonus:', formatPaymentTotals(retentionBonusAmount))}
            <hr />
          </>
        )}
        {retentionFlatForceCommissionAmount > 0 && (
          <>
            {createPanelLineItem(
              'Retention FF Commission:',
              formatPaymentTotals(retentionFlatForceCommissionAmount),
            )}
            {createPanelLineItem('# of Bonus Reps:', numberOfRetentionFlatForceRepsWithBonus)}
            {createPanelLineItem('Bonus:', formatPaymentTotals(retentionFlatForceBonusAmount))}
            <hr />
          </>
        )}
        {salesDevelopmentTotal > 0 && (
          <>
            {createPanelLineItem('SDR Team Bonuses:', formatPaymentTotals(bonusTotal))}
            {createPanelLineItem('# of Bonus Reps:', numberOfSalesDevelopmentRepsWithBonus)}
            {createPanelLineItem('Rep Bonus:', formatPaymentTotals(salesDevelopmentTotal))}
            <hr />
          </>
        )}
        {createPanelLineItem(<b>Total Commission:</b>, formattedTotal)}
      </Collapse.Panel>
    </Collapse>
  );
}

function createDealBlocks(dealTotals: DealTotals, commissionBonusTotals: BonusTotals): React.Node {
  const {
    cancelledAmount,
    trueUpAmount,
    salesCommissionAmount,
    salesDevelopmentAmount,
    salesDevelopmentSoldCount,
    salesDevelopmentCancelledCount,
    soldAmount,
    soldHoldbackAmount,
    soldHoldbackPercentage,
    soldDigitalRawAmount,
    soldDirectMailRawAmount,
    otherAmount,
  } = dealTotals;

  const { total } = commissionBonusTotals;

  const formattedSaleDevelopmentAmount = formatPaymentTotals(salesDevelopmentAmount);
  const formattedSoldAmount = formatPaymentTotals(soldAmount);
  const formattedTotal = formatPaymentTotals(salesCommissionAmount);
  const formattedBonusTotal = formatPaymentTotals(total);
  return (
    <React.Fragment key="deals">
      {salesDevelopmentAmount > 0 ? (
        <>
          <Collapse key="junior" className="payment-totals-block">
            <Collapse.Panel
              header={
                <div className="payment-totals-panel-header">
                  {`Junior Commissions: ${formattedSaleDevelopmentAmount}`}
                </div>
              }
              key="juniorCommissionPanel"
            >
              {createPanelLineItem('Number of deals sold:', salesDevelopmentSoldCount)}
              {createPanelLineItem('Number of deals canceled:', salesDevelopmentCancelledCount)}
              {createPanelLineItem('MBI closing ratio bonus:', formattedBonusTotal)}
              <hr />
              {createPanelLineItem(<b>Total Commission:</b>, formattedSaleDevelopmentAmount)}
            </Collapse.Panel>
          </Collapse>
          <div className="payment-totals-separater">+</div>
        </>
      ) : null}
      <Collapse key="sales" className="payment-totals-block">
        <Collapse.Panel
          header={
            <div className="payment-totals-panel-header">
              {`Sales Commission: ${formattedTotal}`}
            </div>
          }
          key="salesCommissionPanel"
        >
          {createPanelLineItem(
            'Digital Raw Commission:',
            formatPaymentTotals(soldDigitalRawAmount),
          )}
          {createPanelLineItem(
            'Direct Mail Raw Commission:',
            formatPaymentTotals(soldDirectMailRawAmount),
          )}
          {createPanelLineItem(
            `Holdback (${formatNumberAsPercentage(soldHoldbackPercentage)}):`,
            formatPaymentTotals(soldHoldbackAmount),
          )}
          <hr />
          {createPanelLineItem('Total Sold Commission:', formattedSoldAmount)}
          <br />
          {createPanelLineItem('Sold Commission:', formattedSoldAmount)}
          {createPanelLineItem('True Up Commission:', formatPaymentTotals(trueUpAmount))}
          {createPanelLineItem('Canceled Commission:', formatPaymentTotals(cancelledAmount))}
          {createPanelLineItem('Other Commission:', formatPaymentTotals(otherAmount))}
          <hr />
          {createPanelLineItem(<b>Total Sales Commission:</b>, formattedTotal)}
        </Collapse.Panel>
      </Collapse>
    </React.Fragment>
  );
}

function createRetentionBlocks(retentionTotals: RetentionTotals): React.Node {
  const { savedCurrentTotal, trueUpTotal, total } = retentionTotals;

  const formattedDiscountOfferedAmount = formatPaymentTotals(savedCurrentTotal);
  const formattedSaveRatioAmount = formatPaymentTotals(trueUpTotal);
  const formattedTotal = formatPaymentTotals(total);
  return (
    <Collapse key="retention" className="payment-totals-block">
      <Collapse.Panel
        header={
          <div className="payment-totals-panel-header">
            {`Retention Commission: ${formattedTotal}`}
          </div>
        }
        key="salesCommissionPanel"
      >
        {createPanelLineItem('Saved Current Period:', formattedDiscountOfferedAmount)}
        {createPanelLineItem('True Up:', formattedSaveRatioAmount)}
        <hr />
        {createPanelLineItem(<b>Total Retention Commission:</b>, formattedTotal)}
      </Collapse.Panel>
    </Collapse>
  );
}

function createAdjustmentBlock(adjustmentTotals: AdjustmentTotals): React.Node {
  const { positiveAmount, negativeAmount, total } = adjustmentTotals;
  const formattedTotal = formatPaymentTotals(total);
  return (
    <Collapse className="payment-totals-block">
      <Collapse.Panel
        header={
          <div className="payment-totals-panel-header">{`Adjustments: ${formattedTotal}`}</div>
        }
        key="adjustmentsPanel"
      >
        {createPanelLineItem('Bonuses:', formatPaymentTotals(positiveAmount))}
        {createPanelLineItem('Penalties:', formatPaymentTotals(negativeAmount))}
        <hr />
        {createPanelLineItem(<b>Total Adjustments:</b>, formattedTotal)}
      </Collapse.Panel>
    </Collapse>
  );
}

function createOnboardingBlocks(onboardingTotals: OnboardingTotals): React.Node {
  const { numberOfReps, bonusPerRep, total } = onboardingTotals; //

  const formattedTotal = formatPaymentTotals(total);
  return (
    <Collapse className="payment-totals-block">
      <Collapse.Panel
        header={
          <div className="payment-totals-panel-header">{`Daily Sales: ${formattedTotal}`}</div>
        }
        key="onboardingBonus"
      >
        {createPanelLineItem('Number of bonus rep:', numberOfReps)}
        {createPanelLineItem('Amount per rep:', formatPaymentTotals(bonusPerRep))}
        <hr />
        {createPanelLineItem(<b>Daily Sales:</b>, formattedTotal)}
      </Collapse.Panel>
    </Collapse>
  );
}

function createPanelLineItem(label: React.Node, value: React.Node): React.Node {
  return (
    <div className="payment-totals-line-item">
      <div className="payment-totals-label">{label}</div>
      <div className="payment-totals-value">{value}</div>
    </div>
  );
}

function createCommissionTotalBlock(commissionTotal: number): React.Node {
  return (
    <Card className="payment-totals-commission-total-block">
      <span className="payment-totals-commission-total-label">Total:</span>
      <span className="payment-totals-commission-total-amount">
        {formatPaymentTotals(commissionTotal)}
      </span>
    </Card>
  );
}

function formatPaymentTotals(value: number): string {
  return formatNumberAsMoney(value, true);
}

export default PaymentTotals;
