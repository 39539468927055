/* @flow */
import { getAdjustments } from '../../../services/AdjustmentService';
import { useQuery } from 'react-query';
import CommissionsServiceKeys from '../../../constants/CommissionsServiceKeys';
import useQueryCacheKey from '../../useQueryCacheKey';
import type { Adjustment } from '../../../models/Adjustment';

type AdjustmentQueryData = {
  isLoading: boolean,
  adjustments: Array<Adjustment>,
};

function useAdjustments(payPeriodId: number | any): AdjustmentQueryData {
  const cacheKey = useQueryCacheKey(CommissionsServiceKeys.ADJUSTMENTS);

  const { isFetching, data } = useQuery(
    [...cacheKey, { payPeriodId: payPeriodId }],
    async () => getAdjustments(payPeriodId),
    {
      enabled: payPeriodId > 0,
      staleTime: 0,
    },
  );

  return {
    isLoading: isFetching,
    adjustments: data,
  };
}

export default useAdjustments;
