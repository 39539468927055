/* @flow */
import '../../../css/incentives/dynamic-form-components/ContractNumbersIncentiveValue.css';
import * as React from 'react';
import { Input, Tag } from 'antd';
import { useState } from 'react';
import type { AgentIncentive } from '../../../models/incentives/AgentIncentive';

type Props = $ReadOnly<{
  fieldId: number,
  form: any,
  agentIncentive: ?AgentIncentive,
}>;

function ContractNumbersIncentiveValue(props: Props): React.Node {
  const { fieldId, form, agentIncentive } = props;

  const getTagsValue = () => {
    const jsonValue =
      agentIncentive?.incentiveValues?.find((x) => x.incentiveFieldId === fieldId)?.value ?? '';

    if (jsonValue.length > 0) {
      return JSON.parse(jsonValue);
    }
    return null;
  };

  const [tags, setTags] = useState(getTagsValue() ?? []);

  const [inputValue, setInputValue] = useState('');

  const updateTags = (tags) => {
    setTags(tags);
    form.setFieldsValue({ [fieldId]: JSON.stringify(tags) });
  };

  const handleDeleteTag = (removedTag) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    updateTags(newTags);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    const bySpace = inputValue.toString().split(' ');

    if (inputValue && tags.indexOf(inputValue) === -1) {
      updateTags([...tags, ...bySpace]);
    }

    setInputValue('');
  };
  const forMap = (tag) => {
    const tagElem = (
      <Tag
        closable
        onClose={(e) => {
          e.preventDefault();
          handleDeleteTag(tag);
        }}
      >
        {tag}
      </Tag>
    );
    return (
      <span
        key={tag}
        style={{
          display: 'inline-block',
        }}
      >
        {tagElem}
      </span>
    );
  };
  const tagChild = tags.map(forMap);
  return (
    <>
      <Input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onBlur={handleInputConfirm}
        onPressEnter={handleInputConfirm}
        placeholder="Provide a list of contract numbers separated by space"
      />
      <div className="tags">{tagChild}</div>
    </>
  );
}

export default ContractNumbersIncentiveValue;
