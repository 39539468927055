/* @flow */
import type { AdjustmentTotals } from './AdjustmentTotals';
import type { BonusTotals } from './BonusTotals';
import type { DealTotals } from './DealTotals';
import type { ManagerTotals } from './ManagerTotals';
import type { OnboardingTotals } from './OnboardingTotals';
import type { RetentionTotals } from './RetentionTotals';

export type AgentCommissionTotals = {
  commissionOnboardingTotals: OnboardingTotals,
  commissionAdjustmentTotals: AdjustmentTotals,
  commissionBonusTotals: BonusTotals,
  commissionDealTotals: DealTotals,
  commissionRetentionTotals: RetentionTotals,
  commissionManagerTotals: ManagerTotals,
  commissionTotal: number,
};

export const DEFAULT_AGENT_COMMISSION_TOTALS: AgentCommissionTotals = {
  commissionOnboardingTotals: {
    numberOfReps: 0,
    bonusPerRep: 0,
    total: 0,
  },
  commissionAdjustmentTotals: {
    positiveAmount: 0,
    negativeAmount: 0,
    total: 0,
  },
  commissionBonusTotals: {
    closingRatioAmount: 0,
    saveRatioAmount: 0,
    total: 0,
  },
  commissionDealTotals: {
    cancelledAmount: 0,
    trueUpAmount: 0,
    salesCommissionAmount: 0,
    salesDevelopmentAmount: 0,
    salesDevelopmentSoldCount: 0,
    salesDevelopmentCancelledCount: 0,
    soldCurrentAmount: 0,
    soldPriorAmount: 0,
    soldAmount: 0,
    soldHoldbackAmount: 0,
    soldHoldbackPercentage: 0,
    soldDigitalRawAmount: 0,
    soldDirectMailRawAmount: 0,
    total: 0,
    unsettledAmount: 0,
    otherAmount: 0,
  },
  commissionRetentionTotals: {
    discountOfferedAmount: 0,
    saveRatioAmount: 0,
    savedCurrentTotal: 0,
    outstandingTotal: 0,
    trueUpTotal: 0,
    total: 0,
  },
  commissionManagerTotals: {
    salesCommissionAmount: 0,
    numberOfSalesRepsWithBonus: 0,
    salesBonusAmount: 0,
    salesTotal: 0,
    retentionCommissionAmount: 0,
    numberOfRetentionRepsWithBonus: 0,
    retentionBonusAmount: 0,
    retentionTotal: 0,
    retentionFlatForceCommissionAmount: 0,
    numberOfRetentionFlatForceRepsWithBonus: 0,
    retentionFlatForceBonusAmount: 0,
    retentionFlatForceTotal: 0,
    numberOfSalesDevelopmentRepsWithBonus: 0,
    salesDevelopmentTotal: 0,
    bonusTotal: 0,
    total: 0,
  },
  commissionTotal: 0,
};
