/* @flow */
import { approveAgentIncentive } from '../../../services/IncentiveService';
import { filter, findIndex, forEach, head, reduce, size } from 'lodash';
import { useMutation, useQueryClient } from 'react-query';
import CommissionReviewsServiceKeys from '../../../constants/CommissionsServiceKeys';
import useQueryCacheKey from '../../useQueryCacheKey';
import type { AgentIncentive } from '../../../models/incentives/AgentIncentive';
import type { TeamIncentivesReview } from '../../../models/incentives/TeamIncentivesReview';

type ApproveRequest = $ReadOnly<{
  agentIncentiveIds: Array<number>,
  payPeriodId: number,
}>;

type ApproveIncentiveMutationOptions = $ReadOnly<{
  onSuccess?: () => void,
  onError?: (error: any) => void,
}>;

type ApproveIncentivesMutation = $ReadOnly<{
  mutateAsync: (data: ApproveRequest, options?: ApproveIncentiveMutationOptions) => Promise<void>,
  isLoading: boolean,
}>;

export default function useApproveIncentives(): ApproveIncentivesMutation {
  const queryClient = useQueryClient();
  const cacheKey = useQueryCacheKey(CommissionReviewsServiceKeys.AGENT_INCENTIVES);

  const { mutateAsync, isLoading } = useMutation(
    (request: ApproveRequest) =>
      approveAgentIncentive(request.agentIncentiveIds, request.payPeriodId),
    {
      onSuccess: async (result: Array<AgentIncentive>) => {
        const queryKey = [...cacheKey, { payPeriodId: head(result).payPeriodId }];

        await queryClient.cancelQueries(queryKey);
        const cachedIncentiveReviews = queryClient.getQueryData(queryKey);
        const approvedAgentIncentives = filter(result, (review) => review.isApproved);

        forEach(approvedAgentIncentives, (value) => {
          forEach(cachedIncentiveReviews.teamIncentivesReviews, (incentiveReview) => {
            const index = findIndex(
              incentiveReview.agentIncentives,
              (review) => review.id === value.id,
            );

            if (index >= 0) {
              incentiveReview.agentIncentives[index].isApproved = true;
            }
          });

          forEach(
            cachedIncentiveReviews.teamIncentivesReviews,
            (teamIncentiveReview: TeamIncentivesReview) => {
              teamIncentiveReview.teamIncentivesSummary.numberOfAgentsApproved = size(
                filter(
                  teamIncentiveReview.agentIncentives,
                  (agentIncentive: AgentIncentive) => agentIncentive.isApproved === true,
                ),
              );
            },
          );
        });

        cachedIncentiveReviews.incentiveReviewSummary.numberOfAgentsApproved = reduce(
          cachedIncentiveReviews.teamIncentivesReviews,
          (total, teamIncentiveReview) => {
            return total + teamIncentiveReview.teamIncentivesSummary.numberOfAgentsApproved;
          },
          0,
        );

        return cachedIncentiveReviews;
      },
    },
  );

  return {
    mutateAsync: async (
      data: ApproveRequest,
      options?: ApproveIncentiveMutationOptions,
    ): Promise<void> => {
      try {
        await mutateAsync(data);
        options?.onSuccess?.();
      } catch (e) {
        options?.onError?.(e);
      }
    },
    isLoading,
  };
}
