/* @flow */
import type { Agent } from '../models/Agent';

import * as React from 'react';

export type ImpersonationContextType = {
  agent: ?Agent,
  setAgent: (agent: ?Agent) => void,
};

export const ImpersonationContext: React$Context<ImpersonationContextType> =
  React.createContext<ImpersonationContextType>({
    agent: null,
    setAgent: () => {},
  });
