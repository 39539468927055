/* @flow */
import * as React from 'react';
import { DatePicker } from 'antd';
import { useEffect, useState } from 'react';
import moment from 'moment/moment';
import type { AgentIncentive } from '../../../models/incentives/AgentIncentive';

const { RangePicker } = DatePicker;

type Props = $ReadOnly<{
  fieldId: number,
  form: any,
  agentIncentive: ?AgentIncentive,
}>;

function CalendarIncentiveValue(props: Props): React.Node {
  const { fieldId, form, agentIncentive } = props;
  const dateFormat = 'MM/DD/YYYY';

  const getInitialValue = () => {
    const jsonValue =
      agentIncentive?.incentiveValues?.find((x) => x.incentiveFieldId === fieldId)?.value ?? '';

    if (jsonValue.length > 0) {
      const parsedValues = JSON.parse(jsonValue);
      return [moment(parsedValues[0]), moment(parsedValues[1])];
    }
    return [moment(new Date()), moment(new Date())];
  };

  const [dateState, setDateState] = useState(getInitialValue());

  const updateField = (date, dateString) => {
    form.setFieldsValue({ [fieldId]: JSON.stringify(dateString) });
    setDateState(date);
  };

  useEffect(() => {
    form.setFieldsValue({
      [fieldId]: JSON.stringify([dateState[0].format(dateFormat), dateState[1].format(dateFormat)]),
    });
  }, []);

  return <RangePicker value={dateState} onChange={updateField} format={dateFormat} />;
}

export default CalendarIncentiveValue;
