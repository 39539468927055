/* @flow */
import * as React from 'react';
import { isEmpty, isNil } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import SearchBar from '../shared/SearchBar';
import SearchResultsTable from '../search/SearchResultsTable';
import Section from '../shared/Section';
import SubHeader from '../shared/SubHeader';
import useCommissionSearchResults from '../../hooks/services/commission/useCommissionSearchResults';

function Search(): React.Node {
  // Hooks
  const [searchParams, setSearchParams] = useSearchParams();
  const searchTerm = searchParams.get('q');
  const searchResults = useCommissionSearchResults(searchTerm);

  // Constants
  const searchTermExists = !isNil(searchTerm) && !isEmpty(searchTerm);
  const isLoading = isNil(searchResults);
  const resultCount = searchResults?.length ?? 0;

  return (
    <>
      <SubHeader
        headerLabel={'Commission Details Search'}
        primarySelector={
          <SearchBar
            width="small"
            enterButton={false}
            onSearch={(value) => setSearchParams({ q: value })}
          />
        }
        secondarySelector={null}
        secondarySelectorSeparator={
          !searchTermExists
            ? ''
            : isLoading
            ? 'Searching for'
            : `${resultCount} Search Result${resultCount > 0 ? 's' : ''} for`
        }
        secondarySelectorLabel={searchTermExists ? `'${searchTerm}'` : ''}
      />
      <Section
        headerLabel={null}
        contents={[
          <SearchResultsTable key={'searchResultSection'} searchResults={searchResults} />,
        ]}
        isLoading={isLoading}
      />
    </>
  );
}

export default Search;
