/* @flow */
import * as React from 'react';
import { RetentionGroupTypes } from '../../enums/RetentionGroupTypeEnum';
import { Tabs } from 'antd';
import { filter } from 'lodash';
import RetentionCommissionsTable from './RetentionCommissionsTable.jsx';
import type { RetentionDealCommission } from '../../models/deals/RetentionDealCommission';
import type { RetentionTotals } from '../../models/totals/RetentionTotals';
const { TabPane } = Tabs;

type Props = $ReadOnly<{
  commissions: Array<RetentionDealCommission>,
  total: ?RetentionTotals,
  extraContent?: React.Node,
}>;

function RetentionCommissionTabs(props: Props): React.Node {
  const { commissions: deals, total, extraContent } = props;

  const savedCurrentDeals = filter(
    deals,
    (deal) => deal.dealGroup === RetentionGroupTypes.SavedCurrent,
  );
  const claimedDeals = filter(deals, (deal) => deal.dealGroup === RetentionGroupTypes.Claimed);
  const trueUpDeals = filter(deals, (deal) => deal.dealGroup === RetentionGroupTypes.TrueUp);
  const cancelledDeals = filter(deals, (deal) => deal.dealGroup === RetentionGroupTypes.Cancelled);
  const unsettledDeals = filter(deals, (deal) => deal.dealGroup === RetentionGroupTypes.Unsettled);

  const currentSavesLabel = `Saved Current (${savedCurrentDeals.length})`;
  const claimedLabel = `Claimed (${claimedDeals.length})`;
  const trueUpLabel = `True Up (${trueUpDeals.length})`;
  const cancelledLabel = `Canceled (${cancelledDeals.length})`;
  const unsettledLabel = `Unsettled (${unsettledDeals.length})`;

  return (
    <Tabs defaultActiveKey="1" tabBarExtraContent={extraContent}>
      <TabPane tab={currentSavesLabel} key="1">
        <RetentionCommissionsTable
          commissions={savedCurrentDeals}
          total={total?.savedCurrentTotal}
        />
      </TabPane>
      <TabPane tab={claimedLabel} key="2">
        <RetentionCommissionsTable
          commissions={claimedDeals}
          columnConfig={[
            'customerNumber',
            'contractNumber',
            'status',
            'earnedTotal',
            'savedDate',
            'discountOfferedAmount',
            'saveRatioBonusAmount',
            'commissionTotal',
          ]}
        />
      </TabPane>
      <TabPane tab={trueUpLabel} key="3">
        <RetentionCommissionsTable
          commissions={trueUpDeals}
          total={total?.trueUpTotal}
          totalLabel="Total Earned"
          columnConfig={[
            'customerNumber',
            'contractNumber',
            'status',
            'savedDate',
            'paymentDate',
            'discountOfferedAmount',
            'saveRatioBonusAmount',
            'commissionTotal',
            'earnedTotal',
          ]}
        />
      </TabPane>
      <TabPane tab={cancelledLabel} key="4">
        <RetentionCommissionsTable
          commissions={cancelledDeals}
          columnConfig={[
            'customerNumber',
            'contractNumber',
            'status',
            'savedDate',
            'cancelledDate',
            'discountOfferedAmount',
            'saveRatioBonusAmount',
            'commissionTotal',
            'earnedTotal',
          ]}
        />
      </TabPane>
      <TabPane tab={unsettledLabel} key="5">
        <RetentionCommissionsTable
          commissions={unsettledDeals}
          total={total?.outstandingTotal}
          totalLabel="Total Outstanding"
          columnConfig={[
            'customerNumber',
            'contractNumber',
            'status',
            'savedDate',
            'discountOfferedAmount',
            'saveRatioBonusAmount',
            'commissionTotal',
          ]}
        />
      </TabPane>
    </Tabs>
  );
}

export default RetentionCommissionTabs;
