/* @flow */
import { Parser } from 'json2csv';
import { replace, split, trim } from 'lodash';
import type { File } from '../models/File';

export function createAndDownloadCsv(data: any, fields: Array<any>, fileName: string) {
  const jsonParser = new Parser({ fields });
  const fileContents = jsonParser.parse(data);

  var blob = new Blob([fileContents], { type: 'text/csv' });
  var url = URL.createObjectURL(blob);

  fileName = replace(fileName, '.csv', '');
  fileName = replace(fileName, /"/g, '');

  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = fileName;
  anchor.click();
}

export function convertHeaderStringToObject(header: string): any {
  const obj = {};
  const headerKeyValuePairs = split(header, ';');

  for (var i = 0; i < headerKeyValuePairs.length; i++) {
    const keyValuePair = split(headerKeyValuePairs[i], '=');
    const key = keyValuePair[0];
    const value = keyValuePair[1];
    if (key && value) obj[trim(key)] = value;
  }
  return obj;
}

export function downloadSuccessHandler(isSuccessful: boolean, file: ?File) {
  if (!file) return;

  var blob = new Blob([file.fileContents], { type: file.contentType });
  var url = URL.createObjectURL(blob);

  let fileName = replace(file.fileName, '.csv', '');
  fileName = replace(fileName, /"/g, '');

  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = fileName;
  anchor.click();
}
