/* @flow */
export default {
  environment: 'prod',
  agentGatewayUrl: 'https://end-gateway-commissions-prod.azurewebsites.net/api',
  auth: {
    clientId: 'cdbf8224-f34c-4536-afce-68379a6ab2da',
    authority: 'https://login.microsoftonline.com/daae7bc1-c893-46c4-8df4-4464a9a6f43e',
  },
  commissionsStartDate: '5-2-2022',
  impersonatedEmail: '',
  allowWriteOnImpersonation: false,
};
