/* @flow */
import { getCommissionReviews } from '../../../services/CommissionService';
import { useQuery } from 'react-query';
import CommissionReviewsServiceKeys from '../../../constants/CommissionsServiceKeys';
import useQueryCacheKey from '../../useQueryCacheKey';
import type { CommissionReview } from '../../../models/reviews/CommissionReview';

type CommissionReviewQueryData = {
  isLoading: boolean,
  reviews: CommissionReview,
};

const CACHE_TIME_IN_MILLISECONDS = 10 * 60 * 1000; // Ten minutes

function useReviews(payPeriodId: number | any): CommissionReviewQueryData {
  const cacheKey = useQueryCacheKey(CommissionReviewsServiceKeys.COMMISSION_REVIEW);

  const { isFetching, data } = useQuery(
    [...cacheKey, { payPeriodId: payPeriodId }],
    async () => getCommissionReviews(payPeriodId),
    {
      enabled: payPeriodId > 0,
      cacheTime: CACHE_TIME_IN_MILLISECONDS,
      staleTime: CACHE_TIME_IN_MILLISECONDS,
    },
  );

  return {
    isLoading: isFetching,
    reviews: data,
  };
}

export default useReviews;
