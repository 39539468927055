/* @flow */
import { isNaN, isNil, toNumber } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useNavigationType, useSearchParams } from 'react-router-dom';

type PayPeriodQueryState = {
  payPeriodId: ?number,
  onPayPeriodChange: (id: ?number, setQueryParam: boolean) => void,
};

function usePayPeriodQueryState(): PayPeriodQueryState {
  // Navigation
  const [searchParams, setSearchParams] = useSearchParams();
  const navigationType = useNavigationType();
  const queryPayPeriodId = searchParams.get('payPeriod');
  const defaultPayPeriodId = !isNil(queryPayPeriodId) ? toNumber(queryPayPeriodId) : null;

  // States
  const [payPeriodId, setPayPeriodId] = useState(
    isNaN(defaultPayPeriodId) ? 0 : defaultPayPeriodId,
  );

  // Callbacks
  const onPayPeriodChange = useCallback((value: ?number, setQueryParam: boolean) => {
    setPayPeriodId(value);
    const validPayPeriod = value ?? 0;
    if (validPayPeriod > 0 && setQueryParam) {
      setSearchParams({ payPeriod: value });
    }
  }, []);

  // Effects
  useEffect(() => {
    if (navigationType !== 'POP' || isNil(defaultPayPeriodId)) {
      return;
    }

    setPayPeriodId(defaultPayPeriodId);
  }, [defaultPayPeriodId, navigationType]);

  return {
    payPeriodId,
    onPayPeriodChange,
  };
}

export default usePayPeriodQueryState;
