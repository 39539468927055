/* @flow */
import { get, orderBy } from 'lodash';
import WebAPIClient from './api/WebAPIClient';
import type { Adjustment } from '../models/Adjustment';
import type { AdjustmentMutation } from '../models/AdjustmentMutation';

export const getAdjustments = async (payPeriodId: number): Promise<Array<Adjustment>> => {
  const response = await WebAPIClient.get('adjustments', { payPeriodId: payPeriodId });
  return orderBy(get(response, 'data', null), (adjustment) => adjustment.agentFullName, 'asc');
};

export const updateAdjustment = async (adjustment: AdjustmentMutation): Promise<Adjustment> =>
  await WebAPIClient.put(`adjustments/${adjustment.id}`, adjustment);

export const deleteAdjustment = async (adjustmentId: number, payPeriodId: number): Promise<any> =>
  await WebAPIClient.delete(`adjustments/${adjustmentId}?payPeriodId=${payPeriodId}`);

export const createAdjustment = async (adjustment: AdjustmentMutation): Promise<Adjustment> =>
  await WebAPIClient.post('adjustments', adjustment);
