/* @flow */
import { RoleEnum } from '../../../enums/RoleEnum';
import { getMyRole } from '../../../services/RoleService';
import { useQuery } from 'react-query';
import CommissionServiceKeys from '../../../constants/CommissionsServiceKeys';
import useQueryCacheKey from '../../useQueryCacheKey';
import type { RoleAccess } from '../../../models/RoleAccess';

function useMyRole(
  options: { useImpersonation: boolean } = { useImpersonation: true },
): ?RoleAccess {
  const { useImpersonation } = options;
  const cacheKey = useQueryCacheKey(CommissionServiceKeys.MY_ROLE, useImpersonation);

  const { isFetching, isError, data } = useQuery(cacheKey, () => getMyRole(useImpersonation), {
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  if (isFetching) {
    return null;
  }

  if (isError) {
    return { role: { id: 999, name: RoleEnum.Unknown }, hasReadOnlyAccess: true };
  }

  return data;
}

export default useMyRole;
