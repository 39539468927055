/* @flow */
import '../../css/pages/Incentives.css';
import * as React from 'react';
import { Affix, Button, Empty } from 'antd';
import { LockTypes } from '../../enums/LockTypeEnum';
import {
  clone,
  concat,
  filter,
  find,
  forEach,
  isNil,
  last,
  map,
  orderBy,
  reduce,
  size,
  split,
} from 'lodash';
import { useCallback, useState } from 'react';
import AgentIncentiveForm from '../incentives/AgentIncentiveForm';
import IncentiveSummaryWidget from '../incentives/IncentiveSummaryWidget';
import IncentivesSummaryWidget from '../incentives/IncentivesSummaryWidget';
import PayPeriodSelector from '../shared/PayPeriodSelector';
import Section from '../shared/Section';
import SubHeader from '../shared/SubHeader';
import useAgentIncentives from '../../hooks/services/incentive/useAgentIncentives';
import useIncentive from '../../hooks/services/incentive/useIncentive';
import useMyRole from '../../hooks/services/role/useMyRole';
import usePayPeriodQueryState from '../../hooks/usePayPeriodQueryState';
import usePayPeriods from '../../hooks/services/pay-period/usePayPeriods';
import type { AgentIncentive } from '../../models/incentives/AgentIncentive';
import type { Incentive } from '../../models/incentives/Incentive';
import type { TeamIncentivesReview } from '../../models/incentives/TeamIncentivesReview';
import type { TeamReviewSummary } from '../../models/reviews/TeamReviewSummary';

function Incentives(): React.Node {
  // States
  const { payPeriodId, onPayPeriodChange } = usePayPeriodQueryState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentAgentIncentive, setCurrentAgentIncentive] = useState(null);
  const [selectedRowsDictionary, setSelectedRowsDictionary] = useState({});

  //Hooks
  const payPeriods = usePayPeriods();
  const myRole = useMyRole();

  // Queries
  const { isLoadingIncentives, incentives } = useIncentive();
  const { isLoadingAgentIncentives, incentiveReview } = useAgentIncentives(payPeriodId ?? 0);

  // Handlers
  const setModal = (isOpen: boolean, agentIncentive: ?AgentIncentive) => {
    setCurrentAgentIncentive(agentIncentive);
    setIsModalOpen(isOpen);
  };

  // Callbacks
  const setSelectedRowsDictionaryCallback = useCallback(
    (action: { teamId: string, records: Array<any> }) => {
      const clonedDictionary = clone(selectedRowsDictionary);
      const key = `${action.teamId}-${payPeriodId ?? ''}`;

      if (action.records === null || size(action.records) === 0) {
        clonedDictionary[key] = [];
        setSelectedRowsDictionary(clonedDictionary);
        return;
      }

      forEach(action.records, (record) => {
        const isInDictionary =
          find(clonedDictionary[key], (row) => row.id === record.id) !== undefined;

        clonedDictionary[key] = isInDictionary
          ? filter(clonedDictionary[key], (row) => row.key !== record.key)
          : filter(concat(clonedDictionary[key], record), (row) => row !== undefined);
      });
      setSelectedRowsDictionary(clonedDictionary);
    },
    [selectedRowsDictionary, payPeriodId],
  );

  const getCountOfSelectedRowsForPeriod = (payPeriodId: number): number => {
    const selectedRowsForThisPeriod = filter(selectedRowsDictionary, (value, key) => {
      const keySplit = split(key, '-');
      const keyPayPeriod = last(keySplit);
      return keyPayPeriod === payPeriodId.toString();
    });

    return reduce(
      selectedRowsForThisPeriod,
      (sum, value) => {
        return sum + size(value);
      },
      0,
    );
  };

  const selectedPayPeriod = find(payPeriods, (payPeriod) => payPeriod.id === payPeriodId);
  const isLocked =
    selectedPayPeriod && selectedPayPeriod.payPeriodLocks
      ? selectedPayPeriod.payPeriodLocks.some(
          (payPeriod) => payPeriod.lockType === LockTypes.Incentives,
        )
      : true;
  const summary = !isNil(incentiveReview) ? incentiveReview.incentiveReviewSummary : null;
  const teamIncentives = !isNil(incentiveReview) ? incentiveReview.teamIncentivesReviews : null;

  const getTeamBreakdownIncentives = (
    teamIncentives: ?Array<TeamIncentivesReview>,
    incentives: ?Array<Incentive>,
    payPeriodId: number,
  ): React.Node => {
    if (!teamIncentives) return [];

    return map(
      orderBy(teamIncentives, (review: TeamReviewSummary) => review.teamName),
      (teamIncentive) => {
        const key = `${teamIncentive.teamId}-${payPeriodId || 0}`;
        return (
          <IncentiveSummaryWidget
            key={key}
            incentives={incentives}
            teamIncentives={teamIncentive}
            payPeriod={selectedPayPeriod}
            setModal={setModal}
            setSelectedRowsCallback={setSelectedRowsDictionaryCallback}
            selectedRows={selectedRowsDictionary[key]}
            roles={myRole}
          />
        );
      },
    );
  };

  return (
    <>
      {isModalOpen && (
        <AgentIncentiveForm
          agentIncentive={currentAgentIncentive}
          payPeriodId={payPeriodId ?? 0}
          incentives={incentives}
          setModal={setModal}
          isOpen={isModalOpen}
        ></AgentIncentiveForm>
      )}
      <SubHeader
        headerLabel={'Incentives'}
        primarySelector={null}
        secondarySelectorSeparator={'for'}
        secondarySelectorLabel={'Period'}
        secondarySelector={
          <PayPeriodSelector
            payPeriodId={payPeriodId}
            onChange={onPayPeriodChange}
            showStatus={true}
          />
        }
      />
      {selectedPayPeriod && (
        <Section
          headerLabel={''}
          contents={[
            !isLocked ? (
              <Button className="add-new-agent-incentive-button" type="primary" onClick={setModal}>
                New Incentive
              </Button>
            ) : (
              ''
            ),

            !summary || summary.numberOfAgents <= 0 ? (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
            ) : (
              <div className="incentives-review-root">
                <div className="incentives-table-container">
                  {incentiveReview &&
                    getTeamBreakdownIncentives(teamIncentives, incentives, payPeriodId ?? 0)}{' '}
                </div>
                <div className="summary-widget">
                  <Affix offsetTop={65}>
                    <IncentivesSummaryWidget
                      key="incentivesSummarySection"
                      summary={summary}
                      payPeriod={selectedPayPeriod}
                      numberOfSelectedRows={getCountOfSelectedRowsForPeriod(payPeriodId || 0)}
                    />
                  </Affix>
                </div>
              </div>
            ),
          ]}
          isLoading={isLoadingIncentives || isLoadingAgentIncentives}
        />
      )}
    </>
  );
}

export default Incentives;
