/* @flow */
import * as React from 'react';
import { useState } from 'react';

type Props = $ReadOnly<{
  inputText: ?string,
}>;

function ShowMore(props: Props): React.Node {
  const { inputText } = props;

  const [showMore, setShowMore] = useState(false);

  return (
    <>
      <span>{showMore ? inputText : inputText?.slice(0, 40)}</span>
      {(inputText?.length ?? 0) > 40 && (
        <span onClick={() => setShowMore(!showMore)}>
          <a>{showMore ? ' Show Less' : ' ... Show More'}</a>
        </span>
      )}
    </>
  );
}
export default ShowMore;
