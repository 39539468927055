/* @flow */
import { updateIncentiveStatus } from '../../../services/IncentiveService';
import { useMutation, useQueryClient } from 'react-query';
import CommissionsServiceKeys from '../../../constants/CommissionsServiceKeys';
import type { Incentive } from '../../../models/incentives/Incentive';
import type { IncentiveMutation } from '../../../models/incentives/IncentiveMutation';

type IncentiveStatusMutationOptions = $ReadOnly<{
  onSuccess?: (isSuccessful: boolean) => void,
  onError?: (error: any) => void,
}>;

type IncentiveStatusMutation = $ReadOnly<{
  mutateAsync: (
    data: IncentiveMutation,
    options?: IncentiveStatusMutationOptions,
  ) => Promise<?Incentive>,
  isLoading: boolean,
}>;

function useUpdateIncentiveStatus(excludeUnpublished: boolean = true): IncentiveStatusMutation {
  const queryClient = useQueryClient();
  const cacheKey = `${CommissionsServiceKeys.INCENTIVES}-${String(excludeUnpublished)}`;

  const { mutateAsync, isLoading } = useMutation(
    async (incentive) => updateIncentiveStatus(incentive),
    {
      onSuccess: async (response, updatedIncentive: Incentive) => {
        await queryClient.cancelQueries(cacheKey);
        const cachedPayPeriods = queryClient.getQueryData(cacheKey);

        const index = cachedPayPeriods.findIndex((item) => item.id === updatedIncentive.id);

        if (index >= 0) {
          cachedPayPeriods.splice(index, 1, updatedIncentive);
          queryClient.setQueryData(cacheKey, () => cachedPayPeriods);
        }
        return cachedPayPeriods[index];
      },
    },
  );

  return {
    mutateAsync: async (
      data: IncentiveMutation,
      options?: IncentiveStatusMutationOptions,
    ): Promise<void> => {
      try {
        const isSuccessful = await mutateAsync(data);
        options?.onSuccess?.(isSuccessful);
      } catch (e) {
        options?.onError?.(e);
      }
    },
    isLoading,
  };
}

export default useUpdateIncentiveStatus;
