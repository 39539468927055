/* @flow */
import { getContests } from '../../../services/IncentiveService';
import { useQuery } from 'react-query';
import CommissionsServiceKeys from '../../../constants/CommissionsServiceKeys';
import useQueryCacheKey from '../../useQueryCacheKey';
import type { Contest } from '../../../models/incentives/Contest';

type ContestsQueryData = {
  isLoadingContests: boolean,
  contests: Array<Contest>,
};

function useContest(payPeriodId: number): ContestsQueryData {
  const cacheKey = useQueryCacheKey(CommissionsServiceKeys.CONTESTS);

  const { isFetching, data } = useQuery(
    [...cacheKey, { payPeriodId: payPeriodId }],
    async () => getContests(payPeriodId),
    {
      staleTime: 0,
      enabled: payPeriodId > 0,
    },
  );

  return {
    isLoadingContests: isFetching,
    contests: data,
  };
}

export default useContest;
