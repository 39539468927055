/* @flow */
import * as React from 'react';
import { Button } from 'antd';
import { createAndDownloadCsv } from '../../utils/downloader';
import {
  formatDateAsShortDate,
  formatDateForFileName,
  formatNumberAsMoney,
} from '../../utils/formatter';
import { formatDealGroupType, formatRetentionCommissionStatus } from '../../utils/enumFormatter';
import type { Agent } from '../../models/Agent';
import type { PayPeriod } from '../../models/PayPeriod';
import type { RetentionDealCommission } from '../../models/deals/RetentionDealCommission';

type Props = $ReadOnly<{
  agent: ?Agent,
  buttonText?: string,
  deals: ?Array<RetentionDealCommission>,
  payPeriod: PayPeriod,
}>;

RetentionCommissionDownloadCsvButton.defaultProps = {
  buttonText: 'Download',
};

function RetentionCommissionDownloadCsvButton(props: Props): React.Node {
  const { buttonText } = props;
  return (
    <Button onClick={() => onDownloadClick(props)} type="link">
      {buttonText}
    </Button>
  );
}

function getFields(): any {
  return [
    {
      label: 'Customer Number',
      value: 'moxyCustomerNumber',
    },
    {
      label: 'Contract Number',
      value: 'contractNumber',
    },
    {
      label: 'Channel',
      value: (row: any): string => formatDealGroupType(row.dealGroup),
    },
    {
      label: 'Status',
      value: (row: any): string => formatRetentionCommissionStatus(row.retentionCommissionStatus),
    },
    {
      label: 'Saved Date',
      value: (row: any): string => formatDateAsShortDate(row.savedDate, true, true),
      default: '',
    },
    {
      label: 'Payment Date',
      value: (row: any): string => formatDateAsShortDate(row.paymentDate, true, true),
    },
    {
      label: 'Canceled Date',
      value: (row: any): string => formatDateAsShortDate(row.CancelledDate, true, true),
      default: '',
    },
    {
      label: 'PIF Date',
      value: (row: any): string => formatDateAsShortDate(row.pifDate, true, true),
    },
    {
      label: 'Discount Contr.',
      value: (row: any): string => formatNumberAsMoney(row.discountOfferedAmount, true),
    },
    {
      label: 'Save Ratio Contr.',
      value: (row: any): string => formatNumberAsMoney(row.saveRatioBonusAmount, true),
    },
    {
      label: 'Total Commission',
      value: (row: any): string => formatNumberAsMoney(row.commissionTotal, true),
    },
    {
      label: 'Earned this Period',
      value: (row: any): string => formatNumberAsMoney(row.earnedTotal, true),
    },
  ];
}

function getFileName(agent: ?Agent, payPeriod: PayPeriod): string {
  const startDate = formatDateForFileName(payPeriod.startDate);
  const endDate = formatDateForFileName(payPeriod.endDate);
  return `Agent ${
    agent?.fullName ?? ''
  } Retention Commissions for Period ${startDate} to ${endDate}.csv`;
}

function onDownloadClick(props: Props) {
  let { deals, agent, payPeriod } = props;
  const fileName = getFileName(agent, payPeriod);
  const fieldData = getFields();
  createAndDownloadCsv(deals, fieldData, fileName);
}

export default RetentionCommissionDownloadCsvButton;
