/* @flow */
import * as React from 'react';
import { DealGroupTypes } from '../../enums/DealGroupTypeEnum';
import { Tabs } from 'antd';
import { filter } from 'lodash';
import CommissionSalesTable from './CommissionSalesTable';
import type { DealTotals } from '../../models/totals/DealTotals';
import type { SalesCommission } from '../../models/deals/SalesCommission';
const { TabPane } = Tabs;

type Props = $ReadOnly<{
  deals: ?Array<SalesCommission>,
  total: ?DealTotals,
  extraContent?: React.Node,
}>;

function SalesCommissionsTabs(props: Props): React.Node {
  const { deals, total, extraContent } = props;

  const soldCurrentDeals = filter(deals, (deal) => deal.dealGroup === DealGroupTypes.SoldCurrent);
  const soldPriorDeals = filter(deals, (deal) => deal.dealGroup === DealGroupTypes.SoldPrior);
  const trueUpDeals = filter(deals, (deal) => deal.dealGroup === DealGroupTypes.TrueUp);
  const cancelledDeals = filter(deals, (deal) => deal.dealGroup === DealGroupTypes.Cancelled);
  const unsettledDeals = filter(deals, (deal) => deal.dealGroup === DealGroupTypes.Unsettled);

  const soldCurrentLabel = `Sold Current (${soldCurrentDeals.length})`;
  const soldPriorLabel = `Sold Prior (${soldPriorDeals.length})`;
  const trueUpLabel = `True Up (${trueUpDeals.length})`;
  const cancelledLabel = `Canceled (${cancelledDeals.length})`;
  const unsettledLabel = `Unsettled (${unsettledDeals.length})`;

  return (
    <Tabs tabBarExtraContent={extraContent}>
      <TabPane tab={soldCurrentLabel} key="1">
        <CommissionSalesTable deals={soldCurrentDeals} total={total?.soldCurrentAmount} />
      </TabPane>
      <TabPane tab={soldPriorLabel} key="2">
        <CommissionSalesTable deals={soldPriorDeals} total={total?.soldPriorAmount} />
      </TabPane>
      <TabPane tab={trueUpLabel} key="3">
        <CommissionSalesTable deals={trueUpDeals} total={total?.trueUpAmount} />
      </TabPane>
      <TabPane tab={cancelledLabel} key="4">
        <CommissionSalesTable
          deals={cancelledDeals}
          total={total?.cancelledAmount}
          totalLabel="Total Canceled this Period"
        />
      </TabPane>
      <TabPane tab={unsettledLabel} key="5">
        <CommissionSalesTable
          deals={unsettledDeals}
          total={total?.unsettledAmount}
          totalLabel="Total Outstanding this Period"
          columnConfig={[
            'customerNumber',
            'contractNumber',
            'dealType',
            'commissionStatus',
            'outstandingTotal',
            'earnedTotal',
            'soldDate',
            'cancelledDate',
            'fundedDate',
            'paymentType',
            'fullPay',
            'collectedPaymentTotal',
            'activationFee',
            'holdbackTotal',
            'financeTermAmount',
            'retailDeviationAmount',
            'eliteActivationAmount',
            'closingRatioBonusAmount',
            'outboundClosingRatioBonusAmount',
            'splitDeal',
            'rawTotal',
          ]}
        />
      </TabPane>
    </Tabs>
  );
}

export default SalesCommissionsTabs;
