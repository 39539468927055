/* @flow */
import { filter, findIndex, forEach, reduce, size } from 'lodash';
import { unapproveCommissions } from '../../../services/CommissionService';
import { useMutation, useQueryClient } from 'react-query';
import CommissionReviewsServiceKeys from '../../../constants/CommissionsServiceKeys';
import useQueryCacheKey from '../../useQueryCacheKey';
import type { AgentCommission } from '../../../models/AgentCommission';

type UnapproveRequest = $ReadOnly<{
  agentCommissionId: number,
  payPeriodId: number,
}>;

type UnapproveCommissionsMutationOptions = $ReadOnly<{
  onSuccess?: () => void,
  onError?: (error: any) => void,
}>;

type UnapproveCommissionsMutation = $ReadOnly<{
  mutateAsync: (
    data: UnapproveRequest,
    options?: UnapproveCommissionsMutationOptions,
  ) => Promise<void>,
  isLoading: boolean,
}>;

export default function useUnapproveCommissions(): UnapproveCommissionsMutation {
  const queryClient = useQueryClient();
  const cacheKey = useQueryCacheKey(CommissionReviewsServiceKeys.COMMISSION_REVIEW);

  const { mutateAsync, isLoading } = useMutation(
    (request: UnapproveRequest) =>
      unapproveCommissions(request.agentCommissionId, request.payPeriodId),
    {
      onSuccess: async (result: AgentCommission) => {
        const queryKey = [...cacheKey, { payPeriodId: result.payPeriod.id }];

        await queryClient.cancelQueries(queryKey);
        const cachedReviews = queryClient.getQueryData(queryKey);

        forEach(cachedReviews.teamReviews, (teamReview) => {
          const index = findIndex(
            teamReview.commissionReviews,
            (review) => review.agentCommissionId === result.id,
          );

          if (index >= 0) {
            teamReview.commissionReviews[index].isApproved = false;
          }
        });

        forEach(cachedReviews.teamReviews, (teamReview) => {
          teamReview.teamSummary = {
            ...teamReview.teamSummary,
            numberOfAgentsApproved: size(
              filter(teamReview.commissionReviews, (review) => review.isApproved === true),
            ),
          };
        });

        if (cachedReviews.managerReview) {
          TeamReview(cachedReviews.managerReview);
        }

        if (cachedReviews.teamLeadReview) {
          TeamReview(cachedReviews.teamLeadReview);
        }

        function TeamReview(teamReview) {
          const index = findIndex(
            teamReview.reviews,
            (review) => review.agentCommissionId === result.id,
          );

          if (index >= 0) {
            teamReview.reviews[index].isApproved = false;
          }

          teamReview.summary.numberOfAgentsApproved = size(
            filter(teamReview.reviews, (review) => review.isApproved === true),
          );
        }

        cachedReviews.summary.numberOfAgentsApproved = reduce(
          cachedReviews.teamReviews,
          (total, teamReview) => {
            return total + teamReview.teamSummary.numberOfAgentsApproved;
          },
          0,
        );

        if (cachedReviews.managerReview) {
          TeamReviews(cachedReviews.managerReview);
        }

        if (cachedReviews.teamLeadReview) {
          TeamReviews(cachedReviews.teamLeadReview);
        }

        function TeamReviews(team) {
          cachedReviews.summary.numberOfAgentsApproved += team.summary.numberOfAgentsApproved;
        }

        return cachedReviews;
      },
    },
  );

  return {
    mutateAsync: async (
      data: UnapproveRequest,
      options?: UnapproveCommissionsMutationOptions,
    ): Promise<void> => {
      try {
        await mutateAsync(data);
        options?.onSuccess?.();
      } catch (e) {
        options?.onError?.(e);
      }
    },
    isLoading,
  };
}
