/* @flow */
export const RetentionCommissionStatuses: {
  AwaitingPayment: 1,
  Stolen: 2,
  PaidOut: 3,
  Cancelled: 4,
  AwaitingFunding: 5,
} = Object.freeze({
  AwaitingPayment: 1,
  Stolen: 2,
  PaidOut: 3,
  Cancelled: 4,
  AwaitingFunding: 5,
});

export type RetentionCommissionStatusEnum = $Values<typeof RetentionCommissionStatuses>;
