/* @flow */
import { createAdjustment } from '../../../services/AdjustmentService';
import { useMutation, useQueryClient } from 'react-query';
import CommissionsServiceKeys from '../../../constants/CommissionsServiceKeys';
import useQueryCacheKey from '../../useQueryCacheKey';
import type { Adjustment } from '../../../models/Adjustment';
import type { AdjustmentMutation } from '../../../models/AdjustmentMutation';

type AdjustmentCreateMutationOptions = $ReadOnly<{
  onSuccess?: (data: Adjustment) => void,
  onError?: (error: any) => void,
}>;

type AdjustmentCreateMutation = $ReadOnly<{
  mutateAsync: (
    data: AdjustmentMutation,
    options?: AdjustmentCreateMutationOptions,
  ) => Promise<?Adjustment>,
  isLoading: boolean,
}>;

export default function useCreateAdjustment(): AdjustmentCreateMutation {
  const queryClient = useQueryClient();
  const cacheKey = useQueryCacheKey(CommissionsServiceKeys.ADJUSTMENTS);

  const { mutateAsync, isLoading } = useMutation(
    (adjustment: AdjustmentMutation) => createAdjustment(adjustment),
    {
      onSuccess: async (response: { data: Adjustment, status: number, ... }) => {
        const { data } = response;
        const queryKey = [...cacheKey, { payPeriodId: data.payPeriodId }];

        await queryClient.cancelQueries(queryKey);
        const cachedAdjustments = queryClient.getQueryData(queryKey);
        const array = [...cachedAdjustments, data];
        queryClient.setQueryData(queryKey, () => array);
        return data;
      },
    },
  );

  return {
    mutateAsync: async (
      data: AdjustmentMutation,
      options?: AdjustmentCreateMutationOptions,
    ): Promise<?Adjustment> => {
      try {
        const result = await mutateAsync(data);
        options?.onSuccess?.(result.data);
        return result.data;
      } catch (e) {
        options?.onError?.(e);
      }
      return null;
    },
    isLoading,
  };
}
