/* @flow */
import * as React from 'react';
import { Table } from 'antd';
import { assign, isNil, join, map, reverse, sortBy, trim, words } from 'lodash';
import { formatNumberAsMoney } from '../../utils/formatter';
import AgentIncentiveValues from '../incentives/AgentIncentiveValues';
import type { AgentIncentive } from '../../models/incentives/AgentIncentive';
import type { Incentive } from '../../models/incentives/Incentive';

type Props = $ReadOnly<{
  incentives: ?Array<Incentive>,
  agentIncentives: ?Array<AgentIncentive>,
}>;

function IncentivesTable(props: Props): React.Node {
  const { incentives, agentIncentives } = props;

  const getColumns = (): any => {
    return [
      {
        title: 'Incentive',
        dataIndex: 'incentiveName',
        editable: false,
        sorter: (a, b) => a.incentiveName.localeCompare(b.incentiveName),
        width: 250,
      },
      {
        title: 'Amount',
        dataIndex: 'value',
        editable: false,
        sorter: (a, b) => a.amount - b.amount,
        render: (_, row: AgentIncentive) => formatNumberAsMoney(row.amount),
        width: 100,
      },
      {
        title: 'Details',
        dataIndex: 'details',
        editable: false,
        render: (_, row: AgentIncentive) => {
          return (
            <AgentIncentiveValues
              key={row.incentiveId}
              selectedIncentiveId={row.incentiveId}
              isReadOnly={true}
              incentives={incentives}
              agentIncentive={row}
              form={null}
            ></AgentIncentiveValues>
          );
        },
        width: 800,
      },
    ];
  };

  const getRows = (rows: ?Array<AgentIncentive>): Array<AgentIncentive> => {
    if (!rows) {
      return [];
    }
    const data = sortBy(rows, (row) => getFullNameReversed(row.agentFullName));
    return map(data, (record) => {
      return assign({ key: record.id.toString() }, record);
    });
  };

  return (
    <Table
      bordered
      className="incentive-table"
      columns={getColumns()}
      dataSource={getRows(agentIncentives)}
      pagination={false}
      scroll={{ y: 600 }}
      size="small"
    />
  );
}

function getFullNameReversed(fullName: string) {
  if (isNil(fullName)) return '';
  const fullNameArray = words(fullName);
  const fullNameArrayReversed = reverse(fullNameArray);
  const fullNameReversed = join(fullNameArrayReversed, ' ');
  return trim(fullNameReversed);
}

export default IncentivesTable;
