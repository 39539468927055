/* @flow */
import * as React from 'react';
import { Link } from 'react-router-dom';
import PATHS from '../../constants/paths';

type Props = $ReadOnly<{
  agentId: number,
  payPeriodId: number,
  children?: React.Node,
}>;

function DashboardLink({ agentId, payPeriodId, children }: Props): React.Node {
  return (
    <Link to={`${PATHS.DASHBOARD}?agent=${agentId}&payPeriod=${payPeriodId}`} target="_blank">
      {children}
    </Link>
  );
}

export default DashboardLink;
