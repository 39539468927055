/* @flow */
import '../../css/incentives/AgentIncentiveForm.css';
import * as React from 'react';
import { Button, Form, InputNumber, Modal, notification } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RoleEnum } from '../../enums/RoleEnum';
import { customFormItemValidator } from '../../utils/formValidators';
import { faRobot } from '@fortawesome/free-solid-svg-icons';
import { getIncentiveFieldLabel } from '../../utils/formatter';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import AgentIncentiveValues from './AgentIncentiveValues';
import AgentSelector from '../shared/AgentSelector';
import DashboardLink from '../shared/DashboardLink';
import IncentiveTypeSelector from './IncentiveTypeSelector';
import OriginalAmountLabel from '../shared/OriginalAmountLabel';
import useCreateAgentIncentive from '../../hooks/services/incentive/useCreateAgentIncentive';
import useMyRole from '../../hooks/services/role/useMyRole';
import useUpdateAgentIncentive from '../../hooks/services/incentive/useUpdateAgentIncentive';
import type { Agent } from '../../models/Agent';
import type { AgentIncentive } from '../../models/incentives/AgentIncentive';
import type { Incentive } from '../../models/incentives/Incentive';

type Props = $ReadOnly<{
  payPeriodId: number,
  incentives: ?Array<Incentive>,
  agentIncentive: ?AgentIncentive,
  isOpen: boolean,
  setModal: (isOpen: boolean, agentIncentive: ?AgentIncentive) => void,
}>;

function AgentIncentiveForm(props: Props): React.Node {
  const { payPeriodId, incentives, agentIncentive, isOpen, setModal } = props;

  const AGENT_ERROR_MESSAGE = 'Agent is required';

  const myRole = useMyRole();

  // States
  const [selectedIncentiveId, setSelectedIncentiveId] = useState(agentIncentive?.incentiveId);
  const [selectedMasterAgent, setSelectedMasterAgent] = useState(agentIncentive?.masterAgentId);
  const [isErrorAgent, setIsErrorAgent] = useState(false);

  // Forms
  const [form] = Form.useForm();
  const [incentiveValuesForm] = Form.useForm();

  const amount = Form.useWatch('amount', form);

  // Mutations
  const createMutation = useCreateAgentIncentive();
  const updateMutation = useUpdateAgentIncentive();

  // UI modifications
  const modalTitle = (agentIncentive) => {
    if (isSystemGenerated(agentIncentive)) {
      return 'View Incentive';
    } else if (agentIncentive && agentIncentive.id > 0) {
      return 'Edit Incentive';
    } else {
      return 'New Incentive';
    }
  };

  const isLoading = updateMutation.isLoading || createMutation.isLoading;

  useEffect(() => {
    if (agentIncentive && !isNil(agentIncentive.pricePerUnit)) {
      agentIncentive.amount = Number(agentIncentive.pricePerUnit);
    }
  }, []);

  useEffect(() => {
    if (!isOpen) return;

    if (agentIncentive) {
      form.setFieldsValue({
        masterAgent: selectedMasterAgent,
        incentiveId: selectedIncentiveId,
        amount: agentIncentive.amount,
      });

      return;
    }

    if (!isNil(selectedIncentiveId)) {
      form.setFieldsValue({
        amount: incentives?.find((x) => x.id === selectedIncentiveId)?.fixedAmount ?? 0,
      });
    }
  }, [agentIncentive, selectedIncentiveId]);

  const updateAgentIncentive = async (formValues, incentiveFieldValuesForAgentIncentive) => {
    await updateMutation.mutateAsync(
      {
        id: agentIncentive?.id ?? 0,
        amount: formValues.amount,
        isSystemGenerated: agentIncentive?.isSystemGenerated ?? false,
        masterAgentId: selectedMasterAgent ?? 0,
        payPeriodId: payPeriodId,
        incentiveId: formValues.incentiveId,
        incentiveValues: incentiveFieldValuesForAgentIncentive,
        agentFullName: '',
        approvedBy: '',
        approvedDate: '',
        isApproved: false,
        createdBy: '',
        createdDate: '',
        updatedBy: '',
        updatedDate: '',
      },
      {
        onSuccess: () => {
          resetFields();
        },
        onError: (error) => {
          notification.error({
            message: 'Update Failed!',
            description:
              error.response?.data.errors?.ContractNumbers ??
              error.response?.data.message ??
              error.message,
          });
        },
      },
    );
  };

  const createAgentIncentive = async (formValues, incentiveFieldValuesForAgentIncentive) => {
    await createMutation.mutateAsync(
      {
        id: 0,
        amount: formValues.amount,
        isSystemGenerated: false,
        masterAgentId: selectedMasterAgent ?? 0,
        payPeriodId: payPeriodId,
        incentiveId: formValues.incentiveId,
        incentiveValues: incentiveFieldValuesForAgentIncentive,
        agentFullName: '',
        approvedBy: '',
        approvedDate: '',
        isApproved: false,
        createdBy: '',
        createdDate: '',
        updatedBy: '',
        updatedDate: '',
      },
      {
        onSuccess: () => {
          resetFields();
        },
        onError: (error) => {
          notification.error({
            message: 'Update Failed!',
            description:
              error.response?.data.errors?.ContractNumbers ??
              error.response?.data.errors?.Number ??
              error.response?.data.message ??
              error.message,
          });
        },
      },
    );
  };

  const extractDynamicFormData = (incentive, dynamicFormValues) => {
    return incentive.incentiveFields.reduce((acc, field) => {
      const currentFieldValueId = dynamicFormValues[field.id];
      if (currentFieldValueId) {
        return [
          ...acc,
          {
            id:
              agentIncentive?.incentiveValues?.find((x) => x.incentiveFieldId === field.id)?.id ??
              0,
            incentiveFieldId: field.id,
            value: currentFieldValueId,
            incentiveFieldType: field.incentiveFieldType,
            name: field.name,
            order: field.order,
            isMultiplier: field.isMultiplier,
          },
        ];
      }
      return acc;
    }, []);
  };

  const resetFields = () => {
    form.resetFields();
    setSelectedMasterAgent(0);
    setSelectedIncentiveId(0);
    setModal(false, null);
  };

  // Handlers
  const handleOk = async () => {
    const formValues = await form.validateFields();
    const incentiveValuesFormValues = await incentiveValuesForm.validateFields();

    if (!formValues) return;
    if (!incentiveValuesFormValues) return;

    // Map the values from dynamic form
    const incentive = incentives?.find((x) => x.id === selectedIncentiveId);

    if (!incentive) return; // no incentive selected, we should not proceed

    const incentiveFieldValuesForAgentIncentive = extractDynamicFormData(
      incentive,
      incentiveValuesFormValues,
    );

    if (agentIncentive && agentIncentive.id > 0) {
      await updateAgentIncentive(formValues, incentiveFieldValuesForAgentIncentive);
      return;
    }
    await createAgentIncentive(formValues, incentiveFieldValuesForAgentIncentive);
  };

  const handleCancel = () => {
    resetFields();
  };

  const handleIncentiveTypeChange = (incentiveId: number) => {
    if (agentIncentive) {
      agentIncentive.incentiveId = incentiveId;
      agentIncentive.amount =
        incentives?.find((incentive) => incentive.id === incentiveId)?.fixedAmount ?? 0;
    }
    setSelectedIncentiveId(incentiveId);
  };

  const selectedMasterAgentChanged = (agent: ?Agent) => {
    setSelectedMasterAgent(agent?.id ?? 0);
  };

  const isSystemGenerated = (agentIncentive) =>
    agentIncentive?.isSystemGenerated ? (
      <div style={{ color: 'green' }}>
        <FontAwesomeIcon style={{ marginLeft: 330, marginRight: 5 }} icon={faRobot} />
        System Generated
      </div>
    ) : null;

  return (
    <Modal
      title={modalTitle(agentIncentive)}
      visible={isOpen}
      onCancel={handleCancel}
      footer={
        (!agentIncentive || !isSystemGenerated(agentIncentive)) && [
          <Button key="cancel" type="primary" disabled={isLoading} onClick={() => handleCancel()}>
            Cancel
          </Button>,
          <Button key="ok" type="primary" loading={isLoading} onClick={() => handleOk()}>
            OK
          </Button>,
        ]
      }
    >
      <div className="new-agent-selector-main-container">
        <Form requiredMark={false} layout="vertical" form={form}>
          <Form.Item
            name="masterAgent"
            label="Rep "
            rules={[
              {
                validator: (_, value) =>
                  customFormItemValidator(!isNil(value), AGENT_ERROR_MESSAGE, setIsErrorAgent),
              },
            ]}
          >
            {!isNil(agentIncentive) ? (
              <DashboardLink agentId={agentIncentive?.masterAgentId ?? 0} payPeriodId={payPeriodId}>
                {agentIncentive?.agentFullName ?? ''}
                {isSystemGenerated(agentIncentive)}
              </DashboardLink>
            ) : (
              <AgentSelector
                agentId={agentIncentive?.masterAgentId}
                showStatus={true}
                useImpersonation={true}
                showErrorMessage={isErrorAgent}
                showExcludeInactiveCheckbox={false}
                defaultExcludeInactive={false}
                payPeriodId={payPeriodId}
                tooltipErrorMessage={AGENT_ERROR_MESSAGE}
                onChange={selectedMasterAgentChanged}
                useTeamGrouping={true}
              />
            )}
          </Form.Item>
          <Form.Item
            name="incentiveId"
            label="Incentive"
            rules={[{ required: true, message: 'Please select Incentive!' }]}
          >
            <IncentiveTypeSelector
              inputValue={selectedIncentiveId}
              onChange={handleIncentiveTypeChange}
              incentives={incentives}
            ></IncentiveTypeSelector>
          </Form.Item>
          {!isNil(selectedIncentiveId) && incentives && (
            <div className="override-container">
              <Form.Item
                name="amount"
                label={getIncentiveFieldLabel(selectedIncentiveId ?? 0, incentives)}
                rules={[{ required: true, message: 'Please provide an Amount!' }]}
              >
                <InputNumber
                  readOnly={myRole?.role.name !== RoleEnum.Admin}
                  autoSize={{ minRows: 1, maxRows: 1 }}
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                />
              </Form.Item>
              <div className="override-label">
                <OriginalAmountLabel
                  incentives={incentives}
                  selectedIncentiveId={selectedIncentiveId}
                  agentIncentive={agentIncentive}
                  amount={amount}
                  isInModal={true}
                />
              </div>
            </div>
          )}
        </Form>
        <AgentIncentiveValues
          isReadOnly={false}
          selectedIncentiveId={selectedIncentiveId ?? 0}
          incentives={incentives}
          agentIncentive={agentIncentive}
          form={incentiveValuesForm}
        ></AgentIncentiveValues>
      </div>
    </Modal>
  );
}

export default AgentIncentiveForm;
