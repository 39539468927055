/* @flow */
import { unlockPayPeriod } from '../../../services/PayPeriodService';
import { useMutation, useQueryClient } from 'react-query';
import CommissionsServiceKeys from '../../../constants/CommissionsServiceKeys';
import useQueryCacheKey from '../../useQueryCacheKey';
import type { LockTypeEnum } from '../../../enums/LockTypeEnum';
import type { PayPeriod } from '../../../models/PayPeriod';

type PayPeriodLockMutationOptions = $ReadOnly<{
  onSuccess?: (isSuccessful: boolean) => void,
  onError?: (error: any) => void,
}>;

type PayPeriodLockMutation = $ReadOnly<{
  mutateAsync: (
    data: { payPeriodId: number, lockType: LockTypeEnum },
    options?: PayPeriodLockMutationOptions,
  ) => Promise<?PayPeriod>,
  isLoading: boolean,
}>;

function useUnlockPayPeriod(): PayPeriodLockMutation {
  const queryClient = useQueryClient();
  const cacheKey = useQueryCacheKey(CommissionsServiceKeys.PAY_PERIODS);

  const { mutateAsync, isLoading } = useMutation(
    async ({ payPeriodId, lockType }) => unlockPayPeriod(payPeriodId, lockType),
    {
      onSuccess: async (response: PayPeriod) => {
        await queryClient.cancelQueries(cacheKey);
        const cachedPayPeriods = queryClient.getQueryData(cacheKey);

        const index = cachedPayPeriods.findIndex((item) => item.id === response.id);

        if (index >= 0) {
          cachedPayPeriods.splice(index, 1, response);
          queryClient.setQueryData(cacheKey, () => cachedPayPeriods);
        }
        return cachedPayPeriods[index];
      },
    },
  );

  return {
    mutateAsync: async (
      data: { payPeriodId: number, lockType: LockTypeEnum },
      options?: PayPeriodLockMutationOptions,
    ): Promise<?PayPeriod> => {
      try {
        const isSuccessful = await mutateAsync(data);
        options?.onSuccess?.(isSuccessful);
      } catch (e) {
        options?.onError?.(e);
      }
    },
    isLoading,
  };
}

export default useUnlockPayPeriod;
