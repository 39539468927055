/* @flow */
import * as React from 'react';
import { Button, Modal, notification } from 'antd';
import { formatDateAsShortDate } from '../../utils/formatter';
import { get } from 'lodash';
import useLockPayPeriod from '../../hooks/services/pay-period/useLockPayPeriod';
import useUnlockPayPeriod from '../../hooks/services/pay-period/useUnlockPayPeriod';
import type { LockTypeEnum } from '../../enums/LockTypeEnum';
import type { PayPeriod } from '../../models/PayPeriod';

type Props = $ReadOnly<{
  isVisible: boolean,
  payPeriod: PayPeriod,
  isLocking: boolean,
  lockType: LockTypeEnum,
  updateModalVisibility: (e: boolean) => void,
}>;

function LockConfirmationModal(props: Props): React.Node {
  const { isVisible, payPeriod, isLocking, updateModalVisibility, lockType } = props;

  // Mutations
  const lockMutation = useLockPayPeriod();
  const unlockMutation = useUnlockPayPeriod();

  // #region Helper Functions

  const getPayPeriodLabel = (): string => {
    const startDate = formatDateAsShortDate(get(payPeriod, 'startDate'), false, false);
    const endDate = formatDateAsShortDate(get(payPeriod, 'endDate'), false, false);
    return `${startDate} - ${endDate}`;
  };

  // #endregion

  // #region Get Components Functions

  const openSuccessNotification = () => {
    const key = `lock-success-notification-${Date.now()}`;
    const verbPresentTense = isLocking ? 'Lock' : 'Unlock';
    const verbPastTense = verbPresentTense + 'ed';

    const btn = (
      <Button type="primary" size="small" onClick={() => notification.close(key)}>
        Ok
      </Button>
    );

    notification['success']({
      message: `${verbPresentTense} Complete`,
      description: (
        <div>
          Pay period <strong>{getPayPeriodLabel()}</strong> has been successfully{' '}
          {verbPastTense.toLowerCase()}.
        </div>
      ),
      style: { width: 500 },
      btn,
      key,
      placement: 'bottom',
    });
  };

  const openFailureNotification = (errorMessage?: string) => {
    const key = `lock-failure-notification-${Date.now()}`;
    const verb = isLocking ? 'Lock' : 'Unlock';

    const btn = (
      <Button type="primary" size="small" onClick={() => notification.close(key)}>
        Ok
      </Button>
    );

    notification['error']({
      message: `${verb} Failed`,
      description: (
        <div>
          Pay period <strong>{getPayPeriodLabel()}</strong> failed to {verb.toLowerCase()}.{' '}
          <strong>{errorMessage}</strong> If you need further assistance, please notify corporate IT
        </div>
      ),
      style: { width: 500 },
      duration: 0,
      btn,
      key,
      placement: 'bottom',
    });
  };

  // #endregion

  // #region Click Events

  const onCancelClick = () => updateModalVisibility(false);

  const onConfirmClick = async () => {
    if (isLocking) {
      await lockMutation.mutateAsync(
        { payPeriodId: payPeriod.id, lockType: lockType },
        {
          onSuccess: (isSuccessful) => {
            updateModalVisibility(false);
            if (isSuccessful) openSuccessNotification();
            else openFailureNotification();
          },
          onError: (e) => {
            if (e?.response?.data?.errors?.IsLocked)
              openFailureNotification(e.response.data.errors.IsLocked);
            else if (e?.response?.data?.errors?.isCurrentReviewPeriod)
              openFailureNotification(e.response.data.errors.isCurrentReviewPeriod);
            else openFailureNotification();
            updateModalVisibility(false);
          },
        },
      );
    } else {
      await unlockMutation.mutateAsync(
        { payPeriodId: payPeriod.id, lockType: lockType },
        {
          onSuccess: (isSuccessful) => {
            updateModalVisibility(false);
            if (isSuccessful) openSuccessNotification();
            else openFailureNotification();
          },
          onError: (e) => {
            if (e?.response?.data?.errors?.IsLocked)
              openFailureNotification(e.response.data.errors.IsLocked);
            else if (e?.response?.data?.errors?.isCurrentReviewPeriod)
              openFailureNotification(e.response.data.errors.isCurrentReviewPeriod);
            else openFailureNotification();
            updateModalVisibility(false);
          },
        },
      );
    }
  };

  // #endregion

  const verb = isLocking ? 'Lock' : 'Unlock';
  const suffixVerb = isLocking ? 'and prevent' : 'and allow';
  const isLoading = isLocking ? lockMutation.isLoading : unlockMutation.isLoading;

  return (
    <Modal
      okText="Yes"
      okButtonProps={{ loading: isLoading }}
      onCancel={onCancelClick}
      onOk={onConfirmClick}
      title={`Pay Period ${verb} Confirmation`}
      visible={isVisible}
      wrapClassName="payroll-lock-modal"
    >
      <div className="payroll-lock-modal-content">
        Are you sure you want to {verb.toLowerCase()} Pay Period{' '}
        <strong>{getPayPeriodLabel()}</strong> {suffixVerb} further approvals?
      </div>
    </Modal>
  );
}

export default LockConfirmationModal;
