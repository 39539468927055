/* @flow */
export const LockTypes: {
  Commissions: 1,
  Incentives: 2,
} = Object.freeze({
  Commissions: 1,
  Incentives: 2,
});

export type LockTypeEnum = $Values<typeof LockTypes>;
