/* @flow */

import { deleteIncentive } from '../../../services/IncentiveService';
import { useMutation, useQueryClient } from 'react-query';
import CommissionsServiceKeys from '../../../constants/CommissionsServiceKeys';
import type { Incentive } from '../../../models/incentives/Incentive';

type IncentiveDeleteMutationOptions = $ReadOnly<{
  onSuccess?: () => void,
  onError?: (error: any) => void,
}>;

type IncentiveDeleteMutation = $ReadOnly<{
  mutateAsync: (data: Incentive, options?: IncentiveDeleteMutationOptions) => Promise<void>,
  isLoading: boolean,
}>;

export default function useDeleteIncentive(
  excludeUnpublished: boolean = true,
): IncentiveDeleteMutation {
  const queryClient = useQueryClient();
  const cacheKey = `${CommissionsServiceKeys.INCENTIVES}-${String(excludeUnpublished)}`;

  const { mutateAsync, isLoading } = useMutation(
    async (incentive: Incentive) => deleteIncentive(incentive.id),
    {
      onSuccess: async (response, deletedIncentive: Incentive) => {
        await queryClient.cancelQueries(cacheKey);
        const cachedIncentive = queryClient.getQueryData(cacheKey);

        const index = cachedIncentive.findIndex((item) => item.id === deletedIncentive.id);
        if (index >= 0) {
          cachedIncentive.splice(index, 1);
        }

        queryClient.setQueryData(cacheKey, () => cachedIncentive);
        return cachedIncentive;
      },
    },
  );
  return {
    mutateAsync: async (
      data: Incentive,
      options?: IncentiveDeleteMutationOptions,
    ): Promise<void> => {
      try {
        await mutateAsync(data);
        options?.onSuccess?.();
      } catch (e) {
        options?.onError?.(e);
      }
    },
    isLoading,
  };
}
