/* @flow */
import '../../css/corrections-and-adjustments/AdjustmentsTable.css';
import * as React from 'react';
import { Button, Form, Input, Popconfirm, Table } from 'antd';
import { RoleEnum } from '../../enums/RoleEnum';
import { customFormItemValidator } from '../../utils/formValidators';
import { find, flatMap, isNil, map, orderBy, uniq } from 'lodash';
import { formatAdjustmentType } from '../../utils/enumFormatter';
import { formatNumberAsMoney, formatTeamMemberships } from '../../utils/formatter';
import { useState } from 'react';
import AdjustmentTypeSelector from '../../components/shared/AdjustmentTypeSelector';
import AgentSelector from '../../components/shared/AgentSelector';
import DashboardLink from '../shared/DashboardLink';
import TooltipInputNumber from '../shared/TooltipInputNumber';
import useCreateAdjustment from '../../hooks/services/adjustment/useCreateAdjustment';
import useDeleteAdjustment from '../../hooks/services/adjustment/useDeleteAdjustment';
import useMyRole from '../../hooks/services/role/useMyRole';
import usePayPeriodQueryState from '../../hooks/usePayPeriodQueryState';
import usePayPeriods from '../../hooks/services/pay-period/usePayPeriods';
import useUpdateAdjustment from '../../hooks/services/adjustment/useUpdateAdjustment';
import type { Adjustment } from '../../models/Adjustment';

const { TextArea } = Input;
const AMOUNT_ERROR_MESSAGE = 'Amount cannot be empty or zero';
const AGENT_ERROR_MESSAGE = 'Agent is required';

type Props = $ReadOnly<{
  adjustments: ?Array<Adjustment>,
  payPeriodId: number,
}>;

function AdjustmentsTable(props: Props): React.Node {
  const [form] = Form.useForm();
  const { adjustments, payPeriodId } = props;
  const myRole = useMyRole();

  const payPeriodQueryState = usePayPeriodQueryState();
  const payPeriods = usePayPeriods();
  const selectedPayPeriod = find(
    payPeriods,
    (payPeriod) => payPeriod.id === payPeriodQueryState.payPeriodId,
  );
  const isPayPeriodLocked = selectedPayPeriod?.isLocked ?? false;

  // States
  const [editingKey, setEditingKey] = useState(null);
  const [newRow, setNewRow] = useState(null);
  const [isErrorAgent, setIsErrorAgent] = useState(false);
  const [isErrorAmount, setIsErrorAmount] = useState(false);
  const [mutationErrorMessage, setMutationErrorMessage] = useState(null);

  //Mutations
  const createMutation = useCreateAdjustment();
  const updateMutation = useUpdateAdjustment();
  const deleteMutation = useDeleteAdjustment();

  // #region "Helper Functions"

  const isNewRow = (row: Adjustment): boolean => isNil(row) || row.id <= 0;

  const isEditingRow = (row: Adjustment, editingKey: ?number): boolean => row.id === editingKey;

  const isInCreateMode = (): boolean => editingKey === 0;

  const isInEditMode = (): boolean => !isNil(editingKey) && parseInt(editingKey) > 0;

  const userHasWriteAccess = () =>
    (myRole?.role.name === RoleEnum.Admin ||
      myRole?.role.name === RoleEnum.RetentionManager ||
      myRole?.role.name === RoleEnum.Manager) &&
    !isPayPeriodLocked;

  // #endregion

  // #region "Get Table Components"

  const getColumns = (): any => {
    return [
      {
        title: 'Rep',
        dataIndex: 'agentFullName',
        editable: true,
        sorter: (a, b) => a.agentFullName.localeCompare(b.agentFullName),
        render: (value, row: Adjustment) => {
          return isNewRow(row) ? (
            <Form.Item
              name="agent"
              rules={[
                {
                  validator: (_, value) =>
                    customFormItemValidator(!isNil(value), AGENT_ERROR_MESSAGE, setIsErrorAgent),
                },
              ]}
            >
              {!isNil(payPeriodId) && payPeriodId > 0 && (
                <AgentSelector
                  useImpersonation={true}
                  tooltipErrorMessage={AGENT_ERROR_MESSAGE}
                  showErrorMessage={isErrorAgent}
                  showStatus={false}
                  showExcludeInactiveCheckbox={false}
                  defaultExcludeInactive={false}
                  payPeriodId={payPeriodId}
                  useTeamGrouping={true}
                />
              )}
            </Form.Item>
          ) : (
            <DashboardLink agentId={row.agentId ?? 0} payPeriodId={payPeriodId}>
              {value}
            </DashboardLink>
          );
        },
        filterSearch: true,
        filters: map(
          orderBy(uniq(map(adjustments, (adjustment) => adjustment.agentFullName))),
          (item) => {
            return { text: item, value: item };
          },
        ),
        onFilter: (value, record) => record.agentFullName?.includes(value),
        width: 215,
      },
      {
        title: 'Team',
        dataIndex: 'teamMembership',
        editable: true,
        filterSearch: true,
        filters: map(
          orderBy(
            uniq(
              flatMap(adjustments, (adjustment) =>
                adjustment.agent?.teamMemberships?.map((x) => x.team?.teamName),
              ),
            ),
          ),
          (item) => {
            return { text: item, value: item };
          },
        ),
        onFilter: (value, record) =>
          record.agent?.teamMemberships?.some((x) => x.team?.teamName === value),
        sorter: (a, b) =>
          formatTeamMemberships(a.agent?.teamMemberships).localeCompare(
            formatTeamMemberships(b.agent?.teamMemberships),
          ),
        render: (value: ?any, row: Adjustment) =>
          formatTeamMemberships(row.agent?.teamMemberships || []),
        width: 215,
      },
      {
        title: 'Type',
        dataIndex: 'adjustmentType',
        editable: true,
        render: (_, row: Adjustment) => {
          return isNewRow(row) || isEditingRow(row, editingKey) ? (
            <Form.Item name="adjustmentType">
              <AdjustmentTypeSelector />
            </Form.Item>
          ) : (
            formatAdjustmentType(row.adjustmentType)
          );
        },
        sorter: (a, b) =>
          formatAdjustmentType(a.adjustmentType).localeCompare(
            formatAdjustmentType(b.adjustmentType),
          ),
        width: 215,
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        editable: true,
        render: (_, row: Adjustment) => {
          return isEditingRow(row, editingKey) ? (
            <Form.Item
              name="amount"
              rules={[
                {
                  validator: (_, value) =>
                    customFormItemValidator(
                      !isNil(value) && value !== 0,
                      AMOUNT_ERROR_MESSAGE,
                      setIsErrorAmount,
                    ),
                },
              ]}
            >
              <TooltipInputNumber
                tooltipErrorMessage={AMOUNT_ERROR_MESSAGE}
                showErrorMessage={isErrorAmount}
              />
            </Form.Item>
          ) : (
            formatNumberAsMoney(row.amount, true)
          );
        },
        sorter: (a, b) => a.amount - b.amount,
        align: 'right',
        width: 115,
      },
      {
        title: 'Notes',
        dataIndex: 'notes',
        editable: true,
        render: (_, row: Adjustment) => {
          return isEditingRow(row, editingKey) ? (
            <Form.Item
              name="notes"
              rules={[
                {
                  required: false,
                  whitespace: true,
                  type: 'string',
                },
              ]}
            >
              <TextArea
                placeholder={'Enter notes'}
                maxLength={3000}
                autoSize={{ minRows: 1, maxRows: 15 }}
                showCount={true}
              />
            </Form.Item>
          ) : (
            <span>{row.notes}</span>
          );
        },
      },
      {
        title: 'Actions',
        dataIndex: '',
        key: 'actions',
        render: (_, row: Adjustment) => {
          return isEditingRow(row, editingKey)
            ? getEditingActionCell(row)
            : getEditableActionCell(row);
        },
        width: 200,
      },
    ];
  };

  const getRows = (): Array<Adjustment> => {
    if (!adjustments && !newRow) {
      return [];
    }

    const rows = adjustments ?? [];
    if (newRow) {
      return [...rows, newRow];
    }

    return [...rows];
  };

  const getFooter = (): React.Node => {
    const totalNumberOfRecords = adjustments ? adjustments.length : 0;

    return (
      <div className="corrections-and-adjustments-footer">
        <div className="corrections-and-adjustments-footer-cell">
          {userHasWriteAccess() && (
            <Button
              type="primary"
              disabled={isInCreateMode() || isInEditMode()}
              onClick={() => onCreateClick()}
            >
              Create New Record
            </Button>
          )}
        </div>
        <div className="corrections-and-adjustments-footer-cell adjustments-footer-total">
          <span className="adjustments-footer-total-label">Total Records:&nbsp;</span>
          {totalNumberOfRecords}
        </div>
      </div>
    );
  };

  // #endregion

  // #region "Get Cell Components"

  const getEditableActionCell = (row: Adjustment): React.Node => {
    return (
      <div className="corrections-and-adjustments-action-cell">
        <div className="corrections-and-adjustments-action-cell-buttons">
          <Button
            type="primary"
            disabled={!userHasWriteAccess() || isInEditMode() || isInCreateMode()}
            onClick={() => onEditClick(row)}
          >
            Edit
          </Button>
          <Popconfirm
            title="Are you sure you want to delete this record?"
            disabled={isInEditMode()}
            onConfirm={() => onDeleteClick(row)}
          >
            <Button
              type="primary"
              disabled={!userHasWriteAccess() || isInEditMode() || isInCreateMode()}
            >
              Delete
            </Button>
          </Popconfirm>
        </div>
        {getErrorMessage(row)}
      </div>
    );
  };

  const getEditingActionCell = (row: Adjustment): React.Node => {
    const isLoading = updateMutation.isLoading || createMutation.isLoading;

    return (
      <div className="corrections-and-adjustments-action-cell">
        <div className="corrections-and-adjustments-action-cell-buttons">
          <Button type="primary" loading={isLoading} onClick={() => onSaveClick(row)}>
            Save
          </Button>
          <Button type="primary" disabled={isLoading} onClick={() => onCancelClick()}>
            Cancel
          </Button>
        </div>
        {getErrorMessage(row)}
      </div>
    );
  };

  const getErrorMessage = (row: Adjustment): React.Node =>
    !isNil(mutationErrorMessage) &&
    (isNewRow(row) || isEditingRow(row, editingKey)) && (
      <div className="corrections-and-adjustments-action-cell-error-message">
        {mutationErrorMessage}
      </div>
    );

  // #endregion

  // #region "OnClick Events"

  const onCreateClick = () => {
    const newAdjustment: Adjustment = {
      id: 0,
      agentCommissionId: 0,
      agentId: 0,
      agentFullName: '',
      adjustmentType: 8,
      amount: 0,
      notes: '',
      payPeriodId,
      agent: undefined,
    };

    form.setFieldsValue({ agent: null, adjustmentType: 8, amount: 0, notes: '' });
    setNewRow(newAdjustment);
    setEditingKey(0);
  };

  const onEditClick = (row: Adjustment) => {
    form.setFieldsValue({
      adjustmentType: row.adjustmentType,
      amount: row.amount,
      notes: row.notes,
    });
    setEditingKey(row.id);
  };

  const onSaveClick = async (row: Adjustment) => {
    const { payPeriodId } = props;
    const editableRowFieldValues = await form.validateFields();

    if (isInCreateMode()) {
      await createMutation.mutateAsync(
        {
          id: 0,
          agentCommissionId: 0,
          agentId: editableRowFieldValues.agent.id,
          payPeriodId: payPeriodId,
          adjustmentType: editableRowFieldValues.adjustmentType,
          amount: editableRowFieldValues.amount,
          notes: editableRowFieldValues.notes,
        },
        {
          onSuccess: () => {
            setNewRow(null);
            setEditingKey(null);
            setMutationErrorMessage(null);
          },
          onError: (error) => {
            setMutationErrorMessage(
              'This record failed to be created. ' +
                (error.response?.data.message ?? error.message),
            );
          },
        },
      );
      return;
    }

    if (isInEditMode()) {
      if (!adjustments) {
        return;
      }
      const index = adjustments.findIndex((item) => item['id'] === row.id);
      const adjustment = {
        ...adjustments[index],
        adjustmentType: editableRowFieldValues.adjustmentType,
        amount: editableRowFieldValues.amount,
        notes: editableRowFieldValues.notes,
      };

      await updateMutation.mutateAsync(adjustment, {
        onSuccess: () => {
          setEditingKey(null);
          setMutationErrorMessage(null);
        },
        onError: (error) => {
          setMutationErrorMessage(
            'This record failed to be updated. ' + (error.response?.data.message ?? error.message),
          );
        },
      });
    }
  };

  const onCancelClick = () => {
    setNewRow(null);
    setEditingKey(null);
    setIsErrorAmount(false);
    setIsErrorAgent(false);
    setMutationErrorMessage(null);
  };

  const onDeleteClick = async (row: Adjustment) => {
    await deleteMutation.mutateAsync(row, {
      onSuccess: () => {
        setMutationErrorMessage(null);
      },
      onError: (error) => {
        setEditingKey(row.id);
        setMutationErrorMessage(
          'This record failed to be deleted. ' + (error.response?.data.message ?? error.message),
        );
      },
    });
  };

  // #endregion

  return (
    <Form form={form} component={false}>
      <Table
        className="adjustments-table"
        bordered
        columns={getColumns()}
        dataSource={getRows()}
        size="small"
        pagination={false}
        scroll={{ y: 490 }}
        footer={getFooter}
        rowKey="id"
      />
    </Form>
  );
}

export default AdjustmentsTable;
