/* @flow */
import '../../css/incentives/SortableItem.css';
import * as React from 'react';
import { Button, Form, Space } from 'antd';
import { CSS } from '@dnd-kit/utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IncentiveStatuses } from '../../enums/IncentiveStatusEnum';
import { faCheckCircle, faGripVertical, faMinus } from '@fortawesome/free-solid-svg-icons';
import { formatFieldTypes } from '../../utils/enumFormatter';
import { useSortable } from '@dnd-kit/sortable';
import type { IncentiveField } from '../../models/incentives/IncentiveField';
import type { IncentiveStatusEnum } from '../../enums/IncentiveStatusEnum';

type Props = $ReadOnly<{
  field: IncentiveField,
  incentiveStatus: ?IncentiveStatusEnum,
  remove: (id: number) => void,
}>;

export function SortableItem(props: Props): React.Node {
  const { field, remove, incentiveStatus } = props;
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: field.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  function Multiplier(multiplier: boolean) {
    if (multiplier) {
      return (
        <div>
          <FontAwesomeIcon icon={faCheckCircle} />
          <span>Multiplier</span>
        </div>
      );
    }
  }

  return (
    <div style={style} {...attributes}>
      <Space className="container">
        <Form.Item>
          <span className="cursor" ref={setNodeRef} {...listeners}>
            <FontAwesomeIcon icon={faGripVertical} />
          </span>
        </Form.Item>
        <div className="added-incentive-field">
          <Form.Item>
            <span>
              <strong>Name: </strong>
            </span>
            <span>{field.name} </span>
          </Form.Item>
          <Form.Item>
            <span className="type">
              <strong> Type: </strong>
            </span>
            <span>{formatFieldTypes(field.incentiveFieldType)}</span>
          </Form.Item>
        </div>
        <div className="is-multiplier">{Multiplier(field.isMultiplier)}</div>
        <Button type="dashed" onClick={() => remove(field.id)}>
          {incentiveStatus !== IncentiveStatuses.Published &&
            incentiveStatus !== IncentiveStatuses.Unpublished && <FontAwesomeIcon icon={faMinus} />}
        </Button>
      </Space>
    </div>
  );
}
