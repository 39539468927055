/* @flow */
import * as React from 'react';
import { Link } from 'react-router-dom';
import PATHS from '../../constants/paths';
import useMyRole from '../../hooks/services/role/useMyRole';

function NoPermissions(): React.Node {
  const role = useMyRole();
  return role ? (
    <div>
      You do not have permissions to see this. <Link to={PATHS.DASHBOARD}>Click here</Link> to go
      back to homepage.
    </div>
  ) : null;
}

export default NoPermissions;
