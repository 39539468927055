/* @flow */
import '../../css/pay-period-review/TeamSummaryWidget.css';
import * as React from 'react';
import { Button, Collapse, Empty, Statistic, Tooltip } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { ImpersonationContext } from '../../context/ImpersonationContext';
import { LockTypes } from '../../enums/LockTypeEnum';
import {
  type TeamCommissionTypeEnum,
  TeamCommissionTypes,
} from '../../enums/TeamCommissionTypeEnum';
import { assign, find, map, orderBy, reduce, size } from 'lodash';
import { formatNumberAsMoney } from '../../utils/formatter';
import { useCallback, useContext, useState } from 'react';
import ApprovalProgressBar from '../shared/ApprovalProgressBar';
import ApproveConfirmationModal from './ApproveConfirmationModal';
import TeamSummaryTable from './TeamSummaryTable';
import useMyRole from '../../hooks/services/role/useMyRole';
import type { AgentCommissionReview } from '../../models/reviews/AgentCommissionReview';
import type { ManagerAgent } from '../../models/ManagerAgent';
import type { PayPeriod } from '../../models/PayPeriod';
import type { TeamReviewSummary } from '../../models/reviews/TeamReviewSummary';

const { Panel } = Collapse;

type Props = $ReadOnly<{
  summary: ?TeamReviewSummary,
  payPeriod: PayPeriod,
  selectedRows: ?Array<AgentCommissionReview>,
  setSelectedRowsCallback: ({ teamId: string, records: Array<any> }) => void,
}>;

function TeamSummaryWidget(props: Props): React.Node {
  const impersonation = useContext(ImpersonationContext);
  const isImpersonated = impersonation.agent !== null;

  const { payPeriod, summary, selectedRows, setSelectedRowsCallback } = props;
  // States
  const [approveSelectedModalIsVisible, setApproveSelectedModalIsVisible] = useState(false);
  const [approveModalTeamName, setApproveModalTeamName] = useState('');
  const myRole = useMyRole();

  // Callbacks
  const setApproveSelectedModalIsVisibleCallback = useCallback(
    (value) => setApproveSelectedModalIsVisible(value),
    [],
  );
  const setApproveModalTeamNameCallback = useCallback(
    (value) => setApproveModalTeamName(value),
    [],
  );

  // Functions
  const userHasWriteAccess = () => !myRole?.hasReadOnlyAccess;

  const getSelectedApprovalRecordsForModal = (rows: ?Array<AgentCommissionReview>) => {
    return map(rows, (row: AgentCommissionReview) => {
      const isSelected = find(
        selectedRows,
        (selectedRow: AgentCommissionReview) =>
          selectedRow.agentCommissionId === row.agentCommissionId,
      );
      return assign({ isSelected }, row);
    });
  };

  const getTableConfigByCommissionType = (
    commissionType: ?TeamCommissionTypeEnum,
  ): Array<string> => {
    switch (commissionType) {
      case TeamCommissionTypes.Sales:
      case TeamCommissionTypes.SalesAcceleration:
      case TeamCommissionTypes.Onboarding:
      case TeamCommissionTypes.Takeover:
        return getSalesTableColumnConfig();
      case TeamCommissionTypes.Retention:
      case TeamCommissionTypes.RetentionFlatForce:
        return getRetentionTableColumnConfig();
      case TeamCommissionTypes.SalesDevelopment:
        return getSalesDevelopmentTableColumnConfig();
      default:
        return getDefaultTableColumnConfig();
    }
  };

  const getSalesTableColumnConfig = (): Array<string> => {
    return [
      'rep',
      'howardSimonId',
      'hireDate',
      'terminationDate',
      'directMailClosingRatioPercentage',
      'digitalInboundClosingRatioPercentage',
      'digitalOutboundClosingRatioPercentage',
      'tvClosingRatioPercentage',
      'directMailEarnedTotal',
      'digitalEarnedTotal',
      'tvEarnedTotal',
      'otherEarnedTotal',
      'adjustments',
      'earnedTotal',
    ];
  };

  const getSalesDevelopmentTableColumnConfig = (): Array<string> => {
    return [
      'rep',
      'howardSimonId',
      'hireDate',
      'terminationDate',
      'salesDevelopmentEarnedTotal',
      'otherEarnedTotal',
      'adjustments',
      'earnedTotal',
    ];
  };

  const getRetentionTableColumnConfig = (): Array<string> => {
    return [
      'rep',
      'howardSimonId',
      'hireDate',
      'terminationDate',
      'retentionEarnedTotal',
      'retentionFlatForceEarnedTotal',
      'otherEarnedTotal',
      'adjustments',
      'earnedTotal',
    ];
  };

  const getDefaultTableColumnConfig = (): Array<string> => {
    return ['rep', 'howardSimonId', 'hireDate', 'terminationDate', 'adjustments', 'earnedTotal'];
  };

  const getManagersStatisticValue = (managers: ?Array<ManagerAgent>): string => {
    if (size(managers) === 0) return 'N/A';

    return reduce(
      orderBy(managers, (manager) => manager.name),
      (result, manager) => {
        const newline = result !== '' ? '\n' : '';
        return `${result}${newline}${manager.name}`;
      },
      '',
    );
  };

  const getApproveButtonTooltipText = (
    countOfSelected: number,
    isLocked: boolean,
    isImpersonated: boolean,
  ): string => {
    if (isLocked) return 'Cannot approve when a period is locked';
    if (isImpersonated) return 'Cannot approve while viewing as another user';
    if (countOfSelected === 0) return 'Select records first before approving';
    return 'Approve selected records';
  };

  const getWidgetHeaderContent = (props: Props): React.Node => {
    const { summary, payPeriod, selectedRows } = props;
    const isLocked =
      payPeriod && payPeriod.payPeriodLocks
        ? payPeriod.payPeriodLocks.some((x) => x.lockType === LockTypes.Commissions)
        : true;
    const approveTooltipText = getApproveButtonTooltipText(
      size(selectedRows),
      isLocked,
      isImpersonated,
    );

    return (
      <>
        <div className="commission-reviews-table-summary-main-content">
          <Statistic
            className="commission-reviews-table-summary-team-statistic team-name-statistic"
            title="Team"
            value={summary?.teamName}
          />
          <Statistic
            className="commission-reviews-table-summary-team-statistic manager-name-statistic"
            title="Managers"
            value={getManagersStatisticValue(summary?.managers)}
          />
          <Statistic
            className="commission-reviews-table-summary-statistic total-commission-statistic"
            title="Total Commission"
            value={formatNumberAsMoney(summary?.teamSummary.totalEarnedCommission, true)}
          />
          <Statistic
            className="commission-reviews-table-summary-statistic agent-count-statistic"
            title="Agents"
            value={summary?.teamSummary.numberOfAgents}
          />
          <div className="commission-reviews-table-summary-content-approved">
            <ApprovalProgressBar
              countOfApproved={summary?.teamSummary.numberOfAgentsApproved ?? 0}
              countOfSelectedForApproval={size(selectedRows)}
              countOfTotal={summary?.teamSummary.numberOfAgents ?? 0}
              showTitle={false}
              showSubProgressBar={false}
              title={`${summary?.teamSummary.numberOfAgentsApproved ?? 0} approved`}
              type="dashboard"
              width={80}
            />
          </div>
          <div className="commission-reviews-table-summary-button-container">
            <Tooltip title={approveTooltipText}>
              <Button
                className="commission-reviews-table-summary-button"
                disabled={!userHasWriteAccess() || size(selectedRows) === 0 || isLocked}
                icon={<CheckCircleOutlined />}
                onClick={(event) => {
                  event.stopPropagation();
                  setApproveSelectedModalIsVisibleCallback(true);
                  setApproveModalTeamNameCallback(summary?.teamName);
                }}
                type="primary"
              ></Button>
            </Tooltip>
          </div>
        </div>
      </>
    );
  };

  const getTableContent = (summary: ?TeamReviewSummary, payPeriod: PayPeriod): React.Node => {
    if (!summary || !summary.teamSummary || summary.teamSummary.numberOfAgents === 0) {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>;
    }

    return (
      <div className="commission-reviews-table-summary-expanded-section">
        <TeamSummaryTable
          reviews={summary.commissionReviews}
          payPeriod={payPeriod}
          selectedRows={selectedRows}
          setSelectedRowsCallback={setSelectedRowsCallback}
          columnConfig={getTableConfigByCommissionType(summary.teamCommissionType)}
          teamId={summary.teamId.toString()}
        />
      </div>
    );
  };

  if (!summary) return null;

  return (
    <>
      <div className="commission-reviews-table-summary-container">
        <Collapse expandIconPosition="start">
          <Panel key="1" extra={getWidgetHeaderContent(props)}>
            {getTableContent(summary, payPeriod)}
          </Panel>
        </Collapse>
      </div>
      <ApproveConfirmationModal
        isVisible={approveSelectedModalIsVisible}
        payPeriod={payPeriod}
        onModalFinish={() =>
          setSelectedRowsCallback({ teamId: summary.teamId.toString(), records: [] })
        }
        reviews={getSelectedApprovalRecordsForModal(summary.commissionReviews)}
        updateModalVisibility={setApproveSelectedModalIsVisibleCallback}
        teamName={approveModalTeamName ?? ''}
      />
    </>
  );
}

export default TeamSummaryWidget;
