/* @flow */
import { getPayPeriods } from '../../../services/PayPeriodService';
import { useQuery } from 'react-query';
import CommissionServiceKeys from '../../../constants/CommissionsServiceKeys';
import useQueryCacheKey from '../../useQueryCacheKey';
import type { PayPeriod } from '../../../models/PayPeriod';

const CACHE_TIME = 60 * 60 * 1000; // One hour

function usePayPeriods(): ?Array<PayPeriod> {
  const cacheKey = useQueryCacheKey(CommissionServiceKeys.PAY_PERIODS);

  const { isFetching, isError, data } = useQuery(cacheKey, getPayPeriods, {
    cacheTime: CACHE_TIME,
    staleTime: CACHE_TIME,
  });
  if (isFetching) {
    return null;
  }

  if (isError) {
    return [];
  }

  return data;
}

export default usePayPeriods;
