/* @flow */
import '../../css/search/SearchResultsTable.css';
import * as React from 'react';
import { DealCommissionTypes } from '../../enums/DealCommissionTypeEnum';
import { Table } from 'antd';
import { findIndex, head, isEmpty, nth, pull } from 'lodash';
import {
  formatAgentSaleRoleType,
  formatRetentionCommissionStatus,
  formatSalesCommissionStatus,
  formatSalesDevelopmentCommissionStatus,
} from '../../utils/enumFormatter';
import { formatDateAsShortDate, formatNumberAsMoney } from '../../utils/formatter';
import { useEffect, useState } from 'react';
import DashboardLink from '../shared/DashboardLink';
import SearchDetails from './SearchDetails';
import type { SearchResult } from '../../models/SearchResult';

type Props = $ReadOnly<{
  searchResults: ?Array<SearchResult>,
  minScrollHeight?: number,
  minScrollWidth?: number,
  expandRelevantRow?: boolean,
  payPeriodId?: ?number,
  agentId?: ?number,
}>;

SearchResultsTable.defaultProps = {
  minScrollWidth: 1000,
  expandPayPeriodRow: false,
};

function SearchResultsTable({
  searchResults,
  minScrollHeight,
  minScrollWidth,
  expandRelevantRow,
  payPeriodId,
  agentId,
}: Props): React.Node {
  const [expandedRowKeys, setExpandedRowKeys] = useState(
    findDefaultExpandedRowKeys(expandRelevantRow ?? false, searchResults, payPeriodId, agentId),
  );

  useEffect(() => {
    setExpandedRowKeys(
      findDefaultExpandedRowKeys(expandRelevantRow ?? false, searchResults, payPeriodId, agentId),
    );
  }, [agentId, expandRelevantRow, searchResults, payPeriodId]);

  return (
    <Table
      className="search-results-table-root"
      bordered
      columns={getColumns()}
      dataSource={searchResults}
      size="small"
      pagination={false}
      expandable={{
        expandRowByClick: true,
        expandedRowKeys,
        expandedRowRender,
        onExpand: (expanded: boolean, record: SearchResult) => {
          const newExpandedRowKeys = expandedRowKeys;
          if (!expanded) {
            pull(newExpandedRowKeys, record.id);
            setExpandedRowKeys(newExpandedRowKeys);
          } else {
            newExpandedRowKeys.push(record.id);
            setExpandedRowKeys(newExpandedRowKeys);
          }
        },
      }}
      scroll={{ y: minScrollHeight, x: minScrollWidth }}
      rowKey={(searchResult) => searchResult.id}
    />
  );
}

function expandedRowRender(record: SearchResult): React.Node {
  return (
    <div className="search-results-table-details">
      <SearchDetails searchResult={record} />
    </div>
  );
}

function getColumns(): any {
  return [
    {
      title: 'Commission Period',
      dataIndex: 'payPeriod',
      editable: false,
      render: (value) => {
        const startDate = formatDateAsShortDate(value.startDate);
        const endDate = formatDateAsShortDate(value.endDate);
        return `${startDate} - ${endDate}`;
      },
      sorter: (a, b) => new Date(a.payPeriod.startDate) - new Date(b.payPeriod.startDate),
      defaultSortOrder: 'ascend',
      width: 200,
    },
    {
      title: 'Agent',
      dataIndex: 'agentFullName',
      editable: false,
      render: (value, record) => (
        <DashboardLink agentId={record.agentId} payPeriodId={record.payPeriod.id}>
          {value}
        </DashboardLink>
      ),
      sorter: (a, b) => a.agentFullName.localeCompare(b.agentFullName),
    },
    {
      title: 'Commission',
      dataIndex: 'agentSaleRole',
      editable: false,
      render: (value, record) => getCommissionTypeLabel(value, record),
      sorter: (a, b) => {
        const firstValue = getCommissionTypeLabel(a.agentSaleRole, a);
        const secondValue = getCommissionTypeLabel(b.agentSaleRole, b);
        return firstValue.localeCompare(secondValue);
      },
    },
    {
      title: 'Status',
      dataIndex: 'salesCommissionStatus' || 'salesDevelopmentCommissionStatus',
      editable: false,
      render: (value, record) => getStatusLabel(value, record),
      sorter: (a, b) => {
        const firstValue = getStatusLabel(a.salesCommissionStatus, a);
        const secondValue = getStatusLabel(b.salesCommissionStatus, b);
        return firstValue.localeCompare(secondValue);
      },
    },
    {
      title: 'Sold Date',
      dataIndex: 'soldDate',
      editable: false,
      render: (value) => formatDateAsShortDate(value, true, true),
      sorter: (a, b) => new Date(a.soldDate) - new Date(b.soldDate),
    },
    {
      title: 'Canceled Date',
      dataIndex: 'cancelledDate',
      editable: false,
      render: (value) => formatDateAsShortDate(value, true, true),
      sorter: (a, b) => new Date(a.cancelledDate) - new Date(b.cancelledDate),
    },
    {
      title: 'Earned Amount',
      dataIndex: 'earnedTotal',
      editable: false,
      render: (value) => formatNumberAsMoney(value, true),
      sorter: (a, b) => a.earnedTotal - b.earnedTotal,
    },
    {
      title: 'Customer Number',
      dataIndex: 'moxyCustomerNumber',
      editable: false,
      sorter: (a, b) => a.moxyCustomerNumber.localeCompare(b.moxyCustomerNumber),
    },
    {
      title: 'Contract Number',
      dataIndex: 'contractNumber',
      editable: false,
      sorter: (a, b) => a.contractNumber.localeCompare(b.contractNumber),
    },
  ];
}

function getCommissionTypeLabel(value, record): string {
  switch (record.commissionType) {
    case DealCommissionTypes.Sales:
      return formatAgentSaleRoleType(value);
    case DealCommissionTypes.Retention:
      return 'Retention';
    case DealCommissionTypes.SalesDevelopment:
      return 'Junior';
    default:
      return 'Unknown';
  }
}

function getStatusLabel(value, record): string {
  switch (record.commissionType) {
    case DealCommissionTypes.Sales:
      return formatSalesCommissionStatus(value, record.soldDetails.isFullPay);
    case DealCommissionTypes.Retention:
      return formatRetentionCommissionStatus(record.retentionCommissionStatus);
    case DealCommissionTypes.SalesDevelopment:
      return formatSalesDevelopmentCommissionStatus(record.salesDevelopmentCommissionStatus);
    default:
      return 'Unknown';
  }
}

function findDefaultExpandedRowKeys(
  expandRelevantRow: boolean,
  searchResults: ?Array<SearchResult>,
  payPeriodId: ?number,
  agentId: ?number,
): Array<number> {
  if (!expandRelevantRow) {
    return [];
  }

  if (isEmpty(searchResults)) {
    return [];
  }

  const defaultExpandedRowKeys = [head(searchResults).id];
  const currentPayPeriodId = payPeriodId ?? 0;
  const currentAgentId = agentId ?? 0;
  if (currentPayPeriodId === 0 || currentAgentId === 0) {
    return defaultExpandedRowKeys;
  }

  const expandedRowKeyIndex: number = findIndex(
    searchResults,
    (result) => result.payPeriod.id === currentPayPeriodId && result.agentId === currentAgentId,
  );

  return expandedRowKeyIndex >= 0
    ? [nth(searchResults, expandedRowKeyIndex).id]
    : defaultExpandedRowKeys;
}

export default SearchResultsTable;
