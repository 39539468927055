/* @flow */
import '../../css/incentives/IncentiveSummaryWidget.css';

import * as React from 'react';
import { Button, Collapse, Statistic, Tooltip } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { RoleEnum } from '../../enums/RoleEnum';
import { assign, find, map, orderBy, reduce, size } from 'lodash';
import { formatNumberAsMoney } from '../../utils/formatter';
import { useCallback, useState } from 'react';
import ApprovalProgressBar from '../shared/ApprovalProgressBar';
import ApproveConfirmationModal from './ApproveConfirmationModal';
import IncentiveTable from './AgentIncentivesTable';
import useMyRole from '../../hooks/services/role/useMyRole';
import type { AgentIncentive } from '../../models/incentives/AgentIncentive';
import type { Incentive } from '../../models/incentives/Incentive';
import type { ManagerAgent } from '../../models/ManagerAgent';
import type { PayPeriod } from '../../models/PayPeriod';
import type { RoleAccess } from '../../models/RoleAccess';
import type { TeamIncentivesReview } from '../../models/incentives/TeamIncentivesReview';

const { Panel } = Collapse;

type Props = $ReadOnly<{
  payPeriod: PayPeriod,
  teamIncentives: TeamIncentivesReview,
  incentives: ?Array<Incentive>,
  setModal: (isOpen: boolean, agentIncentive: ?AgentIncentive) => void,
  selectedRows: ?Array<AgentIncentive>,
  setSelectedRowsCallback: ({ teamId: string, records: Array<any> }) => void,
  roles: ?RoleAccess,
}>;

function IncentiveSummaryWidget(props: Props): React.Node {
  const {
    payPeriod,
    teamIncentives,
    incentives,
    setModal,
    selectedRows,
    setSelectedRowsCallback,
    roles,
  } = props;

  const [approveSelectedModalIsVisible, setApproveSelectedModalIsVisible] = useState(false);
  const [approveModalTeamName, setApproveModalTeamName] = useState('');
  const myRole = useMyRole();

  // Callbacks
  const setApproveSelectedModalIsVisibleCallback = useCallback(
    (value) => setApproveSelectedModalIsVisible(value),
    [],
  );
  const setApproveModalTeamNameCallback = useCallback(
    (value) => setApproveModalTeamName(value),
    [],
  );

  // Functions
  const userHasApprovalAccess = () =>
    myRole?.role.name === RoleEnum.Admin || myRole?.role.name === RoleEnum.Manager;

  const getSelectedApprovalRecordsForModal = (rows: ?Array<AgentIncentive>) => {
    return map(rows, (row: AgentIncentive) => {
      const isSelected = find(
        selectedRows,
        (selectedRow: AgentIncentive) => selectedRow.id === row.id,
      );
      return assign({ isSelected }, row);
    });
  };

  const getWidgetHeaderContent = (teamName: string, managers: ?Array<ManagerAgent>): React.Node => {
    return (
      <>
        <div className="incentive-reviews-table-summary-main-content">
          <Statistic
            className="incentive-reviews-table-summary-team-statistic team-name-statistic"
            title="Team"
            value={teamName}
          />
          <Statistic
            className="incentive-reviews-table-summary-team-statistic manager-name-statistic"
            title="Managers"
            value={getManagersStatisticValue(managers)}
          />
          <Statistic
            className="incentive-reviews-table-summary-statistic total-incentive-statistic"
            title="Total Incentives"
            value={formatNumberAsMoney(
              teamIncentives.teamIncentivesSummary.totalIncentivesAmount,
              true,
            )}
          />
          <Statistic
            className="incentive-reviews-table-summary-statistic agent-count-statistic"
            title="Incentives"
            value={teamIncentives.teamIncentivesSummary.numberOfAgents}
          />
          <div className="approval-progress">
            <ApprovalProgressBar
              countOfTotal={teamIncentives.teamIncentivesSummary.numberOfAgents ?? 0}
              countOfApproved={teamIncentives.teamIncentivesSummary.numberOfAgentsApproved ?? 0}
              countOfSelectedForApproval={size(selectedRows)}
              showSubProgressBar={false}
              width={80}
            />
          </div>
          <div className="incentive-reviews-table-summary-button-container">
            <Tooltip>
              {userHasApprovalAccess() && (
                <Button
                  className="commission-reviews-table-summary-button"
                  disabled={size(selectedRows) === 0}
                  icon={<CheckCircleOutlined />}
                  onClick={(event) => {
                    event.stopPropagation();
                    setApproveSelectedModalIsVisibleCallback(true);
                    setApproveModalTeamNameCallback(teamIncentives.teamName);
                  }}
                  type="primary"
                ></Button>
              )}
            </Tooltip>
          </div>
        </div>
      </>
    );
  };

  const getTableContent = (
    payPeriodId: number,
    teamIncentivesReview: TeamIncentivesReview,
    incentives: ?Array<Incentive>,
  ): React.Node => {
    return (
      <IncentiveTable
        className="incentive-table"
        key={'incentivesTableSection'}
        incentives={incentives}
        agentIncentives={teamIncentivesReview.agentIncentives}
        payPeriod={payPeriod}
        setModal={setModal}
        teamId={teamIncentivesReview.teamId}
        selectedRows={selectedRows}
        setSelectedRowsCallback={setSelectedRowsCallback}
        roles={roles}
      />
    );
  };

  const getManagersStatisticValue = (managers: ?Array<ManagerAgent>): string => {
    if (size(managers) === 0) return 'N/A';

    return reduce(
      orderBy(managers, (manager) => manager.name),
      (result, manager) => {
        const newline = result !== '' ? '\n' : '';
        return `${result}${newline}${manager.name}`;
      },
      '',
    );
  };

  return (
    <>
      <div className="incentive-reviews-table-summary-container">
        <Collapse expandIconPosition="start">
          <Panel
            key="1"
            extra={getWidgetHeaderContent(teamIncentives.teamName, teamIncentives.managers)}
          >
            {getTableContent(payPeriod.id, teamIncentives, incentives)}
          </Panel>
        </Collapse>
      </div>
      <ApproveConfirmationModal
        isVisible={approveSelectedModalIsVisible}
        payPeriod={payPeriod}
        onModalFinish={() =>
          setSelectedRowsCallback({ teamId: teamIncentives.teamId.toString(), records: [] })
        }
        agentIncentives={getSelectedApprovalRecordsForModal(teamIncentives.agentIncentives)}
        updateModalVisibility={setApproveSelectedModalIsVisibleCallback}
        teamName={approveModalTeamName ?? ''}
      />
    </>
  );
}

export default IncentiveSummaryWidget;
