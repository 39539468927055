/* @flow */

import * as React from 'react';
import { AutomatedIncentive } from '../../enums/AutomatedIncentiveTypeEnum';
import { Button, Form, Input, InputNumber, Modal, Select, notification } from 'antd';
import { CallDirections } from '../../enums/CallDirectionEnum';
import { Option } from 'antd/es/mentions';
import { RoleEnum } from '../../enums/RoleEnum';
import { formatCallDirection } from '../../utils/enumFormatter';
import { isNil, map } from 'lodash';
import { useEffect, useState } from 'react';
import AgentsTagSelector from './AgentsTagSelector';
import ContestPeriod from './ContestPeriod';
import ContestTypeSelector from '../incentives/ContestTypeSelector';
import TeamSelector from '../shared/TeamSelector';
import moment from 'moment';
import useCreateContest from '../../hooks/services/contest-management/useCreateContest';
import useMyRole from '../../hooks/services/role/useMyRole';
import useUpdateContest from '../../hooks/services/contest-management/useUpdateContest';
import type { CallDirectionEnum } from '../../enums/CallDirectionEnum';
import type { Contest } from '../../models/incentives/Contest';
import type { Incentive } from '../../models/incentives/Incentive';
import type { PayPeriod } from '../../models/PayPeriod';
import type { Team } from '../../models/Team';

type Props = $ReadOnly<{
  payPeriod: PayPeriod,
  incentives: ?Array<Incentive>,
  contest: ?Contest,
  isOpen: boolean,
  setModal: (isOpen: boolean, contest: ?Contest) => void,
}>;

function ContestForm(props: Props): React.Node {
  const { payPeriod, incentives, contest, isOpen, setModal } = props;

  const myRole = useMyRole();

  // States
  const [selectedIncentiveId, setSelectedIncentiveId] = useState(contest?.incentiveId);
  const [value, setValue] = useState();
  const [selectedTeams, setSelectedTeams] = useState(contest?.teams.map((team) => team.id) ?? []);

  // Forms
  const [form] = Form.useForm();

  const resetFields = () => {
    form.resetFields();
    setSelectedIncentiveId(0);
    setModal(false, null);
  };

  // UI modifications
  const modalTitle = () => {
    if (isExistingContest()) {
      return 'Edit Contest';
    }
    return 'New Contest';
  };

  function isExistingContest() {
    if (!contest) return false;
    return contest && contest.id > 0;
  }

  useEffect(() => {
    if (!isOpen) return;

    if (contest) {
      form.setFieldsValue({
        name: contest.name,
        startDate: contest.startDate,
        endDate: contest.endDate,
        incentiveId: selectedIncentiveId,
        overrideAmount: contest.overrideAmount,
        duration: [moment(contest.startDate), moment(contest.endDate)],
        inboundOutboundEnum: contest.inboundOutboundEnum,
        teams: contest.teams,
        agents: contest.agents,
      });
    }
  }, []);

  useEffect(() => {
    if (!isOpen) return;

    if (!isNil(selectedIncentiveId)) {
      form.setFieldsValue({
        amount: incentives?.find((x) => x.id === selectedIncentiveId)?.fixedAmount ?? 0,
      });
    }
  }, [selectedIncentiveId]);

  // Mutations

  const createMutation = useCreateContest();
  const createContest = async (contest: Contest) => {
    await createMutation.mutateAsync(contest, {
      onSuccess: () => {
        resetFields();
      },
      onError: (error) => {
        notification.error({
          message: 'Create Failed!',
          description: error.response?.data.message ?? error.message,
        });
      },
    });
  };

  const updateMutation = useUpdateContest();
  const updateContest = async (contest: Contest) => {
    await updateMutation.mutateAsync(contest, {
      onSuccess: () => {
        resetFields();
      },
      onError: (error) => {
        notification.error({
          message: 'Update Failed!',
          description: error.response?.data.message ?? error.message,
        });
      },
    });
  };

  const isLoading = updateMutation.isLoading || createMutation.isLoading;

  // Handlers
  const handleOk = async () => {
    const formValues = await form.validateFields();

    const start = formValues.duration[0].toDate();
    const end = formValues.duration[1].toDate();

    const newContest = {
      id: contest?.id ?? 0,
      ...formValues,
      startDate: start,
      endDate: end,
      payPeriodId: payPeriod.id,
    };

    if (newContest && newContest.id > 0) {
      await updateContest(newContest);
      return;
    }
    await createContest(newContest);
  };

  const handleCancel = () => {
    resetFields();
  };

  function handleContestTypeChange(incentiveId: number) {
    if (contestsIntendedForTeams.includes(incentiveId)) {
      form.setFieldsValue({ agents: [] });
    } else {
      form.setFieldsValue({ teams: [] });
      setSelectedTeams([]);
    }

    form.setFieldsValue({ incentiveId: incentiveId });
    const amount = incentives?.find((incentive) => incentive.id === incentiveId)?.fixedAmount ?? 0;
    form.setFieldsValue({ overrideAmount: amount });
    setSelectedIncentiveId(incentiveId);
    form.setFieldsValue({ inboundOutboundEnum: callDirectionSelectorDefaultValues(incentiveId) });
  }

  const onSelectChange = (newValue: string) => {
    setValue(newValue);
  };

  const callDirectionOptions = map(CallDirections, (allDirectionType: CallDirectionEnum) => {
    return (
      <Option key={allDirectionType} value={allDirectionType}>
        {formatCallDirection(allDirectionType)}
      </Option>
    );
  });

  const teamChanged = (value: Team[]) => {
    form.setFieldsValue({ teams: value });
    setSelectedTeams(value.map((team) => team.id));
  };

  let contestsIntendedForTeams = [
    findIncentiveId(AutomatedIncentive.PerUnitSalesContest),
    findIncentiveId(AutomatedIncentive.ExtendedHours),
    findIncentiveId(AutomatedIncentive.SDROutbound),
  ];

  function findIncentiveId(automatedIncentiveId: number) {
    return incentives?.find(
      (incentive) => incentive.automatedIncentiveType === automatedIncentiveId,
    )?.id;
  }

  function disableCallDirectionSelector() {
    return !!(
      isExistingContest() ||
      findIncentiveId(AutomatedIncentive.SDROutbound) === selectedIncentiveId ||
      findIncentiveId(AutomatedIncentive.PerUnitSalesVolunteeredShift) === selectedIncentiveId
    );
  }

  function callDirectionSelectorDefaultValues(selectedId: number) {
    if (findIncentiveId(AutomatedIncentive.SDROutbound) === selectedId) {
      return CallDirections.Outbound;
    }
    return CallDirections.Both;
  }

  return (
    <Modal
      title={modalTitle()}
      visible={isOpen}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" type="primary" disabled={isLoading} onClick={() => handleCancel()}>
          Cancel
        </Button>,
        <Button key="ok" type="primary" loading={isLoading} onClick={() => handleOk()}>
          OK
        </Button>,
      ]}
    >
      <Form requiredMark={false} layout="vertical" form={form}>
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Please provide a name for the contest' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="incentiveId"
          label="Contest Type"
          rules={[{ required: true, message: 'Please select a contest' }]}
        >
          <ContestTypeSelector
            inputValue={selectedIncentiveId}
            onChange={handleContestTypeChange}
            incentives={incentives}
          ></ContestTypeSelector>
        </Form.Item>
        <Form.Item
          name="overrideAmount"
          label="Amount"
          rules={[{ required: true, message: 'Please provide an amount' }]}
        >
          <InputNumber
            readOnly={myRole?.role.name !== RoleEnum.Admin}
            autoSize={{ minRows: 1, maxRows: 1 }}
            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          />
        </Form.Item>
        <Form.Item
          name="duration"
          rules={[{ required: true, message: 'Please select a contest period' }]}
        >
          <ContestPeriod
            contest={contest}
            form={form}
            payPeriod={payPeriod}
            isDisabled={false}
          ></ContestPeriod>
        </Form.Item>
        <Form.Item
          name="inboundOutboundEnum"
          label="Call Direction"
          initialValue={CallDirections.Both}
        >
          <Select
            style={{ width: 210 }}
            value={value}
            onChange={onSelectChange}
            disabled={disableCallDirectionSelector()}
          >
            {callDirectionOptions}
          </Select>
        </Form.Item>
        {findIncentiveId(AutomatedIncentive.PerUnitSalesVolunteeredShift) ===
          selectedIncentiveId && (
          <Form.Item
            name="agents"
            label="Agents"
            rules={[
              {
                validator: (_, value) => {
                  if (value && value.length > 0) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Please select at least one agent'));
                },
              },
            ]}
          >
            <AgentsTagSelector
              payPeriodId={payPeriod.id}
              agents={contest?.agents ?? []}
              form={form}
            />
          </Form.Item>
        )}
        {contestsIntendedForTeams.includes(selectedIncentiveId) && (
          <Form.Item
            name="teams"
            label="Teams"
            rules={[
              {
                validator: (_, value) => {
                  if (value && value.length > 0) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Please select at least one team.'));
                },
              },
            ]}
          >
            <TeamSelector
              teamIds={selectedTeams}
              payPeriodId={payPeriod.id}
              onChange={teamChanged}
              useImpersonation={true}
            />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
}

export default ContestForm;
