/* @flow */

export function customFormItemValidator(
  isValid: boolean,
  errorMessage: string,
  setIsValid: (valid: boolean) => void,
): Promise<void> {
  if (isValid) {
    return Promise.resolve().then(() => setIsValid(false));
  }

  return Promise.reject(new Error(errorMessage)).catch(() => setIsValid(true));
}
