/* @flow */
import { RoleEnum } from '../enums/RoleEnum';
import { get } from 'lodash';
import WebAPIClient from './api/WebAPIClient';
import config from '../config/';
import type { RoleAccess } from '../models/RoleAccess';

export const getMyRole = async (useImpersonation: boolean = true): Promise<RoleAccess> => {
  const response = await WebAPIClient.get('roles/mine', null, useImpersonation);
  const roleId = get(response, 'data.role.id', 999);
  const roleName = get(response, 'data.role.name', RoleEnum.Unknown);
  const roleEnum = Object.values(RoleEnum).includes(roleName) ? roleName : RoleEnum.Unknown;
  const responseHasReadOnlyAccess = get(response, 'data.hasReadOnlyAccess', true);
  const hasReadOnlyAccess = checkIfUserHasReadOnlyAccess(
    useImpersonation,
    responseHasReadOnlyAccess,
  );
  return { role: { id: roleId, name: roleEnum }, hasReadOnlyAccess };
};

function checkIfUserHasReadOnlyAccess(
  useImpersonation: boolean,
  responseHasReadOnlyAccess: boolean,
): boolean {
  if (!useImpersonation || config.allowWriteOnImpersonation) {
    return responseHasReadOnlyAccess;
  }
  return true;
}
