/* @flow */
import '../../css/pay-period-review/ApprovalProgressBar.css';
import * as React from 'react';
import { Popover, Progress } from 'antd';
import { filter, join, round } from 'lodash';
import Colors from '../../constants/Colors';

type Props = $ReadOnly<{
  countOfApproved: number,
  countOfSelectedForApproval: number,
  countOfTotal: number,
  showSubProgressBar?: boolean,
  showTitle?: boolean,
  title?: string,
  type?: string,
  width?: number,
}>;

ApprovalProgressBar.defaultProps = {
  showSubProgressBar: true,
  showTitle: true,
  title: '',
  type: 'circle',
  width: 132,
};

function ApprovalProgressBar(props: Props): React.Node {
  const {
    countOfApproved,
    countOfSelectedForApproval,
    countOfTotal,
    showTitle,
    showSubProgressBar,
    title,
    type,
    width,
  } = props;
  const percentApproved = round((countOfApproved / countOfTotal) * 100, 1);
  const percentSelected = round((countOfSelectedForApproval / countOfTotal) * 100, 1);
  const percentTotal = round(percentApproved + percentSelected, 1);

  const progressColor = percentApproved === 100 ? Colors.GREEN : Colors.BLUE;
  const shouldShowSubProgressBar =
    showSubProgressBar === null || showSubProgressBar === undefined ? false : showSubProgressBar;
  const progressType = type === null || type === undefined ? 'circle' : type;

  const tooltipTitle = getTooltip(countOfApproved, countOfSelectedForApproval, countOfTotal);

  return (
    <div className="approval-progress-bar-container">
      <div className="approval-progress-bar-main">
        <Popover content={tooltipTitle}>
          <Progress
            percent={percentTotal}
            success={{ percent: percentApproved, strokeColor: progressColor }}
            status="normal"
            strokeColor={Colors.ORANGE}
            trailColor="#DDD"
            type={type}
            width={width}
          />
        </Popover>
        {showTitle === true && title !== '' && (
          <div className="approval-progress-bar-title">{title}</div>
        )}
      </div>
      {shouldShowSubProgressBar && (
        <div className="approval-progress-bar-sub-container">
          {getUnapprovedProgressBar(
            countOfApproved,
            countOfSelectedForApproval,
            countOfTotal,
            progressType,
          )}
        </div>
      )}
    </div>
  );
}

function getTooltip(
  countOfApproved: number,
  countOfSelected: number,
  countOfTotal: number,
): string {
  const countOfUnapproved = countOfTotal - countOfApproved;
  const unapprovedText =
    countOfUnapproved !== countOfTotal && countOfUnapproved !== 0
      ? `${countOfUnapproved} unapproved`
      : '';
  const approvedText =
    countOfApproved > 0 && countOfApproved < countOfTotal ? `${countOfApproved} approved` : '';
  const selectedText = countOfSelected > 0 ? `${countOfSelected} selected` : '';
  const resultsToRender = filter(
    [selectedText, approvedText, unapprovedText],
    (item) => item !== '',
  );
  return join(resultsToRender, ', ');
}

function getUnapprovedProgressBar(
  countOfApproved: number,
  countOfSelectedForApproval: number,
  countOfTotal: number,
  type: string,
): React.Node {
  const countOfUnapproved = countOfTotal - countOfApproved;
  const percentApprovedOutOfUnapproved = round(
    (countOfSelectedForApproval / countOfUnapproved) * 100,
    1,
  );

  return (
    <div className="approval-progress-bar-sub">
      <Progress
        percent={percentApprovedOutOfUnapproved}
        status="normal"
        strokeColor={Colors.ORANGE}
        trailColor="#DDD"
        type={type}
        width={50}
      />
      <div className="approval-progress-bar-sub-title">{countOfUnapproved} Unapproved</div>
    </div>
  );
}

export default ApprovalProgressBar;
