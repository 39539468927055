/* @flow */
import * as React from 'react';
import { DatePicker } from 'antd';
import { formatNthWeekOfMonth } from '../../../utils/formatter';
import { useEffect, useState } from 'react';
import moment from 'moment';
import type { AgentIncentive } from '../../../models/incentives/AgentIncentive';
type Props = $ReadOnly<{
  fieldId: number,
  form: any,
  agentIncentive: ?AgentIncentive,
}>;

function CalendarWeekIncentiveValue(props: Props): React.Node {
  const { fieldId, form, agentIncentive } = props;
  const dateFormat = 'YYYY ww';
  const getInitialValue = () => {
    return moment(
      agentIncentive?.incentiveValues?.find((x) => x.incentiveFieldId === fieldId)?.value ??
        new Date(),
      dateFormat,
    );
  };

  const [dateState, setDateState] = useState(getInitialValue());

  useEffect(() => {
    form.setFieldsValue({ [fieldId]: getInitialValue().format(dateFormat) });
  }, []);

  const updateField = (date) => {
    form.setFieldsValue({ [fieldId]: date.format(dateFormat) });
    setDateState(date);
  };

  return (
    <DatePicker
      value={dateState}
      onChange={updateField}
      picker="week"
      format={formatNthWeekOfMonth}
    />
  );
}

export default CalendarWeekIncentiveValue;
