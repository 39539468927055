/* @flow */
import devConfig from './config.dev';
import localConfig from './config.local';
import prodConfig from './config.prod';
import qaConfig from './config.qa';
import stagingConfig from './config.staging';

let configToExport: {
  environment: string,
  agentGatewayUrl: string,
  auth: {
    clientId: string,
    authority: string,
  },
  commissionsStartDate: string,
  impersonatedEmail: string,
  allowWriteOnImpersonation: boolean,
};

switch (process.env.REACT_APP_ENV) {
  case 'prod':
    configToExport = prodConfig;
    break;
  case 'staging':
    configToExport = stagingConfig;
    break;
  case 'qa':
    configToExport = qaConfig;
    break;
  case 'dev':
    configToExport = devConfig;
    break;
  case 'local':
  default:
    configToExport = localConfig;
    break;
}
export default configToExport;
