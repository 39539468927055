/* @flow */
import * as React from 'react';
import AdjustmentsTable from '../corrections-and-adjustments/AdjustmentsTable';
import PayPeriodSelector from '../shared/PayPeriodSelector';
import Section from '../shared/Section';
import SubHeader from '../shared/SubHeader';
import useAdjustments from '../../hooks/services/adjustment/useAdjustments';
import usePayPeriodQueryState from '../../hooks/usePayPeriodQueryState';

function CorrectionsAndAdjustments(): React.Node {
  // States
  const { payPeriodId, onPayPeriodChange } = usePayPeriodQueryState();

  // Queries
  const { isLoading, adjustments } = useAdjustments(payPeriodId);

  return (
    <>
      <SubHeader
        headerLabel={'Corrections & Adjustments'}
        primarySelector={null}
        secondarySelectorSeparator={'for'}
        secondarySelectorLabel={'Commissions Period'}
        secondarySelector={
          <PayPeriodSelector
            payPeriodId={payPeriodId}
            onChange={onPayPeriodChange}
            showStatus={true}
          />
        }
      />
      <Section
        headerLabel={''}
        contents={[
          <AdjustmentsTable
            key={'adjustmentsTableSection'}
            adjustments={adjustments}
            payPeriodId={payPeriodId ?? 0}
          />,
        ]}
        isLoading={isLoading}
      />
    </>
  );
}

export default CorrectionsAndAdjustments;
