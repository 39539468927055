/* @flow */

import { deleteContest } from '../../../services/IncentiveService';
import { useMutation, useQueryClient } from 'react-query';
import CommissionsServiceKeys from '../../../constants/CommissionsServiceKeys';
import useQueryCacheKey from '../../useQueryCacheKey';
import type { Contest } from '../../../models/incentives/Contest';

type ContestDeleteMutationOptions = $ReadOnly<{
  onSuccess?: () => void,
  onError?: (error: any) => void,
}>;

type ContestDeleteMutation = $ReadOnly<{
  mutateAsync: (data: Contest, options?: ContestDeleteMutationOptions) => Promise<void>,
  isLoading: boolean,
}>;

export default function useDeleteContest(payPeriodId: number): ContestDeleteMutation {
  const queryClient = useQueryClient();
  const cacheKey = useQueryCacheKey(CommissionsServiceKeys.CONTESTS);

  const { mutateAsync, isLoading } = useMutation(
    async (contest: Contest) => deleteContest(contest.id),
    {
      onSuccess: async (response, deletedContest: Contest) => {
        const queryKey = [...cacheKey, { payPeriodId: payPeriodId }];

        await queryClient.cancelQueries(queryKey);
        const cachedContest = queryClient.getQueryData(queryKey);

        const index = cachedContest.findIndex((item) => item.id === deletedContest.id);
        if (index >= 0) {
          cachedContest.splice(index, 1);
        }

        queryClient.setQueryData(queryKey, () => cachedContest);
        return cachedContest;
      },
    },
  );

  return {
    mutateAsync: async (data: Contest, options?: ContestDeleteMutationOptions): Promise<void> => {
      try {
        await mutateAsync(data);
        options?.onSuccess?.();
      } catch (e) {
        options?.onError?.(e);
      }
    },
    isLoading,
  };
}
