/* @flow */
import '../../css/shared/SearchBar.css';
import * as React from 'react';
import { Input } from 'antd';

type Props = $ReadOnly<{
  width: 'medium' | 'small',
  enterButton: boolean | React.Node,
  onSearch: (value: string) => void,
  onFocus?: () => void,
}>;

const SearchBar: React$AbstractComponent<Props, any> = React.forwardRef<Props, any>(
  function SearchBarBase(props: Props, ref): React.Node {
    const { width, enterButton, onSearch, onFocus } = props;

    return (
      <Input.Search
        className={{
          'search-bar-root': true,
          'search-bar-medium': width === 'medium',
          'search-bar-small': width === 'small',
        }}
        ref={ref}
        placeholder="Enter a customer or contract number"
        onSearch={onSearch}
        allowClear
        enterButton={enterButton}
        onFocus={onFocus}
      />
    );
  },
);

export default SearchBar;
