/* @flow */
import * as React from 'react';
import { Col, Descriptions, Row } from 'antd';
import { DealCommissionTypes } from '../../enums/DealCommissionTypeEnum';
import { formatDateAsShortDate } from '../../utils/formatter';
import SearchRetentionDealDetails from './SearchRetentionDealDetails';
import SearchSoldDealDetails from './SearchSoldDealDetails';
import type { SalesDevelopmentCommission } from '../../models/deals/SalesDevelopmentCommission';
import type { SearchAgentInfo, SearchResult } from '../../models/SearchResult';

type Props = $ReadOnly<{
  searchResult: SearchResult,
}>;

function SearchDetails(props: Props): React.Node {
  const { searchResult } = props;

  switch (searchResult.commissionType) {
    case DealCommissionTypes.Sales:
      return <SearchSoldDealDetails deal={searchResult} />;
    case DealCommissionTypes.SalesDevelopment:
      return getSalesDevelopmentDealDetails(searchResult);
    case DealCommissionTypes.Retention:
      return <SearchRetentionDealDetails deal={searchResult} />;
    default:
      return null;
  }
}

function getSalesDevelopmentDealDetails(
  deal: SalesDevelopmentCommission & SearchAgentInfo,
): React.Node {
  return getSearchDetailsContainer(
    <Descriptions.Item label={'Sold Date'}>
      {formatDateAsShortDate(deal.soldDate, true, true)}
    </Descriptions.Item>,
  );
}

function getSearchDetailsContainer(items: React.Node): React.Node {
  return (
    <Row className="search-detail-root">
      <Col xl={6} lg={12} span={24}>
        <Descriptions title="Basic Info" bordered>
          {items}
        </Descriptions>
      </Col>
    </Row>
  );
}

export default SearchDetails;
