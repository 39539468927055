/* @flow */

import { createContest } from '../../../services/IncentiveService';
import { useMutation, useQueryClient } from 'react-query';
import CommissionsServiceKeys from '../../../constants/CommissionsServiceKeys';
import useQueryCacheKey from '../../useQueryCacheKey';
import type { Contest } from '../../../models/incentives/Contest';
import type { ContestMutation } from '../../../models/incentives/ContestMutation';

type ContestCreateMutationOptions = $ReadOnly<{
  onSuccess?: (data: Contest) => void,
  onError?: (error: any) => void,
}>;

type ContestCreateMutation = $ReadOnly<{
  mutateAsync: (data: ContestMutation, options?: ContestCreateMutationOptions) => Promise<?Contest>,
  isLoading: boolean,
}>;

export default function useCreateContest(): ContestCreateMutation {
  const queryClient = useQueryClient();
  const cacheKey = useQueryCacheKey(CommissionsServiceKeys.CONTESTS);

  const { mutateAsync, isLoading } = useMutation(
    (contest: ContestMutation) => createContest(contest),
    {
      onSuccess: async (response: { data: Contest, status: number, ... }) => {
        const { data } = response;
        const queryKey = [...cacheKey, { payPeriodId: data.payPeriodId }];
        await queryClient.cancelQueries(queryKey);

        const cachedContest = queryClient.getQueryData(queryKey);

        const array = [...cachedContest, data];
        queryClient.setQueryData(queryKey, () => array);

        return data;
      },
    },
  );

  return {
    mutateAsync: async (
      data: ContestMutation,
      options?: ContestCreateMutationOptions,
    ): Promise<?Contest> => {
      try {
        const result = await mutateAsync(data);
        options?.onSuccess?.(result.data);
        return result.data;
      } catch (e) {
        options?.onError?.(e);
      }
      return null;
    },
    isLoading,
  };
}
