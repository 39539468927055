/* @flow */
import '../../css/incentives/dynamic-form-components/ContractNumbersIncentiveValue.css';
import * as React from 'react';
import { Tag } from 'antd';
import { useState } from 'react';
import AgentSelector from '../shared/AgentSelector';
import type { Agent } from '../../models/Agent';

type Props = $ReadOnly<{
  payPeriodId: number,
  agents: Array<Agent>,
  form: any,
}>;

function AgentsTagSelector(props: Props): React.Node {
  const { payPeriodId, agents, form } = props;

  //States
  const [tags, setTags] = useState(agents ?? []);

  const onAgentSelect = (value: ?Agent) => {
    if (!value) return;

    const isDouble = tags.some((tag) => tag.id === value.id);
    if (isDouble) {
      return;
    }
    updateTags([...tags, value]);
  };

  const updateTags = (tags) => {
    setTags(tags);
    form.setFieldsValue({ agents: tags });
  };

  const handleDeleteTag = (removedTag) => {
    const newTags = tags.filter((tag) => tag.id !== removedTag);
    updateTags(newTags);
  };

  const renderTag = (tag) => {
    const tagElem = (
      <Tag
        onClose={(e) => {
          e.preventDefault();
          handleDeleteTag(tag.id);
        }}
      >
        {tag.fullName}
      </Tag>
    );
    return (
      <span
        key={tag.id}
        style={{
          display: 'inline-block',
        }}
      >
        {tagElem}
      </span>
    );
  };

  const tagChild = tags.map(renderTag);

  return (
    <>
      <AgentSelector
        agentId={0}
        showStatus={true}
        useImpersonation={true}
        showErrorMessage={false}
        showExcludeInactiveCheckbox={false}
        defaultExcludeInactive={true}
        payPeriodId={payPeriodId}
        tooltipErrorMessage={''}
        useTeamGrouping={true}
        onChange={onAgentSelect}
      />
      <div className="tags">{tagChild}</div>
    </>
  );
}

export default AgentsTagSelector;
