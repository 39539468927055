/* @flow */
import '../../css/layouts/MasterLayout.css';
import * as React from 'react';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsalAuthentication,
} from '@azure/msal-react';
import { ImpersonationContext } from '../../context/ImpersonationContext';
import { Outlet } from 'react-router-dom';
import { RoleEnum } from '../../enums/RoleEnum';
import { Spin } from 'antd';
import Nav from '../shared/Nav';
import useImpersonation from '../../hooks/useImpersonation';
import useMyRole from '../../hooks/services/role/useMyRole';

function MasterLayout(): React.Node {
  const { error } = useMsalAuthentication('redirect');
  const [agent, setAgent] = useImpersonation();
  const myRole = useMyRole({ useImpersonation: false });

  const errorExists = error !== null;
  if (errorExists) {
    console.error('User failed to login', error);
  }

  return (
    <div className="master-layout-root">
      <AuthenticatedTemplate>
        <ImpersonationContext.Provider value={{ agent, setAgent }}>
          <Nav enableImpersonation={myRole?.role.name === RoleEnum.Admin} />
          <main className="master-layout-content">
            <Outlet />
          </main>
        </ImpersonationContext.Provider>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <main className="master-layout-content">
          {errorExists ? 'Something went wrong. Please reload and try again.' : <Spin />}
        </main>
      </UnauthenticatedTemplate>
    </div>
  );
}

export default MasterLayout;
