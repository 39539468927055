/* @flow */
import * as React from 'react';
import { DealGroupTypes } from '../../enums/DealGroupTypeEnum';
import { Tabs } from 'antd';
import { filter } from 'lodash';
import SalesDevelopmentCommissionsTable from './SalesDevelopmentCommissionsTable.jsx';
import type { DealTotals } from '../../models/totals/DealTotals';
import type { SalesDevelopmentCommission } from '../../models/deals/SalesDevelopmentCommission';

const { TabPane } = Tabs;

type Props = $ReadOnly<{
  deals: ?Array<SalesDevelopmentCommission>,
  total: ?DealTotals,
  extraContent?: React.Node,
}>;

function SalesDevelopmentCommissionsTabs(props: Props): React.Node {
  const { deals, total, extraContent } = props;

  const soldCurrentDeals = filter(deals, (deal) => deal.dealGroup === DealGroupTypes.SoldCurrent);
  const cancelledDeals = filter(deals, (deal) => deal.dealGroup === DealGroupTypes.Cancelled);

  const soldCurrentLabel = `Sold Current (${soldCurrentDeals.length})`;
  const cancelledLabel = `Canceled (${cancelledDeals.length})`;

  return (
    <Tabs tabBarExtraContent={extraContent}>
      <TabPane tab={soldCurrentLabel} key="1">
        <SalesDevelopmentCommissionsTable
          deals={soldCurrentDeals}
          total={total?.salesDevelopmentAmount}
          minScrollHeight={390}
        />
      </TabPane>
      <TabPane tab={cancelledLabel} key="2">
        <SalesDevelopmentCommissionsTable
          deals={cancelledDeals}
          total={total?.cancelledAmount}
          minScrollHeight={390}
        />
      </TabPane>
    </Tabs>
  );
}

export default SalesDevelopmentCommissionsTabs;
