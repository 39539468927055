/* @flow */
import { getCommissionSearchResults } from '../../../services/CommissionService';
import { isEmpty, isNil } from 'lodash';
import { useQuery } from 'react-query';
import CommissionServiceKeys from '../../../constants/CommissionsServiceKeys';
import useQueryCacheKey from '../../useQueryCacheKey';
import type { SearchResult } from '../../../models/SearchResult';

const CACHE_TIME = 60 * 60 * 1000; // One hour

function useCommissionSearchResults(searchTerm: string): ?Array<SearchResult> {
  const cacheKey = useQueryCacheKey(CommissionServiceKeys.COMMISSION_SEARCH_RESULTS);

  const { isFetching, isError, data } = useQuery(
    [...cacheKey, { searchTerm }],
    async () => getCommissionSearchResults(searchTerm),
    {
      cacheTime: CACHE_TIME,
      enabled: searchTerm.length > 0,
      staleTime: CACHE_TIME,
    },
  );

  if (isNil(searchTerm) || isEmpty(searchTerm)) {
    return [];
  }

  if (isFetching) {
    return null;
  }

  if (isError) {
    return [];
  }

  return data;
}

export default useCommissionSearchResults;
