/* @flow */
import '../../css/pay-period-review/CommissionReviewTable.css';
import * as React from 'react';
import { Button, Checkbox, Popconfirm, Popover, Progress, Table, notification } from 'antd';
import { LockTypes } from '../../enums/LockTypeEnum';
import { RoleEnum } from '../../enums/RoleEnum';
import {
  assign,
  filter,
  find,
  isNil,
  join,
  map,
  reverse,
  round,
  size,
  sortBy,
  trim,
  words,
} from 'lodash';
import {
  formatDateAsShortDate,
  formatNumberAsMoney,
  formatTeamMemberships,
} from '../../utils/formatter';
import Colors from '../../constants/Colors';
import DashboardLink from '../shared/DashboardLink';
import useMyRole from '../../hooks/services/role/useMyRole';
import useUnapproveCommissions from '../../hooks/services/commission/useUnapproveCommissions';
import type { AgentCommissionReview } from '../../models/reviews/AgentCommissionReview';
import type { OtherCommissionEarnedTotal } from '../../models/reviews/OtherCommissionEarnedTotal';
import type { PayPeriod } from '../../models/PayPeriod';

type Props = $ReadOnly<{
  columnConfig?: Array<string>,
  reviews: ?Array<AgentCommissionReview>,
  selectedRows: ?Array<AgentCommissionReview>,
  setSelectedRowsCallback: ({ teamId: string, records: Array<any> }) => void,
  payPeriod: PayPeriod,
  teamId: string,
}>;

TeamSummaryTable.defaultProps = {
  columnConfig: ['howardSimonId', 'rep', 'hireDate', 'terminationDate', 'earnedTotal'],
};

function TeamSummaryTable(props: Props): React.Node {
  const { columnConfig, payPeriod, reviews, selectedRows, setSelectedRowsCallback, teamId } = props;

  // Hooks
  const myRole = useMyRole();

  // Mutations
  const unapproveMutation = useUnapproveCommissions();

  const COMMISSION_REVIEW_COLUMN_CONFIG = {
    rep: {
      title: 'Rep',
      dataIndex: 'agentFullName',
      editable: false,
      sorter: (a, b): any =>
        getFullNameReversed(a.agentFullName).localeCompare(getFullNameReversed(b.agentFullName)),
      render: (value: ?string, row: AgentCommissionReview) =>
        !isNil(value) ? (
          <DashboardLink agentId={row.agentId} payPeriodId={payPeriod.id}>
            {value}
          </DashboardLink>
        ) : (
          '-'
        ),
      width: 155,
      fixed: 'left',
    },
    managedTeams: {
      title: 'Managed Teams',
      dataIndex: 'teamMemberships',
      editable: false,
      sorter: (a, b) =>
        formatTeamMemberships(a.teamMemberships).localeCompare(
          formatTeamMemberships(b.teamMemberships),
        ),
      render: (value: ?any, row: AgentCommissionReview) =>
        formatTeamMemberships(row.teamMemberships),
      width: 400,
    },
    howardSimonId: {
      title: 'HS Id',
      dataIndex: 'howardSimonId',
      editable: false,
      sorter: (a, b) => a.howardSimonId - b.howardSimonId,
      width: 90,
    },
    hireDate: {
      title: 'Date Hired',
      dataIndex: 'hireDate',
      editable: false,
      sorter: (a, b) => new Date(a.hireDate) - new Date(b.hireDate),
      render: (_, row: AgentCommissionReview) => {
        const formattedDate = formatDateAsShortDate(row.hireDate, true, true);
        return formattedDate !== '1/1/1' ? formattedDate : '-';
      },
      align: 'center',
      width: 110,
    },
    terminationDate: {
      title: 'Date Termed',
      dataIndex: 'terminationDate',
      editable: false,
      sorter: (a, b) => new Date(a.terminationDate) - new Date(b.terminationDate),
      render: (_, row: AgentCommissionReview) => {
        const formattedDate = formatDateAsShortDate(row.terminationDate, true, true);
        return formattedDate !== '' ? formattedDate : '-';
      },
      align: 'center',
      width: 120,
    },
    salesDevelopmentEarnedTotal: {
      title: 'Sales ($)',
      dataIndex: 'salesDevelopmentCommissionEarnedTotal',
      editable: false,
      sorter: (a, b) =>
        a.salesDevelopmentCommissionEarnedTotal - b.salesDevelopmentCommissionEarnedTotal,
      render: (_, row: AgentCommissionReview) =>
        formatNumberAsMoney(row.salesDevelopmentCommissionEarnedTotal, true),
      align: 'right',
      width: 100,
    },
    directMailEarnedTotal: {
      title: 'DM ($)',
      dataIndex: 'directMailSalesCommissionEarnedTotal',
      editable: false,
      sorter: (a, b) =>
        a.directMailSalesCommissionEarnedTotal - b.directMailSalesCommissionEarnedTotal,
      render: (_, row: AgentCommissionReview) =>
        formatNumberAsMoney(row.directMailSalesCommissionEarnedTotal, true),
      align: 'right',
      width: 100,
    },
    directMailClosingRatioPercentage: {
      title: 'DM CR %',
      dataIndex: 'directMailClosingRatioPercentage',
      editable: false,
      sorter: (a, b) => a.directMailClosingRatioPercentage - b.directMailClosingRatioPercentage,
      render: (_, row: AgentCommissionReview) => {
        const progressBarColor =
          row.directMailClosingRatioPercentage === 1 ? Colors.GREEN : Colors.BLUE;
        return (
          <Progress
            percent={round(row.directMailClosingRatioPercentage * 100, 1)}
            size="small"
            strokeColor={progressBarColor}
            status="normal"
          />
        );
      },
      align: 'center',
      width: 120,
    },
    digitalEarnedTotal: {
      title: 'Digital ($)',
      dataIndex: 'digitalSalesCommissionEarnedTotal',
      editable: false,
      sorter: (a, b) => a.digitalSalesCommissionEarnedTotal - b.digitalSalesCommissionEarnedTotal,
      render: (_, row: AgentCommissionReview) =>
        formatNumberAsMoney(row.digitalSalesCommissionEarnedTotal, true),
      align: 'right',
      width: 100,
    },
    digitalInboundClosingRatioPercentage: {
      title: 'Dig IB CR %',
      dataIndex: 'digitalInboundClosingRatioPercentage',
      editable: false,
      sorter: (a, b) =>
        a.digitalInboundClosingRatioPercentage - b.digitalInboundClosingRatioPercentage,
      render: (_, row: AgentCommissionReview) => {
        const progressBarColor =
          row.digitalInboundClosingRatioPercentage === 1 ? Colors.GREEN : Colors.BLUE;
        return (
          <Progress
            percent={round(row.digitalInboundClosingRatioPercentage * 100, 1)}
            size="small"
            strokeColor={progressBarColor}
            status="normal"
          />
        );
      },
      align: 'center',
      width: 120,
    },
    digitalOutboundClosingRatioPercentage: {
      title: 'Dig OB CR %',
      dataIndex: 'digitalOutboundClosingRatioPercentage',
      editable: false,
      sorter: (a, b) =>
        a.digitalOutboundClosingRatioPercentage - b.digitalOutboundClosingRatioPercentage,
      render: (_, row: AgentCommissionReview) => {
        const progressBarColor =
          row.digitalOutboundClosingRatioPercentage === 1 ? Colors.GREEN : Colors.BLUE;
        return (
          <Progress
            percent={round(row.digitalOutboundClosingRatioPercentage * 100, 1)}
            size="small"
            strokeColor={progressBarColor}
            status="normal"
          />
        );
      },
      align: 'center',
      width: 120,
    },
    tvEarnedTotal: {
      title: 'TV ($)',
      dataIndex: 'digitalSalesCommissionEarnedTotal',
      editable: false,
      sorter: (a, b) => a.tvSalesCommissionEarnedTotal - b.tvSalesCommissionEarnedTotal,
      render: (_, row: AgentCommissionReview) =>
        formatNumberAsMoney(row.tvSalesCommissionEarnedTotal, true),
      align: 'right',
      width: 100,
    },
    tvClosingRatioPercentage: {
      title: 'TV CR %',
      dataIndex: 'tvClosingRatioPercentage',
      editable: false,
      sorter: (a, b) => a.tvClosingRatioPercentage - b.tvClosingRatioPercentage,
      render: (_, row: AgentCommissionReview) => {
        const progressBarColor = row.tvClosingRatioPercentage === 1 ? Colors.GREEN : Colors.BLUE;
        return (
          <Progress
            percent={round(row.tvClosingRatioPercentage * 100, 1)}
            status="normal"
            strokeColor={progressBarColor}
            size="small"
          />
        );
      },
      align: 'center',
      width: 120,
    },
    retentionEarnedTotal: {
      title: 'Retention ($)',
      dataIndex: 'retentionCommissionEarnedTotal',
      editable: false,
      sorter: (a, b) => a.retentionCommissionEarnedTotal - b.retentionCommissionEarnedTotal,
      render: (_, row: AgentCommissionReview) =>
        formatNumberAsMoney(row.retentionCommissionEarnedTotal, true),
      align: 'right',
      width: 125,
    },
    retentionFlatForceEarnedTotal: {
      title: 'Flat Force ($)',
      dataIndex: 'retentionFlatForceCommissionEarnedTotal',
      editable: false,
      sorter: (a, b) =>
        a.retentionFlatForceCommissionEarnedTotal - b.retentionFlatForceCommissionEarnedTotal,
      render: (_, row: AgentCommissionReview) =>
        formatNumberAsMoney(row.retentionFlatForceCommissionEarnedTotal, true),
      align: 'right',
      width: 125,
    },
    otherEarnedTotal: {
      title: 'Other ($)',
      dataIndex: 'otherCommissionEarnedTotal',
      editable: false,
      sorter: (a, b) => a.otherCommissionEarnedTotal - b.otherCommissionEarnedTotal,
      render: (_, row: AgentCommissionReview) => {
        const getPopoverContent = (data: Array<OtherCommissionEarnedTotal>): React.Node => {
          return map(data, (item) => {
            return (
              <div className="commission-summary-table-other-amount-container">
                <div className="commission-summary-table-other-amount-title">{item.title}</div>
                <div className="commission-summary-table-other-amount-value">
                  {formatNumberAsMoney(item.amount, true)}
                </div>
              </div>
            );
          });
        };

        return size(row.otherCommissionEarnedTotalBreakdown) > 0 ? (
          <Popover
            content={getPopoverContent(row.otherCommissionEarnedTotalBreakdown)}
            placement="right"
          >
            {formatNumberAsMoney(row.otherCommissionEarnedTotal, true)}
          </Popover>
        ) : (
          formatNumberAsMoney(row.otherCommissionEarnedTotal, true)
        );
      },
      align: 'right',
      width: 100,
    },
    adjustments: {
      title: 'Adjustment',
      dataIndex: 'adjustmentsTotal',
      editable: false,
      sorter: (a, b) => a.adjustmentsTotal - b.adjustmentsTotal,
      render: (_, row: AgentCommissionReview) => formatNumberAsMoney(row.adjustmentsTotal, true),
      align: 'right',
      width: 100,
    },
    earnedTotal: {
      title: 'Total ($)',
      dataIndex: 'salesDevelopmentCommissionEarnedTotal',
      editable: false,
      sorter: (a, b) => a.commissionEarnedTotal - b.commissionEarnedTotal,
      render: (_, row: AgentCommissionReview) =>
        formatNumberAsMoney(row.commissionEarnedTotal, true),
      align: 'right',
      width: 100,
      fixed: 'right',
    },
  };

  const isInSelectedRows = (key: string): AgentCommissionReview => {
    return find(selectedRows, (row) => row.key === key);
  };

  const getRows = (rows: ?Array<AgentCommissionReview>): any => {
    if (isNil(rows)) return [];
    const data = sortBy(rows, (row) => getFullNameReversed(row.agentFullName));
    return map(data, (record) => {
      return assign({ key: record.agentCommissionId.toString() }, record);
    });
  };

  const onUnapproved = async (record) => {
    await unapproveMutation.mutateAsync(
      {
        agentCommissionId: record.agentCommissionId,
        payPeriodId: payPeriod.id,
      },
      {
        onSuccess: () => {
          openSuccessNotification();
          setSelectedRowsCallback({
            teamId,
            records: filter(selectedRows, (row) => !isInSelectedRows(row.key)),
          });
        },
        onError: (e) => {
          if (e?.response?.data?.errors?.IsLocked)
            openFailureNotification(e.response.data.errors.IsLocked);
          else openFailureNotification();
        },
      },
    );
  };

  const openSuccessNotification = () => {
    const key = `approval-success-notification-${Date.now()}`;
    const btn = (
      <Button type="primary" size="small" onClick={() => notification.close(key)}>
        Ok
      </Button>
    );
    notification['success']({
      message: 'Unapproval Complete',
      description: <div>The selected payroll records have been unapproved.</div>,
      btn,
      key,
      placement: 'bottom',
    });
  };

  const openFailureNotification = (errorMessage?: string) => {
    const key = `export-failure-notification-${Date.now()}`;
    const btn = (
      <Button type="primary" size="small" onClick={() => notification.close(key)}>
        Ok
      </Button>
    );
    notification['error']({
      message: 'Unapproval Failed',
      description: (
        <div>
          Unapproval of the payroll records for this pay period failed.
          <strong>{errorMessage}</strong> If you need further assistance, please notify corporate IT
        </div>
      ),
      style: { width: 500 },
      duration: 0,
      btn,
      key,
      placement: 'bottom',
    });
  };

  const getRowSelection = () => {
    return {
      columnWidth: 50,
      selectedRowKeys: map(selectedRows, (row) => row.key),
      preserveSelectedRowKeys: false,
      hideSelectAll: payPeriod.payPeriodLocks?.some((x) => x.lockType === LockTypes.Commissions),
      onChange: (_, selectedRows) => {
        setSelectedRowsCallback({
          teamId,
          records: filter(selectedRows, (row) => !isInSelectedRows(row.key)),
        });
      },
      getCheckboxProps: (row) => ({
        disabled: row.isApproved,
      }),
      renderCell: (checked, record) => {
        if (record.isApproved) {
          return (
            <Popconfirm
              title="Are you sure you want to unapprove the commission?"
              onConfirm={() => onUnapproved(record)}
            >
              <Checkbox
                checked={record.isApproved}
                disabled={
                  myRole?.role.name !== RoleEnum.Admin ||
                  payPeriod.payPeriodLocks?.some((x) => x.lockType === LockTypes.Commissions)
                }
              />
            </Popconfirm>
          );
        }

        return (
          <Checkbox
            checked={isInSelectedRows(record.key)}
            disabled={payPeriod.payPeriodLocks?.some((x) => x.lockType === LockTypes.Commissions)}
            onChange={() => onRowCheckboxClick(record)}
          />
        );
      },
    };
  };

  const getColumns = (columnNames: ?Array<string>): any => {
    return columnNames ? columnNames.map((name) => COMMISSION_REVIEW_COLUMN_CONFIG[name]) : [];
  };

  const onRowCheckboxClick = (record) => {
    setSelectedRowsCallback({ teamId, records: [record] });
  };

  return (
    <Table
      bordered
      className="commission-reviews-table"
      columns={getColumns(columnConfig)}
      dataSource={getRows(reviews)}
      pagination={false}
      rowKey={(record) => record.agentCommissionId.toString()}
      rowSelection={getRowSelection()}
      scroll={{ y: 600 }}
      size="small"
    />
  );
}

function getFullNameReversed(fullName: string) {
  if (isNil(fullName)) return '';
  const fullNameArray = words(fullName);
  const fullNameArrayReversed = reverse(fullNameArray);
  const fullNameReversed = join(fullNameArrayReversed, ' ');
  return trim(fullNameReversed);
}

export default TeamSummaryTable;
