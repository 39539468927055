/* @flow */
import * as React from 'react';
import { IncentiveFieldTypes } from '../../enums/IncentiveFieldTypeEnum';
import ShowMore from './ShowMore';

type Props = $ReadOnly<{
  incentiveId: number,
  incentiveName: string,
  incentiveValue: string,
  incentiveFieldType: string,
}>;

function ShowMoreContractNumbers(props: Props): React.Node {
  const { incentiveId, incentiveName, incentiveValue, incentiveFieldType } = props;

  const GetValue = (incentiveValue: string, incentiveFieldType: string) => {
    if (incentiveFieldType === IncentiveFieldTypes.CONTRACT_NUMBERS) {
      return JSON.parse(incentiveValue).join(', ');
    }
    return incentiveValue;
  };

  return (
    <div key={`${incentiveName}-${incentiveId}`}>
      {incentiveName && <span className="incentive-value-name">{incentiveName}:</span>} {}
      <ShowMore inputText={GetValue(incentiveValue, incentiveFieldType)} />
    </div>
  );
}
export default ShowMoreContractNumbers;
