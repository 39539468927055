/* @flow */
import { createAgentIncentive } from '../../../services/IncentiveService';
import { filter, size } from 'lodash';
import { recalculateIncentiveReviewSummary } from '../../../utils/incentiveUtils';
import { useMutation, useQueryClient } from 'react-query';
import CommissionsServiceKeys from '../../../constants/CommissionsServiceKeys';
import useQueryCacheKey from '../../useQueryCacheKey';
import type { AgentIncentive } from '../../../models/incentives/AgentIncentive';
import type { AgentIncentiveMutation } from '../../../models/incentives/AgentIncentiveMutation';
import type { TeamIncentivesReview } from '../../../models/incentives/TeamIncentivesReview';

type AgentIncentiveCreateMutationOptions = $ReadOnly<{
  onSuccess?: (data: AgentIncentive) => void,
  onError?: (error: any) => void,
}>;

type AgentIncentiveCreateMutation = $ReadOnly<{
  mutateAsync: (
    data: AgentIncentiveMutation,
    options?: AgentIncentiveCreateMutationOptions,
  ) => Promise<?AgentIncentive>,
  isLoading: boolean,
}>;

export default function useCreateAgentIncentive(): AgentIncentiveCreateMutation {
  const queryClient = useQueryClient();
  const cacheKey = useQueryCacheKey(CommissionsServiceKeys.AGENT_INCENTIVES);

  const { mutateAsync, isLoading } = useMutation(
    (agentIncentive: AgentIncentiveMutation) => createAgentIncentive(agentIncentive),
    {
      onSuccess: async (response: { data: AgentIncentive, status: number, ... }) => {
        const { data } = response;
        const queryKey = [...cacheKey, { payPeriodId: data.payPeriodId }];

        await queryClient.cancelQueries(queryKey);
        const cachedIncentive = queryClient.getQueryData(queryKey);

        const existingGroup = cachedIncentive.teamIncentivesReviews.find(
          (group) => group.teamId === data.teamId,
        );
        if (existingGroup) {
          cachedIncentive.teamIncentivesReviews = cachedIncentive.teamIncentivesReviews.map(
            (teamIncentivesReview: TeamIncentivesReview) => {
              if (teamIncentivesReview.teamId === data.teamId) {
                return {
                  ...teamIncentivesReview,
                  teamIncentivesSummary: {
                    totalIncentivesAmount:
                      teamIncentivesReview.teamIncentivesSummary.totalIncentivesAmount +
                      data.amount,
                    numberOfAgents: teamIncentivesReview.teamIncentivesSummary.numberOfAgents + 1,
                    numberOfAgentsApproved: size(
                      filter(
                        teamIncentivesReview.agentIncentives,
                        (agentIncentive: AgentIncentive) => agentIncentive.isApproved === true,
                      ),
                    ),
                  },
                  agentIncentives: [...(teamIncentivesReview.agentIncentives || []), data],
                };
              }
              return teamIncentivesReview;
            },
          );

          cachedIncentive.incentiveReviewSummary =
            recalculateIncentiveReviewSummary(cachedIncentive);

          queryClient.setQueryData(queryKey, () => cachedIncentive);
          return data;
        }

        cachedIncentive.teamIncentivesReviews = [
          ...cachedIncentive.teamIncentivesReviews,
          {
            teamId: data.teamId,
            teamName: data.teamName,
            managers: data.managers,
            teamIncentivesSummary: {
              numberOfAgents: 1,
              totalIncentivesAmount: data.amount,
              numberOfAgentsApproved: 0,
            },
            agentIncentives: [{ ...data }],
          },
        ];

        cachedIncentive.incentiveReviewSummary = recalculateIncentiveReviewSummary(cachedIncentive);

        queryClient.setQueryData(queryKey, () => cachedIncentive);
        return data;
      },
    },
  );

  return {
    mutateAsync: async (
      data: AgentIncentiveMutation,
      options?: AgentIncentiveCreateMutationOptions,
    ): Promise<?AgentIncentive> => {
      try {
        const result = await mutateAsync(data);
        options?.onSuccess?.(result.data);
        return result.data;
      } catch (e) {
        options?.onError?.(e);
      }
      return null;
    },
    isLoading,
  };
}
