/* @flow */
import '../../css/incentives/IncentivesTable.css';
import * as React from 'react';
import { Button, Form, Popconfirm, Table, Tooltip, notification } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IncentiveStatuses } from '../../enums/IncentiveStatusEnum';
import { faCheckCircle, faEye, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { formatIncentiveStatus } from '../../utils/enumFormatter';
import { formatNumberAsMoney } from '../../utils/formatter';
import { isNil, map, orderBy, uniq, uniqBy } from 'lodash';
import { useState } from 'react';
import IncentiveValues from './IncentiveFields';
import useDeleteIncentive from '../../hooks/services/incentive-management/useDeleteIncentive';
import useUpdateIncentiveStatus from '../../hooks/services/incentive-management/useUpdateIncentiveStatus';
import type { Incentive } from '../../models/incentives/Incentive';

type Props = $ReadOnly<{
  incentives: ?Array<Incentive>,
  excludeUnpublished: boolean,
  setModal: (isOpen: boolean, incentive: ?Incentive) => void,
}>;

function IncentivesTable(props: Props): React.Node {
  const [form] = Form.useForm();
  const { incentives, setModal, excludeUnpublished } = props;

  // States
  const [newRow] = useState(null);
  const [mutationErrorMessage, setMutationErrorMessage] = useState(null);

  // Mutations
  const deleteMutation = useDeleteIncentive(excludeUnpublished);
  const updateIncentiveStatusMutation = useUpdateIncentiveStatus(excludeUnpublished);
  const updateIncentiveStatus = async (updatedRow: Incentive) => {
    await updateIncentiveStatusMutation.mutateAsync(updatedRow, {
      onError: (error) => {
        notification.error({
          message: 'Publishing Failed!',
          description: error.response?.data.message ?? error.message,
        });
      },
    });
  };

  const getColumns = (): any => {
    return [
      {
        title: 'Incentive Name',
        dataIndex: 'name',
        editable: false,
        sorter: (a, b) => a.name.localeCompare(b.name),
        defaultSortOrder: 'ascend',
        filterSearch: true,
        filters: map(orderBy(uniq(map(incentives, (incentive) => incentive.name))), (item) => {
          return { text: item, value: item };
        }),
        onFilter: (value, record) => record.name?.includes(value),
        width: 350,
      },
      {
        title: 'Amount',
        dataIndex: 'fixedAmount',
        editable: false,
        sorter: (a, b) => a.fixedAmount - b.fixedAmount,
        render: (_, row: Incentive) => formatNumberAsMoney(row.fixedAmount, true),
        align: 'right',
        width: 115,
      },
      {
        title: 'Status',
        dataIndex: 'incentiveStatus',
        editable: false,
        width: 80,
        filterSearch: true,
        filters: (uniqBy(
          [
            IncentiveStatuses.Draft,
            IncentiveStatuses.Published,
            IncentiveStatuses.Unpublished,
          ].flatMap((status) => [
            {
              text: formatIncentiveStatus(status),
              value: formatIncentiveStatus(status),
            },
          ]),
          (item) => item.text,
        ): {
          text: string,
          value: string,
        }[]),
        onFilter: (value, record) => formatIncentiveStatus(record.incentiveStatus) === value,
        render: (_, row: Incentive) => {
          return (
            <span>
              <strong>{formatIncentiveStatus(row.incentiveStatus)}</strong>
            </span>
          );
        },
      },
      {
        title: 'Automated Incentive',
        dataIndex: 'automatedIncentiveType',
        editable: false,
        width: 155,
        render: (_, row: Incentive) => {
          return row.automatedIncentiveType > 0 ? (
            <div className="check-icon">
              <FontAwesomeIcon icon={faCheckCircle} />
            </div>
          ) : null;
        },
      },
      {
        title: 'Fields',
        dataIndex: 'details',
        editable: true,
        render: (_, row: Incentive) => {
          return (
            <IncentiveValues
              isReadOnly={true}
              incentiveFields={row.incentiveFields}
            ></IncentiveValues>
          );
        },
      },
      {
        title: 'Actions',
        dataIndex: '',
        align: 'center',
        key: 'actions',
        width: 200,
        render: (_, row: Incentive) => getEditableActionCell(row),
      },
    ];
  };

  const onEditClick = (row: Incentive) => {
    setModal(true, row);
  };

  const getRows = (): Array<Incentive> => {
    if (!incentives && !newRow) {
      return [];
    }

    const rows = incentives ?? [];
    if (newRow) {
      return [...rows, newRow];
    }

    return [...rows];
  };

  const getEditableActionCell = (row: Incentive): React.Node => {
    return (
      <div className="incentives-action-cell">
        <div className="incentives-action-cell-buttons">
          <Button shape="circle" onClick={() => onEditClick(row)}>
            {row.incentiveStatus === IncentiveStatuses.Draft ? (
              <Tooltip title="Edit">
                <FontAwesomeIcon icon={faPen} />
              </Tooltip>
            ) : (
              <Tooltip title="View">
                <FontAwesomeIcon icon={faEye} />
              </Tooltip>
            )}
          </Button>
          {row.incentiveStatus === IncentiveStatuses.Draft ? (
            <>
              <Popconfirm
                title="Are you sure you want to delete this record?"
                onConfirm={() => onDeleteClick(row)}
              >
                <Tooltip title="Delete">
                  <Button shape="circle">
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </Tooltip>
              </Popconfirm>
              <Popconfirm
                title="Are you sure you want to publish this Incentive?"
                onConfirm={() => onPublishClick(row)}
              >
                <Button className="publish" type="primary">
                  Publish
                </Button>
              </Popconfirm>
            </>
          ) : row.incentiveStatus === IncentiveStatuses.Published ? (
            <Popconfirm
              title="Are you sure you want to unpublish this Incentive?"
              onConfirm={() => onUnpublishClick(row)}
            >
              <Button className="publish" type="primary" danger>
                Unpublish
              </Button>
            </Popconfirm>
          ) : (
            <Popconfirm
              title="Are you sure you want to publish this Incentive?"
              onConfirm={() => onPublishClick(row)}
            >
              <Button className="publish" type="primary">
                Publish
              </Button>
            </Popconfirm>
          )}
        </div>
        {getErrorMessage()}
      </div>
    );
  };

  const getErrorMessage = (): React.Node =>
    !isNil(mutationErrorMessage) && (
      <div className="incentives-action-cell-error-message">{mutationErrorMessage}</div>
    );

  const onDeleteClick = async (row: Incentive) => {
    await deleteMutation.mutateAsync(row, {
      onSuccess: () => {
        setMutationErrorMessage(null);
      },
      onError: (error) => {
        setMutationErrorMessage(
          'This record failed to be deleted. ' + (error.response?.data.message ?? error.message),
        );
      },
    });
  };

  const onUnpublishClick = async (row: Incentive) => {
    const updatedRow = { ...row, incentiveStatus: IncentiveStatuses.Unpublished };
    await updateIncentiveStatus(updatedRow);
  };

  const onPublishClick = async (row: Incentive) => {
    const updatedRow = { ...row, incentiveStatus: IncentiveStatuses.Published };
    await updateIncentiveStatus(updatedRow);
  };

  return (
    <Form form={form} component={false}>
      <Table
        className="incentives-table"
        bordered
        columns={getColumns()}
        dataSource={getRows()}
        size="small"
        pagination={false}
        rowKey="id"
      />
    </Form>
  );
}

export default IncentivesTable;
