/* @flow */
import { reduce } from 'lodash';
import { updateAgentIncentive } from '../../../services/IncentiveService';
import { useMutation, useQueryClient } from 'react-query';
import CommissionsServiceKeys from '../../../constants/CommissionsServiceKeys';
import useQueryCacheKey from '../../useQueryCacheKey';
import type { AgentIncentive } from '../../../models/incentives/AgentIncentive';
import type { AgentIncentiveMutation } from '../../../models/incentives/AgentIncentiveMutation';

type AgentIncentiveUpdateMutationOptions = $ReadOnly<{
  onSuccess?: (data: AgentIncentive) => void,
  onError?: (error: any) => void,
}>;

type IncentiveUpdateMutation = $ReadOnly<{
  mutateAsync: (
    data: AgentIncentiveMutation,
    options?: AgentIncentiveUpdateMutationOptions,
  ) => Promise<?AgentIncentive>,
  isLoading: boolean,
}>;

export default function useUpdateAgentIncentive(): IncentiveUpdateMutation {
  const queryClient = useQueryClient();
  const cacheKey = useQueryCacheKey(CommissionsServiceKeys.AGENT_INCENTIVES);

  const { mutateAsync, isLoading } = useMutation(
    async (agentIncentive: AgentIncentiveMutation) => updateAgentIncentive(agentIncentive),
    {
      onSuccess: async (response: { data: AgentIncentive, status: number, ... }) => {
        const { data } = response;
        const queryKey = [...cacheKey, { payPeriodId: data.payPeriodId }];

        await queryClient.cancelQueries(queryKey);
        const cachedIncentiveReview = queryClient.getQueryData(queryKey);

        for (let teamIncentiveReview of cachedIncentiveReview.teamIncentivesReviews) {
          const index = teamIncentiveReview?.agentIncentives?.findIndex(
            (item) => item.id === data.id,
          );
          if (index >= 0) {
            teamIncentiveReview.agentIncentives.splice(index, 1, data);
          }

          teamIncentiveReview.teamIncentivesSummary.totalIncentivesAmount = reduce(
            teamIncentiveReview.agentIncentives,
            (total, agentIncentive) => {
              return total + agentIncentive.amount;
            },
            0,
          );
        }

        cachedIncentiveReview.incentiveReviewSummary.totalIncentivesAmount = reduce(
          cachedIncentiveReview.teamIncentivesReviews,
          (total, teamIncentiveReview) => {
            return total + teamIncentiveReview.teamIncentivesSummary.totalIncentivesAmount;
          },
          0,
        );

        queryClient.setQueryData(queryKey, () => cachedIncentiveReview);
        return data;
      },
    },
  );

  return {
    mutateAsync: async (
      data: AgentIncentiveMutation,
      options?: AgentIncentiveUpdateMutationOptions,
    ): Promise<?AgentIncentive> => {
      try {
        const result = await mutateAsync(data);
        options?.onSuccess?.(result.data);
        return result.data;
      } catch (e) {
        options?.onError?.(e);
      }
      return null;
    },
    isLoading,
  };
}
