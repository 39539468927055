/* @flow */
export const AgentSaleRoles: {
  Closer: 1,
  Takeover: 2,
  Renewal: 3,
} = Object.freeze({
  Closer: 1,
  Takeover: 2,
  Renewal: 3,
});

export type AgentSaleRoleEnum = $Values<typeof AgentSaleRoles>;
