/* @flow */
import '../../css/search/SearchDetails.css';
import * as React from 'react';
import { Badge, Col, Descriptions, Divider, Row } from 'antd';
import { SalesCommissionStatus } from '../../enums/SalesCommissionStatusEnum';
import {
  formatAgentSaleRoleType,
  formatDealType,
  formatSalesCommissionStatus,
} from '../../utils/enumFormatter';
import {
  formatBooleanAsString,
  formatDateAsShortDate,
  formatNumberAsMoney,
  formatNumberAsPercentage,
  truncateNumber,
} from '../../utils/formatter';
import type { SalesCommission } from '../../models/deals/SalesCommission';
import type { SalesCommissionStatusEnum } from '../../enums/SalesCommissionStatusEnum';
import type { SearchAgentInfo } from '../../models/SearchResult';

type Props = $ReadOnly<{
  deal: SalesCommission & SearchAgentInfo,
}>;

function SearchSoldDealDetails({ deal }: Props): React.Node {
  const holdbackLabel = `Holdback (${formatNumberAsPercentage(deal.holdbackPercentage)})`;

  const badgeColorForSalesCommissionStatus = (status: SalesCommissionStatusEnum) => {
    switch (status) {
      case SalesCommissionStatus.DownPaymentTooLow:
      case SalesCommissionStatus.Cancelled:
      case SalesCommissionStatus.Reassigned:
        return 'red';
      case SalesCommissionStatus.InitialPayout:
      case SalesCommissionStatus.PaidOut:
      case SalesCommissionStatus.CancellationPeriod:
        return 'green';
      default:
        return 'blue';
    }
  };

  const paymentSection = deal.soldDetails.isFullPay ? (
    <Descriptions title="Payment" column={3} layout="vertical" size="small" bordered>
      <Descriptions.Item label="Payment">
        {formatNumberAsMoney(deal.soldDetails.customerCost, true)}
      </Descriptions.Item>
      <Descriptions.Item label="Collected Amount">
        {formatNumberAsMoney(deal.collectedPaymentTotal, true)}
      </Descriptions.Item>
      <Descriptions.Item label="Payment Collected?">
        {formatBooleanAsString(deal.isPaymentCollected)}
      </Descriptions.Item>
    </Descriptions>
  ) : (
    <Descriptions title="Payment" column={4} layout="vertical" size="small" bordered>
      <Descriptions.Item label="Down Payment">
        {formatNumberAsMoney(deal.soldDetails.downPayment, true)}
      </Descriptions.Item>
      <Descriptions.Item label="DP %">
        {formatNumberAsPercentage(deal.soldDetails.downPaymentPercentage)}
      </Descriptions.Item>
      <Descriptions.Item label="Collected Amount">
        {formatNumberAsMoney(deal.collectedPaymentTotal, true)}
      </Descriptions.Item>
      <Descriptions.Item label="DP Collected?">
        {formatBooleanAsString(deal.isPaymentCollected)}
      </Descriptions.Item>
    </Descriptions>
  );

  return (
    <div className="search-details-root">
      <Row className="search-details-basic-info">
        <Col span={24}>
          <Descriptions title="Basic Info" column={7} layout="vertical" size="small" bordered>
            <Descriptions.Item label="Commission">
              {formatAgentSaleRoleType(deal.agentSaleRole)}
            </Descriptions.Item>
            <Descriptions.Item label="Channel">{formatDealType(deal.dealType)}</Descriptions.Item>
            <Descriptions.Item label="Payment Type">
              {deal.soldDetails.paymentType}
            </Descriptions.Item>
            <Descriptions.Item label="Sold Date">
              {formatDateAsShortDate(deal.soldDetails.soldDate, true, true)}
            </Descriptions.Item>
            <Descriptions.Item label="Canceled Date">
              {formatDateAsShortDate(deal.cancelledDetails?.cancelledDate, true, true)}
            </Descriptions.Item>
            <Descriptions.Item label="Funded Date">
              {deal.soldDetails.isFullPay
                ? 'Full Pay'
                : formatDateAsShortDate(deal.fundedDetails?.fundedDate, true, true)}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      <Row gutter={[32, 0]}>
        <Col span={12} className="search-details-contribution-details">
          {paymentSection}
          <Descriptions
            title="Retail Rate"
            column={4}
            layout="vertical"
            size="small"
            bordered
            className="search-details-retail-rate-details"
          >
            <Descriptions.Item label="Retail Rate">
              {formatNumberAsMoney(deal.soldDetails.retailRate, true)}
            </Descriptions.Item>
            <Descriptions.Item label="Customer Cost">
              {formatNumberAsMoney(deal.soldDetails.customerCost, true)}
            </Descriptions.Item>
            <Descriptions.Item label="Sales Tax">
              {formatNumberAsMoney(deal.soldDetails.salesTax, true)}
            </Descriptions.Item>
            <Descriptions.Item label="Deviation">
              {formatNumberAsMoney(deal.soldDetails.retailRateDeviation, true)}
            </Descriptions.Item>
          </Descriptions>
          <Row>
            <Col span={6}>
              <Descriptions title="Finance Term" column={1} layout="vertical" size="small" bordered>
                <Descriptions.Item label="Finance Term">
                  {deal.soldDetails.isFullPay
                    ? 'Full Pay'
                    : `${deal.soldDetails.financeTermInMonths} month${
                        deal.soldDetails.financeTermInMonths > 0 ? 's' : ''
                      }`}
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Col span={6}>
              <Descriptions
                className="description-table-left-margin"
                title="Policy Term"
                column={1}
                layout="vertical"
                size="small"
                bordered
              >
                <Descriptions.Item label="Policy Term">
                  {`${deal.soldDetails.policyTermInMonths} month${
                    deal.soldDetails.policyTermInMonths > 0 ? 's' : ''
                  }`}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
          <Row>
            <Col span={16}>
              <Descriptions
                title="Elite Club"
                column={3}
                layout="vertical"
                size="small"
                bordered
                className="search-details-elite-details"
              >
                <Descriptions.Item label="Elite Activated?">
                  {formatBooleanAsString(deal.soldDetails.isEliteActivated)}
                </Descriptions.Item>
                <Descriptions.Item label="Activation Fee">
                  {formatNumberAsMoney(deal.eliteClubFee, true)}
                </Descriptions.Item>
                <Descriptions.Item label="Fee Collected?">
                  {formatBooleanAsString(deal.isActivationFeeCollected)}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Descriptions
                title="Closing Rate %"
                column={3}
                layout="vertical"
                size="small"
                bordered
              >
                <Descriptions.Item label="Closing Rate %">
                  {formatNumberAsPercentage(
                    truncateNumber(deal.soldDetails.closingRatioPercentage * 100, 1),
                    false,
                  )}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        </Col>
        <Col span={12} className="search-details-calculation-section">
          <Descriptions title="Calculation" column={1} size="large" bordered>
            <Descriptions.Item label="Retail Deviation Contribution">
              {formatNumberAsMoney(deal.retailDeviationAmount, true)}
            </Descriptions.Item>
            <Descriptions.Item label="Finance Term Contribution">
              {formatNumberAsMoney(deal.financeTermAmount, true)}
            </Descriptions.Item>
            <Descriptions.Item label="Policy Term Contribution">
              {formatNumberAsMoney(deal.policyTermAmount, true)}
            </Descriptions.Item>
            <Descriptions.Item label="Elite Club Contribution">
              {formatNumberAsMoney(deal.eliteActivationAmount, true)}
            </Descriptions.Item>
            <Descriptions.Item label="IB Closing Rate % Contribution">
              {formatNumberAsMoney(deal.closingRatioBonusAmount, true)}
            </Descriptions.Item>
            <Descriptions.Item label="OB Closing Rate % Contribution">
              {formatNumberAsMoney(deal.outboundClosingRatioBonusAmount, true)}
            </Descriptions.Item>
            <Descriptions.Item label="TV Closing Rate % Contribution">
              {formatNumberAsMoney(deal.tvClosingRatioPercentageBonusAmount, true)}
            </Descriptions.Item>
            <Descriptions.Item label="Digital Closing Rate % Contribution">
              {formatNumberAsMoney(deal.digitalClosingRatioBonusAmount, true)}
            </Descriptions.Item>
            <Descriptions.Item label="DM Closing Rate % Contribution">
              {formatNumberAsMoney(deal.directMailClosingRatioBonusAmount, true)}
            </Descriptions.Item>
            <Descriptions.Item label="Dials/Sale Contribution">
              {formatNumberAsMoney(deal.dialsPerSaleBonusAmount, true)}
            </Descriptions.Item>
            <Descriptions.Item label="OB Dialer">
              {formatNumberAsMoney(deal.outboundDialerSaleAmount, true)}
            </Descriptions.Item>
            <Descriptions.Item label="Split Deal?">
              {formatBooleanAsString(deal.isSplitDeal)}
            </Descriptions.Item>
          </Descriptions>
          <Divider></Divider>
          <Descriptions column={1} bordered>
            <Descriptions.Item label="Raw Total">
              {formatNumberAsMoney(deal.rawTotal, true)}
            </Descriptions.Item>
            <Descriptions.Item label={holdbackLabel}>
              {formatNumberAsMoney(deal.holdbackTotal, true)}
            </Descriptions.Item>
          </Descriptions>
          <Divider></Divider>
          {deal.isRenewal && (
            <>
              <Descriptions column={1} bordered>
                <Descriptions.Item label="Is Renewal">
                  {formatBooleanAsString(deal.isRenewal)}
                </Descriptions.Item>
                <Descriptions.Item label="Renewal %">
                  {formatNumberAsPercentage(deal.renewalPercentage)}
                </Descriptions.Item>
              </Descriptions>
              <Divider></Divider>
            </>
          )}
          <Descriptions column={1} bordered>
            <Descriptions.Item label="Status">
              <Badge
                color={badgeColorForSalesCommissionStatus(deal.salesCommissionStatus)}
                status="processing"
                text={formatSalesCommissionStatus(
                  deal.salesCommissionStatus,
                  deal.soldDetails.isFullPay,
                )}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Earned this Period">
              {formatNumberAsMoney(deal.earnedTotal, true)}
            </Descriptions.Item>
            {deal.cumulativeTotal > 0 && (
              <Descriptions.Item label="Earned Prior">
                {formatNumberAsMoney(deal.cumulativeTotal, true)}
              </Descriptions.Item>
            )}
            {[
              SalesCommissionStatus.AwaitingDownPayment,
              SalesCommissionStatus.AwaitingFunding,
              SalesCommissionStatus.AwaitingTrialPeriodEnd,
            ].includes(deal.salesCommissionStatus) && (
              <Descriptions.Item label="Outstanding">
                {formatNumberAsMoney(deal.outstandingTotal, true)}
              </Descriptions.Item>
            )}
          </Descriptions>
        </Col>
      </Row>
    </div>
  );
}

export default SearchSoldDealDetails;
