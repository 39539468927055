/* @flow */
export const AdjustmentTypes: {
  KpiBonus: 1,
  ReviewBonus: 2,
  UpsellBonus: 3,
  OtherBonus: 4,
  EgregiousErrorPenalty: 5,
  OtherPenalty: 6,
  Correction: 7,
  Other: 8,
} = Object.freeze({
  KpiBonus: 1,
  ReviewBonus: 2,
  UpsellBonus: 3,
  OtherBonus: 4,
  EgregiousErrorPenalty: 5,
  OtherPenalty: 6,
  Correction: 7,
  Other: 8,
});

export type AdjustmentTypeEnum = $Values<typeof AdjustmentTypes>;
